/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/Spinner';
import useApi from '../../hooks/api/useApi';
import { AlertContext } from '../../hooks/context/AlertProvider';
import { NavbarContext } from '../../hooks/context/NavbarProvider';
import { TutorialContext } from '../../hooks/context/TutorialProvider';
import withTutorial from '../../hooks/withTutorial';
import { tutorialScreens } from '../../utils/texts';
import { HUB_MODES } from '../TeacherHub/constants';
import DetailsTab from './components/DetailsTab/DetailsTab';
import ScoreTab from './components/ScoreTab';
import Step1Tab from './components/Step1Tab';
import Step2Tab from './components/Step2Tab';
import './PreparationResults.scss';

const TABS = {
  SCORES: 'Note',
  DETAILS: 'Détail',
  DETAILS_P1: 'Analyse continue',
  DETAILS_P2: 'Appréciation générale'
};

function PreparationResults({ match, history, mode, isTeacher, location }) {
  const [results, isLoading] = useApi('/api/debates/preparationResults', {}, 'post', {
    token: match.params.id,
    mode: mode === HUB_MODES.TEACHER_FOLDER_TO_DO ? 'to_do' : 'to_eval',
    forUser: match.params.forUser
  });
  const [currentTab, setCurrentTab] = React.useState(TABS.SCORES);
  const [shouldScrollToP2, setShouldScrollToP2] = React.useState(false);
  const [lastAssignedGrade, setLastAssignedGrade] = React.useState({ grade: 0, comment: '' });
  const changeTab = tab => () => setCurrentTab(tab);
  const { title, scores, argsQuality, phase1, phase2, url, folder, notes, isDemo, isDemoToEvaluate } = results;

  const wasGradeUpdated =
    scores === undefined
      ? false
      : String(scores.final.grade) !== String(lastAssignedGrade.grade) ||
        scores.final.comment !== lastAssignedGrade.comment;
  const isReplay = location.search.includes('?mode=replay');

  const navbarContext = React.useContext(NavbarContext);
  const alertContext = React.useContext(AlertContext);
  const tutorialContext = React.useContext(TutorialContext);

  React.useEffect(() => {
    tutorialContext.setScreen(mode === 'to_do' ? tutorialScreens.RESULTS_TO_DO : tutorialScreens.RESULTS_TO_EVAL, true);
    return () => tutorialContext.setScreen('');
  }, [mode]);

  React.useEffect(() => {
    if (!isLoading && scores.final) {
      setLastAssignedGrade({ ...scores.final });
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (currentTab !== TABS.DETAISL) {
      setShouldScrollToP2(false);
    }
  }, [currentTab]);

  React.useEffect(() => {
    if (!isLoading) {
      if (isReplay) {
        navbarContext.popover('Rejouer', folder);
      } else {
        navbarContext.popover(
          'Résultats',
          folder,
          !isTeacher
            ? null
            : async () => {
                if (results.graded && !wasGradeUpdated) return true;

                // dialog only if the exercise has yet to be graded, or grade was changed
                const res = await new Promise(resolve => {
                  alertContext.confirm(
                    {
                      title: 'Attention !',
                      content:
                        wasGradeUpdated && results.graded ? (
                          <>
                            Si vous quittez sans cliquer sur le bouton «&nbsp;Valider et fermer&nbsp;» en <br />
                            bas de page, vos modifications ne seront pas sauvegardées.
                          </>
                        ) : (
                          <>
                            Êtes-vous sur(e) ? Si vous quittez sans cliquer sur le bouton «&nbsp;Valider et
                            fermer&nbsp;» en bas de page, vos dernières modifications ne seront pas sauvegardées et
                            visibles pour l'élève.
                          </>
                        ),
                      submitText: 'Oui, quitter'
                    },
                    resolve
                  );
                });

                return res;
              }
        );
      }

      if (isDemo && !isDemoToEvaluate) tutorialContext.startGuidedTour();
    } else {
      navbarContext.popover('Résultats', '');
    }
  }, [isLoading, wasGradeUpdated]);

  React.useEffect(() => {
    if (isReplay) {
      setCurrentTab(TABS.DETAILS_P1);
    }
  }, [isReplay, location.search]);

  const onReplay = phase => () => {
    if (phase === 1) {
      if (mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL) {
        setCurrentTab(TABS.DETAILS_P1);
      } else {
        history.push('/replay/' + match.params.id);
      }
    } else if (phase === 2) {
      if (mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL) {
        setCurrentTab(TABS.DETAILS_P2);
      } else {
        setShouldScrollToP2(true);
        setCurrentTab(TABS.DETAILS);
      }
    }
  };

  const CurrentTabComponent = isLoading
    ? {}
    : {
        [TABS.SCORES]: (
          <ScoreTab
            isTeacher={isTeacher}
            history={history}
            title={title}
            scores={scores.computed}
            recordingMode={results.recordingMode}
            grade={lastAssignedGrade}
            onReplay={onReplay}
            mode={mode}
            graded={results.graded}
            isInPerson={results.mode === 'in_person'}
            folderToken={results.folder_token}
            isDemo={isDemo}
            isDemoToEvaluate={isDemoToEvaluate}
            onGradeUpdate={setLastAssignedGrade}
          />
        ),
        [TABS.DETAILS]:
          mode !== HUB_MODES.TEACHER_FOLDER_TO_EVAL ? (
            <DetailsTab
              title={title}
              data={argsQuality}
              isTeacher={isTeacher}
              phase2={phase2}
              shouldScrollToP2={shouldScrollToP2}
            />
          ) : (
            ''
          ),
        [TABS.DETAILS_P1]:
          mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL ? (
            <Step1Tab title={title} data={phase1} url={url} isTeacher={isTeacher} notes={notes} />
          ) : (
            ''
          ),
        [TABS.DETAILS_P2]: (
          <Step2Tab
            title={title}
            hexagon={phase2.hexagon}
            selection={phase2.selected}
            mode={mode}
            speakers={phase2.speakers}
            pics={phase2.pics}
            isTeacher={isTeacher}
          />
        )
      };

  return (
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      {!isReplay && (
        <div className="tab-selector">
          {Object.keys(TABS).map(
            tab =>
              ((mode === HUB_MODES.TEACHER_FOLDER_TO_DO &&
                TABS[tab] !== TABS.DETAILS_P1 &&
                TABS[tab] !== TABS.DETAILS_P2) ||
                (mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL && TABS[tab] !== TABS.DETAILS)) && (
                <button
                  className={`btn btn-text tab-item ${currentTab === TABS[tab] ? 'tab-item-selected' : ''}`}
                  onClick={changeTab(TABS[tab])}
                  key={TABS[tab]}
                >
                  {TABS[tab]}
                </button>
              )
          )}
        </div>
      )}

      {isLoading ? <Spinner /> : <div className="pr-wrapper">{CurrentTabComponent[currentTab]}</div>}
    </div>
  );
}

export default connect(({ auth }) => ({ isTeacher: auth.user.teacher }))(PreparationResults);
