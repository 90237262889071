import React from 'react';
import Dialog from '../../components/dialog/Dialog';

const INITIAL_CONTEXT = {
  isOpen: false,
  title: '',
  content: '',
  cancelText: 'Annuler',
  submitText: 'Valider',
  callback: null
};

export const AlertContext = React.createContext(INITIAL_CONTEXT);

function AlertProvider({ children, history, auth }) {
  const [state, setState] = React.useState(INITIAL_CONTEXT);

  const confirm = (options, callback) => {
    setState(s => ({ ...s, ...options, callback, isOpen: true }));
  };

  const onCallback = bool => () => {
    setState(s => ({ ...s, isOpen: false }));
    if (state.callback) {
      state.callback(bool);
    }
  };

  return (
    <AlertContext.Provider value={{ ...state, confirm }}>
      <div>
        <Dialog
          open={state.isOpen}
          title=" "
          onClose={() => setState(s => ({ ...INITIAL_CONTEXT, isOpen: false }))}
          content={
            <div className="text-center">
              <h3>{state.title}</h3>
              <div className="subtitle">{state.content}</div>

              <div className="d-flex flex-wrap mt-2" style={{ gap: 20 }}>
                <button className="btn btn-outline fullwidth" onClick={onCallback(false)} style={{ flex: 1 }}>
                  {state.cancelText}
                </button>
                <button className="btn btn-primary fullwidth" onClick={onCallback(true)} style={{ flex: 1 }}>
                  {state.submitText}
                </button>
              </div>
            </div>
          }
        />
      </div>
      <div style={{ height: '100%' }}>{children}</div>
    </AlertContext.Provider>
  );
}

export default AlertProvider;
