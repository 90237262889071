import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { HUB_MODES } from '../../constants';
import { get_thumbnail_img } from '../../../../utils/video_patterns';
import { Link, useHistory } from 'react-router-dom';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import IconButton from '../../../../components/IconButton';
import BarChartIcon from '@material-ui/icons/BarChart';
import { makeStyles, Tooltip } from '@material-ui/core';
import { isIE } from 'react-device-detect';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { hexagonColors, squareColors } from '../../../../styles/palette';
import CircularProgress from '../../../../components/charts/CircularProgress';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const { TEACHER_FOLDER_TO_DO: TO_DO, TEACHER_FOLDER_TO_EVAL: TO_EVAL, TEACHER_IN_PERSON: IN_PERSON } = HUB_MODES;

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#FFFFFF00',
    color: '#9B9B9B',
    padding: 0,
    fontWeight: 'bold'
  }
});

const SessionCard = function({
  session,
  onClick,
  mode,
  fileURL,
  callbacks,
  isTeacher,
  closeDate,
  isDemo,
  isDemoToEvaluate,
  isPCL,
  submitted
}) {
  const { onDelete, onReevaluate, onEvaluate, onResults, onReplay, onEdit } = callbacks;
  const history = useHistory();

  const classes = useStyles();
  const IconButtonWrapper = ({ onClick, title, children }) => (
    <div>
      <IconButton onClick={onClick}>
        <Tooltip placement="top" enterDelay={0} title={title} classes={classes}>
          {children}
        </Tooltip>
      </IconButton>
    </div>
  );

  const LinkWrapper = ({ to, title, children, disabled }) => {
    const linkProps = {};
    if (isIE)
      linkProps.onClick = () => {
        history.push(to);
        window.location.reload();
      };
    else linkProps.to = to;

    const wrapperProps = {};
    if (disabled) wrapperProps.title = title;

    return (
      <div {...wrapperProps}>
        <Link className={`btn btn-icon ${disabled && 'disabled'}`} {...linkProps}>
          <Tooltip placement="top" enterDelay={0} title={title} classes={classes}>
            {children}
          </Tooltip>
        </Link>
      </div>
    );
  };

  const shouldAddBackground = isTeacher && mode === HUB_MODES.TEACHER_HUB;
  const shouldDisableReplay =
    (mode === IN_PERSON || (!isTeacher && mode === HUB_MODES.TEACHER_HUB)) && session.recordingMode === 'none';
  const replayButtonTitle = shouldDisableReplay ? 'Pas de média à revisionner' : "Lire l'enregistrement";

  const evaluateButtonActions = mode === IN_PERSON ? { onClick: onEvaluate } : { to: onEvaluate() };

  const SessionCardEvalControls = () => (
    <div key={session.token} className={'slist-card-controls' + (shouldAddBackground ? ' slist-card-controls-bg' : '')}>
      {isTeacher ? (
        <>
          {mode !== HUB_MODES.TEACHER_HUB && !session.graded && session.participated && (
            <div className="slist-card-warning">
              <Tooltip
                arrow={true}
                placement="top"
                enterDelay={0}
                title={
                  <>
                    Cette note n'est pas visible pour l'élève. Dans la barre d'actions, cliquez sur
                    «&nbsp;Résulats&nbsp;» puis, en bas de page, sur «&nbsp;Valider et fermer&nbsp;» pour l'informer de
                    votre correction.
                  </>
                }
              >
                <ReportProblemOutlinedIcon
                  style={{ color: hexagonColors[3], fontSize: '1.1rem', marginBottom: 5 }}
                  fontSize="inherit"
                />
              </Tooltip>
              <span>Note</span>
              <span>à valider</span>
            </div>
          )}

          {session.participated && (
            <a style={{ width: '3em', height: '100%', marginRight: 10 }} href={onResults}>
              <CircularProgress textSize={'28px'} strokeWidth={15} value={session.score} maxValue={20} />
            </a>
          )}

          {mode !== HUB_MODES.TEACHER_HUB &&
            (!session.participated ||
            (mode === TO_EVAL && !session.graded) ||
            (mode === IN_PERSON && session.notSubmitted) ? (
              <div>
                <Link {...evaluateButtonActions} className="btn btn-primary small">
                  Corriger
                </Link>
              </div>
            ) : (
              <>
                {isTeacher && (!isDemo || isDemoToEvaluate) && (
                  <div>
                    <button onClick={onReevaluate} className="btn btn-outline small">
                      Re-corriger
                    </button>
                  </div>
                )}
                {!isTeacher && (
                  <>
                    <LinkWrapper to={onReplay} title="Rejouer">
                      <PlayArrowIcon fontSize="inherit" />
                    </LinkWrapper>
                    {(session.hasEvaluations || session.graded) && (
                      <LinkWrapper title="Résultats" to={onResults}>
                        <BarChartIcon fontSize="inherit" />
                      </LinkWrapper>
                    )}
                  </>
                )}
              </>
            ))}

          {mode === HUB_MODES.TEACHER_HUB && !session.isDemo && (
            <>
              <IconButtonWrapper title="Modifier" onClick={onEdit}>
                <EditIcon fontSize="inherit" />
              </IconButtonWrapper>
              {!isDemo && (
                <IconButtonWrapper onClick={onDelete} title="Supprimer">
                  <DeleteIcon fontSize="inherit" />
                </IconButtonWrapper>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {!session.submitted ? (
            <div style={{ fontSize: '2em', marginRight: 5, color: '#A0A0A0' }}>
              <KeyboardArrowRightIcon fontSize="inherit" />
            </div>
          ) : session.graded ? (
            <>
              <div style={{ width: '3em', height: '100%', marginRight: 10 }}>
                <CircularProgress textSize={'28px'} strokeWidth={10} value={session.grade} maxValue={20} />
              </div>
              <div className="vr"></div>
              {!isTeacher && (
                <>
                  <LinkWrapper title={replayButtonTitle} to={onReplay} disabled={shouldDisableReplay}>
                    <PlayArrowIcon fontSize="inherit" />
                  </LinkWrapper>
                  <LinkWrapper title="Résultats" to={onResults}>
                    <BarChartIcon fontSize="inherit" />
                  </LinkWrapper>
                </>
              )}
            </>
          ) : (
            <div className="slist-weval">
              <div className="mb-1">
                <HourglassEmptyIcon />
              </div>
              <div>En attente de correction</div>
            </div>
          )}
        </>
      )}
    </div>
  );

  const SessionCardFooterControls = () =>
    mode !== HUB_MODES.TEACHER_HUB && ((mode !== TO_EVAL && session.hasEvaluations) || mode === TO_EVAL) ? (
      <div className="slist-card-footer">
        {session.participated && (
          <>
            <LinkWrapper to={onReplay} title={replayButtonTitle} disabled={shouldDisableReplay}>
              <PlayArrowIcon fontSize="inherit" />
            </LinkWrapper>
            <LinkWrapper title="Résultats" to={onResults}>
              <BarChartIcon fontSize="inherit" />
            </LinkWrapper>
          </>
        )}
        {!isDemo && (
          <IconButtonWrapper onClick={onDelete} title="Supprimer">
            <DeleteIcon fontSize="inherit" />
          </IconButtonWrapper>
        )}
      </div>
    ) : (
      ''
    );

  const timeCreatedR = new Date(session.timeCreated);
  timeCreatedR.setHours(0, 0, 0, 0);

  const closeDateR = new Date(closeDate);
  closeDateR.setHours(0, 0, 0, 0);

  const wasSubmittedLate = mode !== IN_PERSON && timeCreatedR > closeDateR;
  const SessionCardInfos = () => (
    <>
      {!session.notSubmitted && (
        <div className={'subtitle' + (wasSubmittedLate ? ' red' : '')}>
          {new Date(mode === HUB_MODES.TEACHER_HUB ? session.closeDate : session.timeCreated).toLocaleDateString(
            'fr-FR'
          )}
        </div>
      )}

      {[TO_DO, TO_EVAL, IN_PERSON].includes(session.mode) ? (
        <div className="d-flex align-items-center">
          <span
            className={`pastille-lightblue`}
            style={{
              backgroundColor:
                session.mode === IN_PERSON ? squareColors.yellow : session.mode === TO_EVAL ? squareColors.purple : ''
            }}
          >
            {session.mode === TO_EVAL
              ? 'Apprendre à évaluer'
              : session.mode === IN_PERSON
              ? "S'entraîner en conditions d'examen"
              : "S'entraîner à l'oral"}
          </span>
          {isTeacher && (
            <span className={'sess-info ' + (session.submissions === session.group_members ? 'primary' : 'orange')}>
              {session.submissions === session.group_members ? (
                <CheckCircleIcon fontSize="inherit" />
              ) : (
                <HourglassEmptyIcon fontSize="inherit" />
              )}
              <div className="ml-1">
                <span style={{ fontWeight: 900 }}>
                  {String(session.submissions)}/{String(session.group_members)}
                </span>{' '}
                rendus
              </div>
            </span>
          )}
        </div>
      ) : session.notSubmitted && mode !== IN_PERSON ? (
        <div className="sess-info red">
          <CancelIcon fontSize="inherit" />
          <div className="ml-1">Non rendu</div>
        </div>
      ) : !session.participated ? (
        <div className="sess-info orange">
          <HourglassEmptyIcon fontSize="inherit" />
          <div className="ml-1">À évaluer</div>
        </div>
      ) : (
        ''
      )}
    </>
  );

  const wrapperLinkProps = !isIE
    ? { to: onClick }
    : {
        onClick: () => {
          history.push(onClick);
          window.location.reload();
        }
      };

  const CardWrapper = ({ children }) =>
    (!submitted || isTeacher) && mode === HUB_MODES.TEACHER_HUB ? (
      <Link
        className={`slist-card flex-row ${mode === HUB_MODES.TEACHER_HUB && 'slist-clickable'}`}
        id={
          isPCL ? (session.isDemo ? (session.isDemoToEvaluate ? 'pcl-demo-tocorrect' : 'pcl-demo-corrected') : '') : ''
        }
        {...wrapperLinkProps}
      >
        {children}
      </Link>
    ) : (
      <div className={`slist-card`}>{children}</div>
    );

  const thumbnail_img = session.mode === 'to_eval' ? get_thumbnail_img(session) : null;
  return (
    <CardWrapper>
      <div className="slist-card-inner">
        {session.mode === 'to_eval' ? (
          thumbnail_img ? (
            <img alt="Logo" className="logo-todo" src={thumbnail_img} />
          ) : (
            <div
              className="logo-todo logo-todo-ico"
              style={{
                backgroundColor: squareColors.purple,
                color: 'white'
              }}
            >
              <VideoLibraryIcon fontSize="inherit" />
            </div>
          )
        ) : (
          <div
            className="logo-todo logo-todo-ico"
            style={{
              backgroundColor: !session.notSubmitted
                ? mode === TO_EVAL
                  ? squareColors.purple
                  : session.mode === IN_PERSON || mode === IN_PERSON
                  ? squareColors.yellow
                  : squareColors.blue
                : '#EEEEEE',
              color: !session.notSubmitted ? 'white' : '#9B9B9B'
            }}
          >
            {session.prestationMode === 'audio' ? (
              <VolumeUpIcon fontSize="inherit" />
            ) : (
              <VideoLibraryIcon fontSize="inherit" />
            )}
          </div>
        )}
        <div className="slist-card-content">
          <div>
            <b>{session.title}</b>
          </div>
          <SessionCardInfos />
        </div>
        {(!isTeacher || (isTeacher && mode !== HUB_MODES.TEACHER_HUB)) &&
          (mode === IN_PERSON || !session.notSubmitted) && <SessionCardEvalControls />}
      </div>
      {isTeacher && mode === HUB_MODES.TEACHER_HUB && <SessionCardEvalControls />}
      {!session.notSubmitted && <SessionCardFooterControls />}
    </CardWrapper>
  );
};

export default SessionCard;
