import React from 'react';
import { useHistory } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import Spinner from '../../components/Spinner';
import useApi from '../../hooks/api/useApi';
import { GET_TUTORIAL } from '../../services/api_routes';
import './TutorialViewer.scss';

const TutorialViewer = ({ match }) => {
  const token = match.params.token;
  const history = useHistory();

  const [video, isLoading] = useApi(GET_TUTORIAL + '?token=' + token, {});

  const onGoBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace('/tutorials');
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <NavigationBar title={video.title} onBack={onGoBack} />
      <div className="tv-desc">{video.description}</div>

      <div className="body-wrapper tv-wrapper">
        <video src={video.url} controls autoPlay />
      </div>
    </>
  );
};

export default TutorialViewer;
