/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Hexagon from '../../../../components/Hexagon';
import { isIE } from 'react-device-detect';

const hexagonsSizes = [
  { width: 1143, height: 1015 },
  { width: 720, height: 646 },
  { width: 590, height: 550 },
  { width: 624, height: 555 }
];
const hexagonsPositions = [
  [{ x: 67, y: 38 }],
  [
    { x: 8.5, y: 12.5 },
    { x: 538, y: 442, width: 730, height: 616 }
  ],
  [
    { x: 94, y: 276 },
    { x: 585, y: 0 },
    { x: 585, y: 558 }
  ],
  [
    { x: 0, y: 0 },
    { x: 645, y: 0 },
    { x: 0, y: 545 },
    { x: 645, y: 545 }
  ]
];

const hexagonsMobileSizes = [
  { width: 600, height: 520 },
  { width: 475, height: 412 }
];

const hexagonsMobilePositions = [
  [{ x: 0, y: 5 }],
  [
    { x: 62, y: 0 },
    { x: 62, y: 429 }
  ]
];

const MAX_HEXAGONS = 4;

/**
 * Renders an hexagon inside a <foreignObject>.
 * Takes hexagon props, position, and size as props.
 */
const HexagonObject = ({
  hexagon,
  currentComment,
  position,
  size,
  active,
  className,
  onCellClick,
  timer,
  flashing = [],
  rotate = false,
  onOpenComment,
  isReplay,
  commenting,
  editedComment
}) => {
  // const HexagonObj = () => <Hexagon {...hexagon} onCellClick={onCellClick} active={active} flashing={flashing} />

  // const Wrapper = ({ children }) ?

  const style = {};
  if (isIE) {
    style.margin = '0 auto';
    style.width = '50%';
  } else {
    style.pointerEvents = 'none';
  }

  return hexagon.phase >= 2 ? (
    <Hexagon {...hexagon} onCellClick={onCellClick} active={active} flashing={flashing} isReplay={isReplay} />
  ) : (
    <foreignObject
      style={style}
      {...position}
      {...size}
      className={className}
      transform={rotate ? `rotate(90, ${position.x + position.width / 2}, ${position.y + position.height / 2})` : ''}
    >
      <Hexagon
        {...hexagon}
        timer={timer}
        isReplay={isReplay}
        onCellClick={onCellClick}
        active={active}
        flashing={flashing}
        onOpenComment={onOpenComment}
        currentComment={currentComment}
        commenting={commenting}
        editedComment={editedComment}
      />
      {/* </g> */}
    </foreignObject>
  );
};

/**
 * Renders hexagons in an hexagonal grid inside of a svg canvas.
 * Hexagons' sizes and positions are fixed for every number of hexagons in the grid
 *
 * Offers a mobile mode and a desktop mode.
 * @mobile mode => capped at 2 hexagons displayed max
 */
function HexagonsGrid(props) {
  const { hexagons } = props;

  // Hexagons are split into pages
  const [hexagonPages, setHexagonPages] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  // The css class applied to hexagons on page transition for animation
  const [transi, setTransi] = useState('');

  /**
   * Splits the array of hexagons into pages of props.maxSize size
   */
  const paginate = () => {
    const pages = [];
    const grpSize = Math.min(props.maxSize, hexagons.length, MAX_HEXAGONS);

    hexagons.forEach((hexagon, idx) => {
      if (idx % grpSize === 0) {
        pages.push([]);
      }
      pages[Math.floor(idx / grpSize)].push(hexagon);
    });

    setHexagonPages(pages);
  };

  const goToPreviousPage = () => {
    const prevPage = currPage === 0 ? hexagonPages.length - 1 : currPage - 1;
    setCurrPage(prevPage);
    setTransi('hex-fade-right');

    if (props.onPageChange) props.onPageChange(prevPage, hexagonPages.length);
  };

  const goToNextPage = () => {
    const nextPage = (currPage + 1) % hexagonPages.length;
    setCurrPage(nextPage);
    setTransi('hex-fade-left');

    if (props.onPageChange) props.onPageChange(nextPage, hexagonPages.length);
  };

  /**
   * Paginate on hexagons reload
   */
  useEffect(() => {
    paginate();
  }, [hexagons]);

  /**
   * If the number of hexagons per page changes, go back to page 0.
   */
  useEffect(() => {
    paginate();
    setCurrPage(0);
  }, [props.maxSize]);

  useEffect(() => {
    if (props.onPageChange) props.onPageChange(currPage, hexagonPages.length);
  }, [hexagonPages]);

  const BackPageBtn = () =>
    hexagonPages.length > 1 ? (
      <div
        title="Page précédente"
        onClick={goToPreviousPage}
        className="page-arrow"
        style={{ paddingLeft: 10, height: props.isMobile ? '100%' : '100vh' }}
      >
        <ArrowBackIosIcon />
      </div>
    ) : (
      '' // <span style={{ width: '30px' }} />
    );

  const NextPageBtn = () =>
    hexagonPages.length > 1 ? (
      <div
        title="Page suivante"
        onClick={goToNextPage}
        className="page-arrow"
        style={{
          paddingLeft: 7,
          paddingRight: 3,
          height: props.isMobile ? '100%' : '100vh'
        }}
      >
        <ArrowForwardIosIcon />
      </div>
    ) : (
      ''
    );

  // The svg viewbox changes on mobile
  let positions = !props.isMobile ? hexagonsPositions : hexagonsMobilePositions;
  let sizes = !props.isMobile ? hexagonsSizes : hexagonsMobileSizes;

  const viewBox = !props.isMobile ? '0 0 1280 1080' : '0 0 600 840';

  const SVGWrapper = ({ children }) =>
    isIE ? (
      <>{children}</>
    ) : (
      <svg
        width="100%"
        // height={props.isMobile ? '100%' : '100vh'}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={transi}
        onAnimationEnd={() => setTransi('')}
        // minHeight="100%"
        style={{ zIndex: 0 }}
      >
        {children}
      </svg>
    );

  return (
    <div style={{ textAlign: 'center', overflow: 'auto' }}>
      {props.phase < 2 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            maxHeight: '75vh',
            maxWidth: '100%',
            overflow: 'auto',
            minHeight: '300px'
          }}
        >
          <BackPageBtn />
          {/* <SVGWrapper
            width="100%"
            // height={props.isMobile ? '100%' : '100vh'}
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
            className={transi}
            onAnimationEnd={() => setTransi('')}
            // minHeight="100%"
            style={{ zIndex: 0 }}
          > */}

          {SVGWrapper({
            children:
              hexagonPages.length > 0
                ? hexagonPages[currPage].map((_, idx) => {
                    return (
                      <HexagonObject
                        timer={props.timer}
                        onOpenComment={props.onOpenComment}
                        active={props.active}
                        isReplay={props.isReplay}
                        currentComment={props.currentComment}
                        // active={props.canVote}
                        flashing={props.flashing}
                        hexagon={hexagonPages[currPage][idx]}
                        onCellClick={props.onCellClick}
                        position={positions[hexagonPages[currPage].length - 1][idx]}
                        size={sizes[hexagonPages[currPage].length - 1]}
                        className={idx === 0 ? 'session-hexagon' : ''}
                        key={`hex-${currPage}-${idx}`}
                        commenting={props.commenting}
                        editedComment={props.editedComment}
                      />
                    );
                  })
                : ''
          })}
          {/* {hexagonPages.length > 0
              ? hexagonPages[currPage].map((_, idx) => {
                  return (
                    <HexagonObject
                      onOpenComment={props.onOpenComment}
                      active={props.active}
                      isReplay={props.isReplay}
                      currentComment={props.currentComment}
                      // active={props.canVote}
                      flashing={props.flashing}
                      hexagon={hexagonPages[currPage][idx]}
                      onCellClick={props.onCellClick}
                      position={positions[hexagonPages[currPage].length - 1][idx]}
                      size={sizes[hexagonPages[currPage].length - 1]}
                      className={idx === 0 ? 'session-hexagon' : ''}
                      key={`hex-${currPage}-${idx}`}
                      commenting={props.commenting}
                    />
                  );
                })
              : ''} */}
          {/* </SVGWrapper> */}
          <NextPageBtn />
        </div>
      ) : (
        <HexagonObject
          active={props.active}
          flashing={props.flashing}
          hexagon={hexagons[props.phase - 2]}
          onCellClick={props.onCellClick}
          // position={positions[hexagonPages[currPage].length - 1][idx]}
          // size={sizes[hexagonPages[currPage].length - 1]}
          // className={idx === 0 ? 'session-hexagon' : ''}
        />
      )}
    </div>
  );
}

HexagonsGrid.propTypes = {
  hexagons: PropTypes.array.isRequired,
  defaultSize: PropTypes.number,
  maxSize: PropTypes.number,
  onPageChange: PropTypes.func,
  height: PropTypes.string,
  onCellClick: PropTypes.func
};

HexagonsGrid.defaultProps = {
  defaultSize: 4,
  height: '100vh'
};

export default HexagonsGrid;
