/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import axios from 'axios';
import NavigationBar from '../../components/NavigationBar';
import { withRouter } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import './PreparationDeposit.scss';
import Dialog from '../../components/dialog/Dialog';
import videoIcon from '../../styles/assets/icons/debate_logo.svg';
import { GET_FOLDER, UPLOAD_FILE } from '../../services/api_routes';
import { LinearProgress, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { theme } from '../../styles/ui_theme';
import ReactPlayer from 'react-player';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useSnackbar } from 'notistack';
import { isEdge, isMobileSafari, isSafari, isIE } from 'react-device-detect';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import DepositRecordingDialog from './components/DepositRecordingDialog';

const INITIAL_UPLOAD_STATE = {
  file: null,
  uploading: false,
  mode: 'file',
  progress: 0,
  cancelSource: null,
  result: {
    finished: false,
    file_url: false
  }
};

const useStyles = makeStyles(_ => ({
  bar: { backgroundColor: theme.palette.primary },
  root: { height: 10, backgroundColor: '#F4F4F4' }
}));

function PreparationDeposit({ location, match, history, urlConnexionGar, token }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();

  // const [state, dispatch] = React.useReducer
  const [folder, setFolder] = React.useState({});
  const [loadingFolder, setLoadingFolder] = React.useState(true);
  const [uploadState, setUploadState] = React.useState(INITIAL_UPLOAD_STATE);

  // MODALS
  const [showFileTooLargeDialog, setShowFileTooLargeDialog] = React.useState(false);
  const [showPrestationSentDialog, setShowPrestationSentDialog] = React.useState(false);
  const [showMediaRecorderDialog, setShowMediaRecorderDialog] = React.useState(false);
  const [showMobileDialog, setShowMobileDialog] = React.useState(false);
  const [showCancelDialog, setShowCancelDialog] = React.useState(false);

  React.useEffect(() => {
    const fetchFolder = async () => {
      const res = await axios.get(GET_FOLDER + '?token=' + match.params.token);
      if (res.status === 200) {
        setFolder(res.data);
        setLoadingFolder(false);
      }
    };
    if (location.state) {
      setFolder({ ...location.state.folder });
      setLoadingFolder(false);

      if (location.state.folder.submitted) {
        history.replace('/');
      }
    } else {
      fetchFolder();
    }
  }, [location.pathname]);

  const upload = React.useRef();

  /** LOGIC */
  async function uploadFile(file) {
    const data = new FormData();
    data.append('file', file);

    const cancelSource = axios.CancelToken.source();
    setUploadState(uploadState => ({ ...uploadState, cancelSource }));

    const res = await axios.post(UPLOAD_FILE + '?token=' + folder.token, data, {
      cancelToken: cancelSource.token,
      headers: {
        Authorization: axios.defaults.headers.common['Authorization'],
        'Content-type': 'multipart/form-data'
      },
      onUploadProgress: ({ loaded, total }) =>
        setUploadState(uploadState => ({ ...uploadState, progress: Math.round((loaded * 100) / total) })),
      validateStatus: status => status < 403
    });

    if (res.status === 200) {
      setUploadState(uploadState => ({ ...uploadState, result: { finished: true, file_url: res.data.file_url } }));
    } else if (res.status === 400) {
      enqueueSnackbar(res.data.error, { variant: 'error' });
      setUploadState(INITIAL_UPLOAD_STATE);
    }

    return res;
  }

  const onUploadFile = file => {
    setShowMediaRecorderDialog(false);

    if (ReactPlayer.canPlay(file.name)) {
      if (file.size > 50 * Math.pow(10, 6)) {
        setShowFileTooLargeDialog(true);
      } else {
        setUploadState(uploadState => ({ ...uploadState, file, uploading: true, mode: 'file' }));
        uploadFile(file);
      }
    } else {
      enqueueSnackbar("Ce format de fichier n'est pas accepté.", { variant: 'error' });
    }
  };

  const onValidateCancelUpload = () => {
    if (uploadState.cancelSource) uploadState.cancelSource.cancel();
    setUploadState({ ...INITIAL_UPLOAD_STATE });

    setShowCancelDialog(false);
  };

  const onCancelUpload = () => {
    setShowCancelDialog(true);
  };

  const onPreparationValidated = () => {
    setShowPrestationSentDialog(false);
    history.push('/list');
  };

  /** UI ELEMENTS */

  /***** MODALS */
  const onCancelDialogToggle = () => setShowCancelDialog(open => !open);
  const CancelDialog = () => (
    <Dialog
      open={showCancelDialog}
      onClose={onCancelDialogToggle}
      title=" "
      content={
        <div className="text-center">
          <h3>Attention</h3>
          <div className="subtitle">Cette action supprimera votre enregistrement.</div>

          <div className="mt-4 d-flex flex-wrap mt-2" style={{ gap: 20 }}>
            <button className="fullwidth mt-4 btn btn-outline" onClick={onCancelDialogToggle} style={{ flex: 1 }}>
              Annuler
            </button>
            <button className="fullwidth mt-4 btn btn-primary" onClick={onValidateCancelUpload} style={{ flex: 1 }}>
              Recommencer
            </button>
          </div>
        </div>
      }
    />
  );

  const onFileTooLargeDialogToggle = () => setShowFileTooLargeDialog(open => !open);
  const FileTooLargeDialog = () => (
    <Dialog
      open={showFileTooLargeDialog}
      onClose={onFileTooLargeDialogToggle}
      title=" "
      content={
        <div className="text-center">
          <h3>Fichier trop lourd</h3>
          <div className="subtitle">
            Votre fichier dépasse la taille maximale autorisée de
            <br />
            50 Mo. Compressez-le ou choisissez-en un autre.
          </div>
          <button style={{ width: '8em' }} className="mt-4 btn btn-primary" onClick={onFileTooLargeDialogToggle}>
            OK
          </button>
        </div>
      }
    />
  );

  const onMobileDialogToggle = () => setShowMobileDialog(open => !open);
  const MobileDialog = () => (
    <Dialog
      open={showMobileDialog}
      onClose={onMobileDialogToggle}
      title=" "
      content={
        <div className="text-center">
          <h3>Depuis votre mobile</h3>
          <div>
            Ouvrez l'application Mon oral Projet Voltaire sur votre smartphone, et authentifiez-vous avec ce code 2D,
            pour commencer votre enregistrement.
          </div>
          <div className="mt-4">
            <QRCode value={urlConnexionGar || token} />
          </div>
          <div className="mt-4">
            Téléchargez l'application{' '}
            <span style={{ color: '#A2D146', fontWeight: 'bold' }}>Mon oral Projet Voltaire</span> sur les stores :
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '30px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <a href="#">
              <img
                src={require('../../styles/assets/app-store-badge.svg')}
                width={145}
                height={45}
                alt="logo app store"
              />
            </a>
            <a href="#">
              <img
                src={require('../../styles/assets/google-play-badge.png')}
                width={155}
                height={65}
                alt="logo google play"
              />
            </a>
          </div>
        </div>
      }
    />
  );

  const onPrestationSentDialogToggle = () => setShowPrestationSentDialog(open => !open);
  const PrestationSentDialog = () => (
    <Dialog
      open={showPrestationSentDialog}
      onClose={onPrestationSentDialogToggle}
      title=" "
      content={
        <div className="text-center">
          <h3>Prestation envoyée</h3>
          <div className="subtitle">
            Votre prestation a bien été transmise à votre enseignant.
            <br />
            Une fois qu'il l'aura évalué vous retrouverez votre note
            <br />
            dans vos sessions.
          </div>
          <button style={{ width: '8em' }} className="mt-4 btn btn-primary" onClick={onPreparationValidated}>
            OK
          </button>
        </div>
      }
    />
  );

  const onMediaRecorderDialogToggle = () => setShowMediaRecorderDialog(open => !open);
  const MediaRecorderDialog = () => (
    <DepositRecordingDialog
      mode={folder.prestationMode}
      onUpload={onUploadFile}
      open={showMediaRecorderDialog}
      fullScreen={fullScreen}
      onClose={onMediaRecorderDialogToggle}
    />
  );

  const isSupported = !(isSafari || isMobileSafari || isEdge || isIE);

  /****** SCREENS */
  const DepositList = () => (
    <div className="content-data prep-selection">
      <div className="prep-selection-title">Enregistrez votre prestation</div>
      <div style={{ width: '100%', maxWidth: '30em' }}>
        <div className="deposit-card" onClick={onMobileDialogToggle}>
          <div className="deposit-card-icon">
            <PhoneIphoneIcon fontSize="inherit" />
          </div>
          <div className="deposit-card-title">Depuis votre mobile</div>
        </div>
        <div
          className="deposit-card"
          onClick={isSupported ? onMediaRecorderDialogToggle : () => {}}
          style={{ paddingBottom: 10 }}
        >
          <div className="deposit-card-icon">
            <PhotoCameraIcon fontSize="inherit" />
          </div>
          <div className="deposit-card-title">Depuis votre webcam</div>
          {!isSupported ? (
            <div className="subtitle">Seul Chrome et Firefox sont supportés.</div>
          ) : (
            <div className="subtitle">(Durée maximale : 20 min)</div>
          )}
        </div>
        <div className="deposit-card" style={{ paddingBottom: 10 }} onClick={() => upload.current.click()}>
          <input
            ref={upload}
            type="file"
            style={{ display: 'none' }}
            accept={folder.prestationMode === 'video' ? 'video/*' : 'audio/*'}
            onChange={e => onUploadFile(e.target.files[0])}
          />
          <div className="deposit-card-icon">
            <CloudDownloadIcon fontSize="inherit" />
          </div>
          <div className="deposit-card-title">Importer un fichier</div>
          <div className="subtitle">(Taille maximale : 50 Mo)</div>
        </div>

        {/* <a href="#">Comment compresser un fichier ?</a> */}
      </div>
    </div>
  );

  const UploadScreen = () => (
    <div className="content-data prep-selection">
      {!uploadState.result.finished && (
        <>
          <div className="prep-selection-title">
            {uploadState.mode === 'file' ? 'Importer un fichier' : 'Depuis votre webcam'}
          </div>
          <div style={{ width: '100%', maxWidth: '20em' }}>
            <img src={videoIcon} style={{ width: '100%', marginTop: 10 }} alt="Icone Video" />
            <div className="mt-2">
              <strong>{uploadState.file.name}</strong>
            </div>

            <div className="mt-4">
              <LinearProgress classes={classes} value={uploadState.progress} variant="determinate" />
            </div>
            <div className="subtitle mb-4">Transfert en cours {uploadState.progress} %</div>
          </div>
        </>
      )}

      {uploadState.result.finished && (
        <div className="text-center mt-4">
          <h3>Travail rendu</h3>
          <div className="subtitle">
            Votre travail a bien été envoyé à votre enseignant(e).
            <br />
            Une fois qu'il aura été corrigé, votre note sera visible.
          </div>
          <button style={{ width: '8em' }} className="mt-4 btn btn-primary" onClick={onPreparationValidated}>
            OK
          </button>
        </div>
      )}

      {/* {uploadState.result.finished && 
        <>
          <div className="mt-4">Votre fichier est chargé et prêt à être envoyé.</div>
          <div>Cliquez sur "Envoyer" pour le transmettre à votre enseignant.</div>
          <div><button style={{ width: "13em" }} className="btn btn-primary mt-4" onClick={onValidateUpload}>Envoyer</button></div>
        </>
      } */}

      {!uploadState.result.finished && (
        <div>
          <button style={{ width: '13em' }} className="btn btn-outline mt-2" onClick={onCancelUpload}>
            Annuler
          </button>
        </div>
      )}
    </div>
  );

  const submissionDate = new Date(folder.closeDate);
  submissionDate.setHours(0, 0, 0, 0);

  const today = new Date(Date.now());
  today.setHours(0, 0, 0, 0);

  const isSubmissionLate = folder.closeDate ? today > submissionDate : false;

  return loadingFolder ? (
    <div>
      <Spinner />
    </div>
  ) : (
    <div style={{ height: '90%' }}>
      <NavigationBar title={folder.title} to="/list" />

      <FileTooLargeDialog />
      <PrestationSentDialog />
      <MediaRecorderDialog />
      <MobileDialog />
      {CancelDialog()}

      <div className="body-wrapper">
        <div className="content-wrapper">
          <div className="content-sidebar p-4 text-center">
            <div>
              <div className="prestation-title justify-content-center mb-2">
                <div className="p">{folder.prestationMode === 'audio' ? <VolumeUpIcon /> : <VideoLibraryIcon />}</div>
                <div>
                  <h2>Prestation {folder.prestationMode === 'audio' ? 'Audio' : 'Vidéo'}</h2>
                </div>
              </div>

              <div
                className="text-center pastille-lightblue uppercase"
                style={{ margin: '0 auto', width: '8em', fontSize: '1.1rem', fontWeight: '900' }}
              >
                S'entraîner à l'oral
              </div>
              <div className="subtitle mt-2">
                <small>Demandée depuis le {new Date(folder.timeCreated).toLocaleDateString('fr-FR')}</small>
                <br />
                <small>
                  À rendre le{' '}
                  <span style={{ color: isSubmissionLate ? 'red' : '' }}>
                    {new Date(folder.closeDate).toLocaleDateString('fr-FR')}
                  </span>
                </small>
              </div>
            </div>
            <div>
              <hr />
            </div>
            {folder.description && (
              <div className="mt-2">
                <h2>Consigne</h2>
                <div>
                  {folder.description.split('\n').map(str => (
                    <>
                      {str}
                      <br />
                    </>
                  ))}
                </div>
              </div>
            )}
          </div>

          {uploadState.uploading ? UploadScreen() : <DepositList />}
        </div>
      </div>
    </div>
  );
}

export default connect(({ auth: { user: { teacher, userKind, urlConnexionGar, token } } }) => ({
  isTeacher: teacher,
  isGAR: userKind === 'woonoz',
  urlConnexionGar,
  token
}))(withRouter(PreparationDeposit));
