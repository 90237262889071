import isEmpty from 'is-empty';
import {
  SET_CURRENT_USER,
  USER_LOADING,
  FETCH_USER_STATS,
  SET_CURRENT_GROUP,
  SET_CURRENT_HEXAGON_GROUP,
  SET_EXERCISES_COUNT
} from './actions';

const initialState = {
  isAuthenticated: false,
  user: {
    experience: 0,
    persuasionRate: 0,
    userKind: 'woonoz',
    currentHexagonGroup: 0,
    dismissedTutorials: [],
    finishedGuidedTours: [],
  },
  exercisesCount: 1,
  loading: false,
  currentGroupToken: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_EXERCISES_COUNT:
      return {
        ...state,
        exercisesCount: action.payload
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: { ...action.payload, persuasionRate: 0, experience: 0, userKind: 'woonoz' }
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_CURRENT_GROUP:
      return { ...state, currentGroup: action.payload };
    case SET_CURRENT_HEXAGON_GROUP:
      return { ...state, user: { ...state.user, currentHexagonGroup: action.payload } };
    case FETCH_USER_STATS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    default:
      return state;
  }
}
