import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NavigationBar from '../../components/NavigationBar';
import useApi from '../../hooks/api/useApi';
import useApiPrepare from '../../hooks/api/useApiPrepare';
import {
  GET_ALL_TUTOS,
  GET_ALL_THEMES,
  CREATE_TUTO_THEME,
  CREATE_TUTO_VIDEO,
  DELETE_TUTO_VIDEO,
  DELETE_TUTO_THEME
} from '../../services/api_routes';
import TextInput from '../../components/TextInput';
import './TutorialsAdmin.scss';
import MuiSelect from '../../components/forms/MuiSelect';
import { MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '../../components/IconButton';
import { withSnackbar } from 'notistack';
import { AlertContext } from '../../hooks/context/AlertProvider';
import Dialog from '../../components/dialog/Dialog';
import Spinner from '../../components/Spinner';

const VideoCard = ({ video, themes, screens, onSubmit, onDelete, uploadState }) => {
  const [state, setState] = useState(video);

  useEffect(() => {
    setState({ ...video });
  }, [video]);

  const onChange = e => {
    e.persist();
    setState(s => ({ ...s, [e.target.name]: e.target.files ? e.target.files[0] : e.target.value }));
  };

  const onChangeScreen = idx => e => {
    e.persist();
    state.screens[idx] = { ...state.screens[idx], [e.target.name]: e.target.value };
    setState(s => ({ ...s }));
  };

  const onAddScreen = () => {
    setState(s => ({ ...s, screens: [...s.screens, { label: screens.length > 0 ? screens[0] : '', order: 1 }] }));
  };

  const onRemoveScreen = idx => () => {
    state.screens = state.screens.filter((_, i) => i !== idx);
    setState(s => ({ ...s }));
  };

  return (
    <div className="tut-admin-video">
      <TextInput label="Titre *" name="title" value={state.title} onChange={onChange} placeholder="Nouvelle vidéo" />
      <TextInput
        label="Description"
        name="description"
        value={state.description}
        onChange={onChange}
        placeholder="Description de la vidéo"
      />

      <div>
        <label className="lightblue">Vidéo {video.url ? '(non modifiable)' : '*'} </label>
        {video.url ? (
          <div>
            <video src={video.url} controls style={{ aspectRatio: '16/9' }} />
          </div>
        ) : (
          <input type="file" name="video" accept="video/*" onChange={onChange} />
        )}
      </div>

      <div>
        <label className="lightblue">Miniature {video.thumbnailUrl ? '(non modifiable)' : '*'}</label>
        {video.thumbnailUrl ? (
          <div>
            <img src={video.thumbnailUrl} alt="Miniature" style={{ maxWidth: '100%', aspectRatio: '16/9' }} />
          </div>
        ) : (
          <input type="file" name="thumbnail" accept="image/*" onChange={onChange} />
        )}
      </div>

      <div className="tut-admin-video-screen">
        <div className="screen-select">
          <label className="lightblue">Theme *</label>
          <div>
            <MuiSelect
              value={state.theme}
              name="theme"
              onChange={onChange}
              style={{ width: '100%', overflow: 'hidden' }}
            >
              {themes.map(t => (
                <MenuItem value={t._id}>{t.title}</MenuItem>
              ))}
            </MuiSelect>
          </div>
        </div>
        <TextInput
          label="Ordre *"
          value={state.themeOrder}
          name="themeOrder"
          style={{ width: '4em' }}
          onChange={onChange}
        />
      </div>

      <h3 className="lightblue mt-4">Ecrans associés *</h3>
      {state.screens.map(({ label, order }, idx) => (
        <div className="tut-admin-video-screen">
          <div className="screen-select">
            <label className="lightblue">Ecran *</label>
            <MuiSelect value={label} style={{ width: '100%' }} name="label" onChange={onChangeScreen(idx)}>
              {screens.map(s => (
                <MenuItem value={s}>{s}</MenuItem>
              ))}
            </MuiSelect>
          </div>
          <TextInput
            label="Ordre *"
            value={order}
            style={{ width: '4em' }}
            name="order"
            onChange={onChangeScreen(idx)}
          />
          <IconButton onClick={onRemoveScreen(idx)}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
      ))}

      <button className="btn btn-outline small fullwidth" onClick={onAddScreen}>
        Ajouter un écran
      </button>

      <div style={{ flexGrow: 1 }} />

      <button className="btn btn-primary small fullwidth" onClick={() => onSubmit(state)}>
        {video._id ? 'Modifier' : 'Créer'}
      </button>

      {video._id && (
        <button className="btn btn-danger small mt-4" onClick={() => onDelete(video._id)}>
          Supprimer
        </button>
      )}
    </div>
  );
};

const VideosSection = withSnackbar(({ videos, themes, screens, onReload, enqueueSnackbar }) => {
  const alertContext = React.useContext(AlertContext);
  const [uploadState, setUploadState] = useState({ uploading: false, progress: 0 });
  const [, , editVideo] = useApiPrepare(CREATE_TUTO_VIDEO, {}, 'post');
  const [, , deleteVideo] = useApiPrepare(DELETE_TUTO_VIDEO, {}, 'post');
  const emptyVideo = {
    title: '',
    description: '',
    themeOrder: 1,
    screens: [],
    theme: themes.length > 0 ? themes[0]._id : ''
  };

  const onSubmit = async video => {
    const formData = new FormData();
    Object.keys(video).forEach(key =>
      formData.append(key, key === 'video' || key === 'thumbnail' ? video[key] : JSON.stringify(video[key]))
    );

    if (!video._id) {
      setUploadState({ uploading: true });
    }

    const res = await editVideo(formData);
    if (res.status === 200) {
      enqueueSnackbar('Action réussie !', { variant: 'success' });
      onReload();
    }

    setUploadState({ uploading: false });
  };

  const onDelete = async _id => {
    alertContext.confirm(
      {
        title: 'Attention!',
        content: 'Êtes-vous sûr de vouloir supprimer cette vidéo ?'
      },
      async bool => {
        if (bool) {
          const res = await deleteVideo({ _id });
          if (res.status === 200) {
            enqueueSnackbar('Action réussie !', { variant: 'success' });
            onReload();
          }
        }
      }
    );
  };

  return (
    <div className="mt-4">
      <Dialog
        open={uploadState.uploading}
        onClose={() => setUploadState({ uploading: false })}
        title="Envoi en cours"
        content={
          <div>
            <div className="mt-2 mb-4 text-center">
              Cette action peut prendre <br />
              quelques minutes...
            </div>
            <Spinner />
          </div>
        }
      />

      <h2>Vidéos</h2>
      <div className="tut-admin-videos">
        {[...videos, emptyVideo].map(video => (
          <VideoCard video={video} themes={themes} screens={screens} onSubmit={onSubmit} onDelete={onDelete} />
        ))}
      </div>
    </div>
  );
});

const ThemeCard = ({ title, order, _id, onSubmit, onDelete }) => {
  const [state, setState] = useState({ title, order, _id });

  useEffect(() => {
    setState({ title, order, _id });
  }, [title, order, _id]);

  return (
    <div className="tut-admin-theme">
      <div>
        <TextInput
          label="Titre *"
          value={state.title}
          onChange={e => setState({ ...state, title: e.target.value })}
          placeholder="Nouveau thème"
        />
      </div>
      <div>
        <TextInput
          label="Ordre *"
          value={state.order}
          onChange={e => setState({ ...state, order: e.target.value })}
          placeholder="1"
        />
        <div className="subtitle">
          (Position du thème sur la page des vidéos, 1 = tout en haut, 2 = en-dessous, ...)
        </div>
      </div>
      <button className="btn btn-primary small fullwidth" onClick={() => onSubmit(state)}>
        {_id ? 'Modifier' : 'Créer'}
      </button>

      {_id && (
        <button className="btn btn-danger small fullwidth mt-4" onClick={() => onDelete(_id)}>
          Supprimer
        </button>
      )}
    </div>
  );
};

const ThemesSection = withSnackbar(({ themes, onReload, enqueueSnackbar }) => {
  const alertContext = React.useContext(AlertContext);
  const [, , editTheme] = useApiPrepare(CREATE_TUTO_THEME, {}, 'post');
  const [, , deleteTheme] = useApiPrepare(DELETE_TUTO_THEME, {}, 'post');
  const emptyTheme = { title: '', order: '', _id: undefined };

  const onSubmit = async theme => {
    const res = await editTheme(theme);
    if (res.status === 200) {
      onReload();
      enqueueSnackbar('Action réussie ! ', { variant: 'success' });
    }
  };

  const onDelete = async _id => {
    alertContext.confirm(
      {
        title: 'Attention!',
        content: 'Êtes-vous sûr de vouloir supprimer ce thème ?'
      },
      async bool => {
        if (bool) {
          const res = await deleteTheme({ _id });

          if (res.status === 200) {
            enqueueSnackbar('Action réussie !', { variant: 'success' });
            onReload();
          }
        }
      }
    );
  };

  return (
    <div>
      <h2>Thèmes</h2>
      <div className="tut-admin-themes">
        {[...themes, emptyTheme].map(theme => (
          <ThemeCard {...theme} onSubmit={onSubmit} onDelete={onDelete} />
        ))}
      </div>
    </div>
  );
});

const TutorialsAdmin = props => {
  const isAdmin = useSelector(state => state.auth.user.adminTutorials);
  if (!isAdmin) window.location.href = '/';

  const [tutoVideos, , reloadVideos] = useApi(GET_ALL_TUTOS, { videos: [], screens: [] });
  const [tutoThemes, , reloadThemes] = useApi(GET_ALL_THEMES);

  return !isAdmin ? (
    ''
  ) : (
    <>
      <NavigationBar title="Administration des tutoriels" to="/home" />
      <div className="tut-admin-wrapper">
        <ThemesSection themes={tutoThemes} onReload={reloadThemes} />
        <VideosSection
          videos={tutoVideos.videos}
          themes={tutoThemes}
          screens={tutoVideos.screens}
          onReload={reloadVideos}
        />
      </div>
    </>
  );
};

export default withSnackbar(TutorialsAdmin);
