import React, { useState } from 'react';
import { Grid, Paper, FormGroup, TextField, Button, Table, TableHead, TableCell, TableRow, IconButton } from '@material-ui/core';
import useApi from '../hooks/api/useApi';
import useApiPrepare from '../hooks/api/useApiPrepare';
import { CREATE_SCHOOL, DELETE_SCHOOL, EDIT_SCHOOL, GET_SCHOOLS } from '../services/api_routes';
import Spinner from '../components/Spinner';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import useForm from '../hooks/useForm';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '../components/dialog/Dialog';

const initialForm = {
  name: '',
  email: '',
  maxAccounts: 0,
  expirationDate: Date.now()
}

const SchoolEditionForm = ({ title, onSubmit, initialInputs = initialForm, mode='creation'}) => {
  const [inputs, handleInputChange, updateInput] = useForm(initialInputs);

  return (
      <Paper elevation={3} style={{padding: 20}}>
        <h2>{ title }</h2>
        <form>
          <FormGroup>
            <TextField label="Nom" name="name" value={inputs.name} onChange={handleInputChange} className="mt-2" />
            <TextField label="E-mail" name="email" value={inputs.email} onChange={handleInputChange} type="email" disabled={mode !== 'creation'} className="mt-2" />
            <MuiPickersUtilsProvider utils={DateFnsUtils} className="p-2">
              <DatePicker
                className="mt-2"
                disableToolbar
                variant="inline"
                autoOk
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Date de fin de licence"
                name="expirationDate"
                onChange={date => updateInput("expirationDate", new Date(date))}
                value={inputs.expirationDate}
              />
            </MuiPickersUtilsProvider>
            <TextField type="number" label="Nombre de professeurs" name="maxAccounts" value={inputs.maxAccounts} onChange={handleInputChange} className="mt-2" />
          </FormGroup>
          <small>
            Un compte pour l'administrateur de l'école est automatiquement créé pour le mail donné. Un mot de passe lui est envoyé par mail.
          </small>

          <Button fullWidth color="primary" variant="contained" style={{ marginTop: '15px' }} onClick={() => onSubmit(inputs)}>
            {mode === 'creation' ? "Créer" : "Modifier"}
          </Button>
        </form>
      </Paper>
  )
}

const Admin = props => {
  const [editingSchool, setEditingSchool] = useState(false);
  const [schoolEdited, setSchoolEdited] = useState({});

  const [schools, isLoading, , setSchools] = useApi(GET_SCHOOLS);
  const [, , createSchool] = useApiPrepare(CREATE_SCHOOL, '', 'post');
  const [, , deleteSchool] = useApiPrepare(DELETE_SCHOOL, '', 'post');

  const [, , editSchool] = useApiPrepare(EDIT_SCHOOL, '', 'post');

  const onCreateSchoolClick = async data => {
    const { email, ...school } = data;

    const res = await createSchool({ school, email});
    if (res.status === 200) {
      setSchools([...schools, res.data])
    }
    
  };

  const onEditSchoolClick = school => () => {
    setSchoolEdited({ ...school, email: school.admin.email });
    setEditingSchool(true);
  }

  const onEditSchool = async data => {
    const { email, ...school } = data;
    const res = await editSchool({ school ,email });
    
    // Update the school
    if (res.status === 200) {
      const tmpSchools = [...schools];

      const schoolIdx = tmpSchools.findIndex(s => s._id === res.data._id);
      tmpSchools[schoolIdx].name = data.name;
      tmpSchools[schoolIdx].expirationDate = data.expirationDate;
      tmpSchools[schoolIdx].maxAccounts = data.maxAccounts;

      setSchools(tmpSchools);
      setEditingSchool(false);
    }
  }



  // School delete
  const onDeleteSchoolClick = id => async () => {
    if (window.confirm("Êtes vous sûr de vouloir supprimer cette école ?")) {
      const res = await deleteSchool({ id });
      
      // remove the school from the list
      if (res.status === 200) {
        const schoolToRemoveIdx = schools.findIndex(s => s._id === res.data.id);

        if (schoolToRemoveIdx > -1) {
          const tmpSchools = [...schools];
          tmpSchools.splice(schoolToRemoveIdx, 1);

          setSchools(tmpSchools)
        }
      }
    }
  }
  
  // const onEditSchoolClick = school => 

  return isLoading ? <Spinner /> : (
    <Grid container style={{padding: 20}}>
      <Dialog open={editingSchool} onClose={() => setEditingSchool(false)} 
        content={<SchoolEditionForm title="Editer une école" initialInputs={schoolEdited} onSubmit={onEditSchool} mode="edition" />} />

      <Grid xs={4} style={{padding: 10}}>
        <SchoolEditionForm title="Ajouter une école" onSubmit={onCreateSchoolClick} />
      </Grid>
      <Grid xs={8} style={{padding: 10}}>
        <Paper elevation={3} style={{padding: 20}}>
          <h2>Toutes les écoles</h2>
          <Table>
            <TableHead>
              <TableCell>Nom</TableCell>
              <TableCell>Administrateur</TableCell>
              <TableCell>Expiration</TableCell>
              <TableCell>Nombre de comptes</TableCell>
              <TableCell></TableCell>
            </TableHead>
            {schools.map(school => (
              <TableRow key={school.email}>
                  <TableCell item>{school.name}</TableCell>
                  <TableCell item>{school.admin.email}</TableCell>
                  <TableCell item>{new Date(school.expirationDate).toLocaleDateString()}</TableCell>
                  <TableCell item>{school.maxAccounts}</TableCell>
                  <TableCell>
                    <Grid container justify="space-around">
                      <IconButton onClick={onEditSchoolClick(school)}><EditIcon /></IconButton>
                      <IconButton onClick={onDeleteSchoolClick(school._id)}><DeleteIcon style={{color: 'red'}} /></IconButton>
                    </Grid>
                  </TableCell>
              </TableRow>
            ))}
          </Table>
        </Paper>
        </Grid>
    </Grid>
  )
}

export default Admin;