/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer } from 'react';
import useApiPrepare from '../../hooks/api/useApiPrepare';
import useForm from '../../hooks/useForm';
import { GET_ALL_HEXAGONS, CREATE_HEXAGON, GET_EDIT_HEXAGONS, EDIT_HEXAGON } from '../../services/api_routes';
import { connect } from 'react-redux';
import Hexagon from '../../components/Hexagon';
import ArgumentModal from './components/ArgumentModal';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import GroupSelectionModal from './components/GroupSelectionModal';
import { FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@material-ui/core';
import Spinner from '../../components/Spinner';
import { withSnackbar } from 'notistack';
import LockIcon from '@material-ui/icons/Lock';
import Dialog from '../../components/dialog/Dialog';
import MuiButton from '../../components/MuiButton';

const emptyArgGroups = hexagonSize => {
  let argumentGroups = [];

  for (let grp = 0; grp < 6; grp++) {
    argumentGroups.push({ title: '', arguments: [], groupsForArguments: [] });
    for (let args = 0; args < hexagonSize / 6; args++) {
      argumentGroups[grp].arguments.push({
        _id: '',
        rating: 0,
        name: '[Argument]',
        description: ''
      });
      argumentGroups[grp].groupsForArguments.push(5);
    }
  }

  return argumentGroups;
};

const hexSizes = [6, 12, 18, 24, 30, 36];

const initialState = {
  showGroupModal: false,
  showArgumentModal: false,
  clickedArgument: -1,
  selectedArgument: -1
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'click_argument':
      return {
        ...state,
        showArgumentModal: true,
        clickedArgument: action.payload
      };
    case 'select_argument':
      return {
        ...state,
        selectedArgument: action.payload,
        showGroupModal: true,
        showArgumentModal: false
      };
    case 'hide_modal':
      return { ...state, [action.payload]: false };
    case 'reset':
      return initialState;
    default:
      return state;
  }
};

const ControlCreation = props => {
  const [inputs, handleInputChange, updateInput] = useForm({
    hexagonSize: 18,
    refName: '',
    editHexRef: '',
    duplicateHexName: '',
    editHexPrecision: -1
  });
  const [newControl, setNewControl] = useState({
    idx: 0,
    phase: 1,
    name: '',
    argumentGroups: emptyArgGroups(inputs.hexagonSize),
    buzzerArgument: { rating: 0, name: '[Buzzer]', description: '' },
    groupForBuzzer: 5
  });
  const [currPrecisions, setCurrPrecisions] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);

  const [editHexagons,, fetchEditHexagons] = useApiPrepare(GET_EDIT_HEXAGONS);
  const [hexagons, isLoadingHexagons, fetchHexagons] = useApiPrepare(GET_ALL_HEXAGONS);
  const [, , editHexagon] = useApiPrepare(EDIT_HEXAGON, {}, 'post');
  const [newHexagonData, , createHexagon] = useApiPrepare(CREATE_HEXAGON, {}, 'post');

  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);

  const updateSize = event => {
    const size = event.target.value;
    updateInput(event.target.name, size);

    setNewControl(newControl => ({
      ...newControl,
      argumentGroups: emptyArgGroups(size)
    }));
  };

  useEffect(() => {
    fetchEditHexagons();
    fetchHexagons();
  }, [])

  useEffect(() => {
    if (hexagons.length > 0) updateInput('refName', hexagons[0].name);
  }, [hexagons]);

  useEffect(() => {
    if (props.mode === 'edition' && Object.keys(editHexagons).length > 0) {
      updateInput('editHexRef', Object.keys(editHexagons)[0]);
      // updateInput('editHexPrecision', 0);
    }
  }, [editHexagons]);

  useEffect(() => {
    if (props.mode === 'edition' && Object.keys(editHexagons).length > 0 && editHexagons[inputs.editHexRef]) {
      const control = editHexagons[inputs.editHexRef][inputs.editHexPrecision];
      setNewControl({ ...control , idx: newControl.idx + 1 });
    }
    
  }, [inputs.editHexPrecision])

  useEffect(() => {
    if (inputs.editHexRef) {
      // updateInput()
      // const split = inputs.editHexRef.split('-');
      // const hexRef = split.slice(0, split.length - 1).join('-');
      // const hexIdx = split[split.length - 1];

      const newPrecision = 0;
      updateInput('editHexPrecision', newPrecision)
      
      if (inputs.editHexPrecision === 0) {
        const control = editHexagons[inputs.editHexRef][newPrecision];
        if (control) {
          setNewControl({ ...control });
        }
      }
      // updateInput("duplicateHexName", inputs.editHexRef + ' - Copie');
    }
  }, [inputs.editHexRef]);

  useEffect(() => {
    if (newHexagonData === 'OK') {
      props.enqueueSnackbar("  👍 L'hexagone a été créé avec succès !  ", {
        variant: 'success'
      });
      props.history.push('/list');
    }
  }, [newHexagonData]);

  useEffect(() => {
    if (!isLoadingHexagons && hexagons.length > 0) {
      setCurrPrecisions(hexagons.filter(h => h.name === inputs.refName)[0].availablePrecisions);
    }
  }, [inputs.refName, hexagons, isLoadingHexagons]);

  const submitCreateControl = async e => {
    e.preventDefault();
    const { controlName: name, hexagonSize: size, refName } = inputs;

    const evaluationControl = { ...newControl };
    evaluationControl.name = name;
    evaluationControl.argumentGroups = newControl.argumentGroups.map(grp => ({
      title: grp.title,
      arguments: grp.arguments.map(arg => arg._id),
      groupsForArguments: grp.groupsForArguments
    }));
    evaluationControl.buzzerArgument = evaluationControl.buzzerArgument._id;

    createHexagon({
      name,
      refName,
      size,
      evaluationControl
    });
  };

  const onEditControl = () => {
    if (newControl.isPublic) {
      setShowDuplicateDialog(true);
    } else if (props.auth.user.admin || !newControl.isPublic) {
      submitEditedControl();
    }
  }

  const submitEditedControl = async e => {
    if (newControl.isPublic && !props.auth.user.admin) {
      newControl.name = inputs.duplicateHexName;
    }

    const res = await editHexagon({ newControl });
    if (res.status === 200) {
      props.enqueueSnackbar("L'hexagone a bien été modifié.", { variant: 'success' });
    }
  };

  const hideModal = name => () => dispatch({ type: 'hide_modal', payload: name });

  const onArgumentClick = (_, argument, id) => dispatch({ type: 'click_argument', payload: id });

  const onArgumentSelected = argument => dispatch({ type: 'select_argument', payload: argument });

  const onSubmitArgument = (pointGroup = 1) => {
    const { clickedArgument, selectedArgument } = state;
    const argument = selectedArgument;
    const cellsPerRow = (props.mode === 'creation' ? inputs.hexagonSize : (newControl.precision + 1) * 6) / 6;

    const argGrpIdx = Math.floor(clickedArgument / cellsPerRow);
    const argIdx = clickedArgument % cellsPerRow;

    // clicked argument was the buzzer
    if (clickedArgument === -1) {
      newControl.buzzerArgument = argument;
      newControl.groupForBuzzer = pointGroup;
    } else {
      newControl.argumentGroups[argGrpIdx].arguments[argIdx] = argument;
      newControl.argumentGroups[argGrpIdx].groupsForArguments[argIdx] = pointGroup;
    }

    setNewControl(newControl => {
      const r = {
        ...newControl,
        idx: newControl.idx + 1
      };

      return { ...r };
    });
    dispatch({ type: 'reset' });

    toast.success("👍 L'argument a bien été ajouté ! ");
  };

  const argsCountPerGroup = newControl.argumentGroups.reduce(
    (acc, grp) => {
      grp.groupsForArguments.forEach(arg => {
        if (arg <= 4) acc[arg]++;
      });
      return acc;
    },
    [0, 0, 0, 0, 0]
  );
  argsCountPerGroup[newControl.groupForBuzzer] += 1;

  return (
    <div
      className="text-center d-flex justify-content-center align-items-center flex-wrap"
      style={{ marginTop: '3em', textAlign: 'center' }}
    >
      {!props.auth.user.teacher && (
        <div
          style={{
            position: 'fixed',
            top: '5vh',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#222',
            opacity: 0.95,
            zIndex: 9999
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              right: '50%',
              bottom: '50%',
              textAlign: 'center',
              width: '400px',
              margin: -200
            }}
          >
            <div>
              <LockIcon style={{ fontSize: '16em' }} />
            </div>
            <div style={{ color: 'white', fontSize: '2em' }}>
              Cette page est réservée à nos utilisateurs professionnels.
              <small>
                <br />
                <a href="mailto:contact@retorate.com">Nous contacter</a>
              </small>
            </div>
          </div>
        </div>
      )}

      <Dialog open={showDuplicateDialog} onClose={() => setShowDuplicateDialog(false)} title="Vous êtes en train de modifier un hexagone Retorate" content={(
        <div>
          <div>Pour cela, il va falloir en créer une copie personnelle.</div>
          <TextField className="mt-2" label="Donnez-lui un nom:" fullWidth color="secondary"
            onChange={handleInputChange}
            value={inputs.duplicateHexName}
            name="duplicateHexName" />

          <MuiButton fullWidth className="mt-2" onClick={submitEditedControl}>Valider</MuiButton>
        </div>
      )} />

      <div
        style={{
          flex: 1,
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '4em'
        }}
      >
        {props.mode === 'creation' ? (
          <>
            <h2 style={{ marginBottom: '2em' }}>Configuration de l'hexagone</h2>
            <form
              style={{
                textAlign: 'center'
              }}
            >
              <div>
                <p style={{ textAlign: 'left' }}>
                  Sur Retorate, un hexagone correspond à un ensemble de sous-hexagones de taille variable. L'hexagone
                  "Eloquence", par exemple, a une version pour chaque taille possible.{' '}
                </p>
                <p style={{ textAlign: 'left' }}>
                  Vous pouvez choisir de soit créer une nouvelle "famille d'hexagone" en lui donnant un nom, soit de
                  simplement créer une nouvelle taille pour une famille existante, en rattachant à un hexagone existant.
                </p>
                <div className="text-left" style={{ width: '100%', marginBottom: '2em' }}>
                  <TextField
                    style={{ width: '100%' }}
                    variant="standard"
                    onChange={handleInputChange}
                    value={inputs.controlName}
                    name="controlName"
                    type="text"
                    placeholder="Laisser vide si vous ne souhaitez pas créer un nouvel hexagone indépendant"
                    label="Nom"
                  />
                </div>
                OU
                {isLoadingHexagons ? (
                  <Spinner />
                ) : (
                  <FormControl style={{ width: '100%', marginBottom: '2em' }} className="text-left">
                    <InputLabel id="hexa-ref-select">Rattacher à un Hexagone Existant</InputLabel>
                    <Select
                      labelId="hexa-ref-select"
                      style={{ width: '100%' }}
                      name="refName"
                      value={inputs.refName}
                      onChange={handleInputChange}
                    >
                      {hexagons.map(h => (
                        <MenuItem value={h.name}>{h.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <FormControl style={{ width: '100%', marginBottom: '2em', marginTop: '2em' }} className="text-left">
                <InputLabel id="hexa-size-select">Nombre de cases</InputLabel>
                <Select
                  labelId="hexa-size-select"
                  name="hexagonSize"
                  style={{ width: '100%' }}
                  onChange={updateSize}
                  value={inputs.hexagonSize}
                >
                  {hexSizes.map(size => (
                    <MenuItem value={size}>
                      {currPrecisions.includes(size / 6 - 1) ? '⚠️' : ''}
                      {size}
                    </MenuItem>
                  ))}
                </Select>
                <div style={{ fontSize: '10px', marginTop: 10 }}>
                  Si un champ est marqué d'un <span role="img" aria-label="panneau">"⚠️"</span>, cela veut dire qu'un hexagone de cette taille existe déjà pour
                  l'hexagone existant sélectionné. Si vous en créez un nouveau, l'ancien sera supprimé. Ignorez cet
                  avertissement si vous créer un nouvel hexagon en le nommant.
                </div>
              </FormControl>
              <div style={{ marginBottom: '2em' }}>
                <b>
                  Pour ajouter des arguments à l'hexagone, cliquez sur une case puis sélectionnez l'argument que vous
                  souhaitez ajouter, ou créez en un nouveau. <br />
                  Un hexagone doit être completement rempli avant d'être validé.
                </b>
              </div>
              {props.auth.user.teacher || props.auth.user.admin ? (
                <Button variant="contained" color="primary" onClick={submitCreateControl}>
                  Créer l'hexagone
                </Button>
              ) : (
                <p>
                  Vous n'avez pas les droits pour créer un nouvel hexagone.
                  <br />
                  <a href="mailto:contact@retorate.com" alt="send mail">
                    Nous contacter.
                  </a>
                </p>
              )}
            </form>
          </>
        ) : (
          <>
            <h2 style={{ marginBottom: '1em' }}>Editer un hexagone</h2>
            <p>Seuls les hexagones que vous avez créés sont éditables sur cette page.</p>
            <FormControl style={{ width: '100%', marginBottom: '2em', marginTop: '2em' }} className="text-left">
              <InputLabel id="hexa-size-select">Choisir un hexagone</InputLabel>
              <Select
                labelId="hexa-size-select"
                name="editHexRef"
                style={{ width: '100%' }}
                onChange={handleInputChange}
                value={inputs.editHexRef}
              >
                {Object.keys(editHexagons).map(hexRef => <MenuItem value={hexRef}>{hexRef}</MenuItem>)}
                {/* {Object.keys(editHexagons).reduce(
                  (acc, hexRef) =>
                    acc.concat(
                      editHexagons[hexRef]
                        .sort((a, b) => a.precision - b.precision)
                        .map((hexagon, idx) => (
                          <MenuItem value={`${hexRef}-${idx}`}>
                            {hexRef} - {(hexagon.precision + 1) * 6} cases
                          </MenuItem>
                        ))
                    ),
                  []
                )} */}
              </Select>
              </FormControl>

              {editHexagons[inputs.editHexRef] && 
              (<FormControl style={{ width: '100%', marginBottom: '2em' }} className="text-left">
                <InputLabel id="hexa-precision-select">Choisir une précision</InputLabel>
                <Select
                  labelId="hexa-precision-select"
                  name="editHexPrecision"
                  style={{ width: '100%' }}
                  onChange={handleInputChange}
                  value={inputs.editHexPrecision}
                >
                  {editHexagons[inputs.editHexRef].map((hexagon, idx) => <MenuItem value={idx}>{(hexagon.precision + 1) * 6} cases</MenuItem>)}
                  
                </Select>
                </FormControl>
              )}
            
            <div style={{ marginBottom: '2em' }}>
              <b>
                Pour ajouter des arguments à l'hexagone, cliquez sur une case puis sélectionnez l'argument que vous
                souhaitez ajouter, ou créez en un nouveau. <br />
                Un hexagone doit être completement rempli avant d'être validé.
              </b>
            </div>

            {props.auth.user.teacher || props.auth.user.admin ? (
              <Button variant="contained" color="primary" onClick={onEditControl}>
                Editer l'hexagone
              </Button>
            ) : (
              <p>
                Vous n'avez pas les droits pour créer un nouvel hexagone.
                <br />
                <a href="mailto:contact@retorate.com" alt="send mail">
                  Nous contacter.
                </a>
              </p>
            )}
          </>
        )}
      </div>

      <div style={{ width: '4em' }} />
      <div style={{ flex: 1 }}>
        <h2>Configuration des cases</h2>
        <div style={{ flex: 1 }}>
          <Hexagon
            id={newControl.idx}
            evaluationControl={{ ...newControl, id: newControl.idx }}
            cellNumber={props.mode === 'creation' ? inputs.hexagonSize : (newControl.precision + 1) * 6}
            active={true}
            buzzerLabel={newControl.buzzerArgument.name}
            onCellClick={onArgumentClick}
            onBuzzer
            phase={1}
          />
        </div>
      </div>

      <ArgumentModal
        open={state.showArgumentModal}
        onSubmit={onArgumentSelected}
        onClose={hideModal('showArgumentModal')}
      />

      <GroupSelectionModal
        open={state.showGroupModal}
        onSubmit={onSubmitArgument}
        onClose={hideModal('showGroupModal')}
        groupsRepartition={argsCountPerGroup}
        hexagonSize={inputs.hexagonSize}
      />
    </div>
  );
};

export default connect(state => ({ auth: state.auth }))(withRouter(withSnackbar(ControlCreation)));
