import { useState, useEffect } from 'react';

export default function useLongPress(
  callback = () => {},
  ms = 300,
  onTouchStart = () => {}
) {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timerId;
    if (startLongPress) {
      timerId = setTimeout(callback, ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: e => {
      e.stopPropagation();
      onTouchStart();
      setStartLongPress(true);
    },
    onTouchEnd: () => setStartLongPress(false)
  };
}
