import axios from "axios";

export const TEST_GET_LIST = "TEST_GET_LIST";

export const getList = () => async dispatch => {
  try {
    let { data } = await axios.get("/api/getList");
    dispatch({
      type: "TEST_GET_LIST",
      payload: data
    });
  } catch (err) {
    console.error(err);
  }
};
