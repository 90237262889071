import React from 'react';
import { Link } from 'react-router-dom';
import useApi from '../../../hooks/api/useApi';
import { GET_PENDING_ACTIVITIES } from '../../../services/api_routes';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Spinner from '../../../components/Spinner';
import { squareColors } from '../../../styles/palette';
import '../Home.scss';

/** === CONSTANTS === */
// Statuses definitions
const LATE = 'late';
const SOON_LATE = 'soon_late';
const TO_GRADE_EVAL = 'to_grade_eval';
const TO_GRADE_OTHER = 'to_grade_other';

const STATUS_ICONS = {
  [LATE]: { icon: <DateRangeIcon fontSize="inherit" />, bg: squareColors.red },
  [SOON_LATE]: { icon: <DateRangeIcon fontSize="inherit" />, bg: squareColors.red },
  [TO_GRADE_EVAL]: { icon: <AssignmentReturnedIcon fontSize="inherit" />, bg: squareColors.yellow },
  [TO_GRADE_OTHER]: { icon: <AssignmentTurnedInIcon fontSize="inherit" />, bg: squareColors.green }
};

const STATUS_TEXTS = {
  [LATE]: 'en retard',
  [SOON_LATE]: "à rendre d'ici 48 h",
  [TO_GRADE_EVAL]: 'avec note à valider',
  [TO_GRADE_OTHER]: 'à corriger'
};
/** =/= CONSTANTS =/= */

const PendingActivityRow = ({ title, token, mode, count, status, style, ...props }) => {
  const { icon, bg } = STATUS_ICONS[status];
  const iconStyle = { backgroundColor: bg };

  const titleText = `${count} ${count > 1 ? 'travaux' : 'travail'} ${STATUS_TEXTS[status]}`;
  const filter =
    status === LATE || status === SOON_LATE ? 'not_submitted' : status === TO_GRADE_EVAL ? 'all' : 'to_evaluate';
  const link = (mode === 'to_eval' ? '/evals/' : '/folders/') + token + '?filter=' + filter;

  return (
    <Link className="par-wrapper" to={link} style={style}>
      <div className="par-icon">
        <div className="par-icon-wrapper" style={iconStyle}>
          {icon}
        </div>
      </div>
      <div className="par-content">
        <div className="par-content-title">{titleText}</div>
        <div className="subtitle">{title}</div>
      </div>
      <div className="par-arrow">
        <ArrowForwardIosIcon fontSize="inherit" color="red" />
      </div>
    </Link>
  );
};

const HomePendingActivities = ({ group }) => {
  const [activities, isLoading] = useApi(GET_PENDING_ACTIVITIES + '?token=' + group.token);

  const PendingActivitiesEmpty = () =>
    isLoading ? (
      <Spinner />
    ) : (
      <div className="pa-empty">
        <div className="subtitle">
          Vous n'avez aucune activité en attente. <br /> Créez-en une !
        </div>
        <Link className={(group.isDemo ? 'disabled' : '') + ' btn btn-primary small mt-2 '} to="/sessionCreation">
          Créer une activité
        </Link>
      </div>
    );

  const PendingActivitiesList = () => (
    <div className="pa-list">
      <div className="pa-list-inner">
        {activities.map((activity, idx) => (
          <PendingActivityRow
            key={'act' + idx}
            style={{ border: idx === activities.length - 1 ? 'unset' : '' }}
            {...activity}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className="pa-wrapper">
      <div className="strong mb-2">Activités en attente</div>
      {activities.length === 0 ? <PendingActivitiesEmpty /> : <PendingActivitiesList />}
    </div>
  );
};

export default HomePendingActivities;
