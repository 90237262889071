import Axios from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import Dialog from '../../../components/dialog/Dialog';
import TextInput from '../../../components/TextInput';
import { CREATE_ARGUMENT, EDIT_ARGUMENT } from '../../../services/api_routes';
import '../GridsList.scss';
import GroupSelect from './GroupSelect';

const MAX_NAME_LENGTH = 20;
const MAX_DESCRIPTION_LENGTH = 140;

function ArgumentCreationModal({ open, onClose, onValidate, withGroup, argToEdit = null }) {
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = React.useState({ name: '', description: '' });

  const [pointsGroup, setPointsGroup] = React.useState(0);

  const reset = f => setForm(f || { name: '', description: '' });

  const onNameChange = e => {
    const name = e.target.value;
    if (name.length <= MAX_NAME_LENGTH) {
      setForm(f => ({ ...f, name }));
    }
  };

  const onDescriptionChange = e => {
    const description = e.target.value;
    if (description.length <= MAX_DESCRIPTION_LENGTH) {
      setForm(f => ({ ...f, description }));
    }
  };

  const isEditing = argToEdit !== null;

  React.useEffect(() => {
    if (!isEditing) {
      reset();
    }
  }, [isEditing, open]);

  React.useEffect(() => {
    if (isEditing) {
      reset(argToEdit);
    }
  }, [argToEdit, isEditing]);

  const onSubmit = async e => {
    e.preventDefault();

    const data = form;
    if (isEditing) data.id = argToEdit._id;

    const res = await Axios.post(isEditing ? EDIT_ARGUMENT : CREATE_ARGUMENT, data, { validateStatus: s => s < 401 });
    if (res.status === 200) {
      if (isEditing) res.data = data;

      onClose();
      enqueueSnackbar('Le critère a bien été ' + (isEditing ? 'modifié' : 'créé'), { variant: 'success' });

      onValidate({ ...res.data, pointsGroup });
    } else {
      enqueueSnackbar(res.data.error, { variant: 'error' });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title=" "
      content={
        <div className="acm">
          <h2 className="text-center">{!isEditing ? 'Nouveau critère' : 'Modifier le critère'}</h2>

          <form onSubmit={onSubmit}>
            <div>
              <div>
                <label>Nom</label>
                <small className="ml-2 subtitle">
                  {form.name.length}/{MAX_NAME_LENGTH}
                </small>
              </div>
              <div>
                <TextInput
                  className="fullwidth"
                  placeholder="Nom du critère"
                  value={form.name}
                  onChange={onNameChange}
                />
              </div>
            </div>

            <div className="mt-2">
              <div>
                <label>Description</label>
                <small className="ml-2 subtitle">
                  {form.description.length}/{MAX_DESCRIPTION_LENGTH}
                </small>
              </div>
              <div>
                <TextInput
                  className="fullwidth"
                  placeholder="Description"
                  value={form.description}
                  onChange={onDescriptionChange}
                  multiline
                  rows={4}
                />
              </div>
            </div>

            {withGroup && <GroupSelect value={pointsGroup} onChange={grp => () => setPointsGroup(grp)} />}

            <div className="acm-submit mt-4">
              <div>
                <button className="btn btn-outline fullwidth" type="button" onClick={onClose}>
                  Annuler
                </button>
              </div>
              <div>
                <button className="btn btn-primary fullwidth">Valider</button>
              </div>
            </div>
          </form>
        </div>
      }
    />
  );
}

export default ArgumentCreationModal;
