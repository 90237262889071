import React, { useState, useRef, useEffect, forwardRef } from 'react';
import recordrtc from 'recordrtc';
import AudioAnalyser from '../AudioVisualization/AudioAnalyser';
import { isChromium, isEdgeChromium, isChrome } from 'react-device-detect';
import './MediaRecorder.scss';

const MAX_RECORDING_DURATION = 20 * 60;

const MediaRecorder = forwardRef(({ mode, showTimeWarning, style, shouldTick }, ref) => {
  const [recordingState, setRecordingState] = useState({ isRecording: false, file: '' });
  const [timestamp, setTimestamp] = useState(0);
  const file = useRef(null);

  const playerRef = useRef();
  const recorderRef = useRef();

  const streamOptions = {
    video: mode === 'video',
    audio: { echoCancellation: false },
    width: { min: 480, max: 480 },
    height: { min: 270, max: 270 },
    maxFrameRate: 10
  };

  useEffect(() => {
    if (playerRef.current && !playerRef.current.srcObject) {
      initialize();
    }
  }, [playerRef.current]);

  /// EVENT HANDLERS
  const initialize = async () => {
    try {
      if (mode === 'video') {
        // First stream initialized when recording has not started
        const stream = await navigator.mediaDevices.getUserMedia(streamOptions);
        if (playerRef.current) {
          playerRef.current.srcObject = stream;
        }
      }

      if (playerRef.current) {
        playerRef.current.muted = true;
        playerRef.current.volume = 0;
        playerRef.current.controls = mode !== 'video';
      }
      setRecordingState({ isRecording: true, file: '' });
    } catch (err) {
      window.alert("La caméra n'est pas disponible.");
    }
  };

  const startRecording = async () => {
    try {
      if (playerRef.current) {
        const isEdge =
          navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);

        if (playerRef.current.srcObject) {
          playerRef.current.srcObject.stop();
        }

        // Second stream started when recording has started
        // We need two different streams so that the timer on the video is not wrong
        const stream = await navigator.mediaDevices.getUserMedia(streamOptions);

        playerRef.current.srcObject = stream;

        const constraints = {
          type: mode === 'video' ? 'video' : 'audio',
          numberOfAudioChannels: isEdge ? 1 : 2,
          videoBitsPerSecond: 200000,
          checkForInactiveTracks: true,
          timeSlice: 1000,
          onTimeStamp: (_, timestamps) => {
            setTimestamp(Math.round((new Date().getTime() - timestamps[0]) / 1000));
          }
        };

        if (mode === 'video' && (isChrome || isChromium || isEdgeChromium)) {
          constraints.mimeType = 'video/webm;codecs=vp8';
        }

        recorderRef.current = new recordrtc.RecordRTCPromisesHandler(stream, constraints);
        recorderRef.current.stream = stream;

        playerRef.current.controls = true;

        recorderRef.current.startRecording();
      }
    } catch (err) {
      window.alert("La caméra n'est pas disponible.");
    }
  };

  const stopRecording = async callback => {
    if (recorderRef.current) {
      await recorderRef.current.stopRecording();
      playerRef.current.srcObject = null;

      if (playerRef.current) {
        let blob = await recorderRef.current.getBlob();
        recordrtc.getSeekableBlob(blob, async seekableBlob => {
          if (mode === 'audio') seekableBlob = blob;

          if (playerRef.current) {
            playerRef.current.src = URL.createObjectURL(seekableBlob);
            playerRef.current.muted = false;
            playerRef.current.volume = 0.5;
          }

          recorderRef.current.stream.stop();
          recorderRef.current.stream.getTracks(t => {
            t.stop();
          });

          // await recorderRef.current.stop();
          await recorderRef.current.reset();
          await recorderRef.current.destroy();
          recorderRef.current = null;

          file.current = seekableBlob;
          setRecordingState({ isRecording: false, file: seekableBlob });

          if (callback) callback();

          return true;
        });
      }

      if (callback) callback();
      return true;
    } else {
      if (playerRef.current.srcObject) {
        playerRef.current.srcObject.stop();
        playerRef.current.srcObject.getTracks(t => {
          t.stop();
        });
        playerRef.current.srcObject = null;
        playerRef.current.src = null;

        setRecordingState({ isRecording: false, file: '' });
      }

      return true;
    }
  };

  const reset = () => {
    setRecordingState({ isRecording: false, file: '' });
    if (playerRef.current) {
      playerRef.current.srcObject = null;
      playerRef.current.src = null;
    }
  };

  const getCurrentTime = () => timestamp;

  const getFile = () => {
    const fileObject = new File([file.current], 'record.webm', {
      type: mode === 'audio' ? 'audio/webm' : 'video/webm'
    });

    return fileObject;
  };

  ref.current = { initialize, startRecording, stopRecording, reset, getFile, getCurrentTime };

  const { isRecording } = recordingState;

  return (
    <>
      {recorderRef.current && mode === 'audio' && <AudioAnalyser audio={recorderRef.current.stream} style={style} />}
      <div style={style}>
        {mode === 'video' ? (
          <video ref={playerRef} controls autoPlay={isRecording} playsInline className="fullwidth"></video>
        ) : (
          <audio ref={playerRef} controls autoPlay={isRecording} playsInline className="fullwidth"></audio>
        )}

        {showTimeWarning && (
          <div className="mt-4 text-center">
            {isRecording && MAX_RECORDING_DURATION - timestamp <= 120 ? (
              <span style={{ color: 'red' }}>
                <strong>ATTENTION !</strong>
                {MAX_RECORDING_DURATION - timestamp <= 0 ? (
                  <>
                    {' '}
                    Il est temps de terminer votre enregistrement. <br />
                    Sinon, votre fichier risque de ne pas pouvoir être envoyé.
                  </>
                ) : (
                  ` Il vous reste ${MAX_RECORDING_DURATION - timestamp} secondes d'enregistrement.`
                )}
              </span>
            ) : (
              "Durée maximale d'enregistrement : 20 min"
            )}
          </div>
        )}
      </div>
    </>
  );
});

export default MediaRecorder;
