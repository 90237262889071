export const hexagonColors = {
  0: '#9bc098',
  1: '#a9cf7d',
  2: '#fcc953',
  3: '#ffae51',
  4: '#ff7654',
  5: '#F4F4F4',
  buzzer: '#55a520'
};

export const hexagonColorsGrayscal = {
  0: '#9fb29e',
  1: '#62764B',
  2: '#8D7335',
  3: '#8F6434',
  4: '#Cf4736'
};

export const squareColors = {
  blue: '#97B5BF',
  purple: '#B997B2',
  yellow: '#FFAC54',
  green: '#9BBF99',
  red: '#FF7657'
};

export const p2_labels = ['Très insuffisant', 'Insuffisant', 'Satisfaisant', 'Très satisfaisant'];
