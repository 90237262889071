import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { CHECK_GROUP_TOKEN, JOIN_GROUP } from '../services/api_routes';
import Spinner from '../components/Spinner';
import Button from '@material-ui/core/Button'
import useApiPrepare from '../hooks/api/useApiPrepare';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';

/**
 * Page for URL /group/:token.
 * This page shows a prompt to join a user group. (password check)
 * @param {*} props -> history
 */
const JoinGroup = props => {
  const [,, joinGroup] = useApiPrepare(JOIN_GROUP, [], 'POST');
  const [,, checkToken] = useApiPrepare(CHECK_GROUP_TOKEN + "?token=" + props.match.params.token);

  const [group, setGroup] = useState();
  useEffect(() => {

    const aCheckToken = async () => {
      const res = await checkToken();

      // On page load, check the token. If the res is 200, token is valid show the group
      if (res.status === 200 && res.data) {
        setGroup({ ...res.data });
      } else if (res.status === 201) { // if res is 201, user already joined the group. 
        props.history.push('/list');
        props.enqueueSnackbar("Vous faites déjà partie de ce groupe.", { variant: "success" });
      }
    }

    aCheckToken();
  }, [checkToken, props])

  const { register, handleSubmit } = useForm();

  const onSubmit = async data => {
    const res = await joinGroup({ password: data.password, id: group._id });
    if (res.status === 200) {
      props.history.push('/list');
      props.enqueueSnackbar("Vous avez bien rejoint le groupe.", { variant: "success" })
    }
    
  }

  // user not authed, redirect to login
  if (!props.auth.isAuthenticated) {
    props.history.push('/login/' + props.match.params.token)
  }

  return !props.auth.isAuthenticated ||  !group ? <Spinner /> : (
    <div style={{padding: '2em', textAlign: 'center', margin: '0 auto'}}>
      <h1>Rejoindre le groupe { group.name }</h1>
      <p>
        { group.description }
      </p>

      <strong>Ce groupe est protégé par un mot de passe. </strong> <br/>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
        <TextField style={{ width: '100%', marginBottom: 15 }} type="password" inputRef={register} name="password" label="Mot de passe" />
        </div>
        <div>
        <Button fullWidth type="submit" variant="contained" color="primary">
          Rejoindre
        </Button>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(withRouter(withSnackbar(JoinGroup)));