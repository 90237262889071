import * as React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { hexagonColors } from '../../styles/palette';

function getCenterText(value, maxValue, fontSize) {
  // const isValueBig = value >= 10;
  const isMaxValueBig = maxValue >= 10;

  const maxValueStyle = { fontSize: 8 };

  const fontSizeOffset = (fontSize - 20) / 5 * 2;
  const xPos = (isMaxValueBig ? 45 : 49) + String(value).length * 6;
  const offset = 7;

  return (
    <>
      <tspan x={isMaxValueBig ? 43 - fontSizeOffset : 45 + fontSizeOffset}>{value}</tspan>
      <tspan y="54" x={xPos} style={maxValueStyle}>/</tspan>
      <tspan y="54" x={xPos + offset} style={maxValueStyle}>{maxValue}</tspan>
    </>
  )
}

function CircularProgress({ value, text = '', color, textSize = '20px', maxValue = 10, counterClockwise = false, strokeWidth = 8, withMaxIndicator = false }) {
  const fontSize = parseInt(textSize.split("px")[0])
  const centerText = withMaxIndicator ? (
    getCenterText(value, maxValue, fontSize)
  ) : text;

  return (
    <CircularProgressbar
      value={value}
      maxValue={maxValue}
      text={text !== '' || withMaxIndicator ? centerText : String(value)}
      counterClockwise={counterClockwise}
      styles={buildStyles({
        pathColor: color || (value > maxValue * 0.75 ? hexagonColors[0] : value > maxValue * 0.5 ? hexagonColors[1] : value > maxValue * 0.25 ? hexagonColors[3] : hexagonColors[4]),
        textColor: color || (value > maxValue * 0.75 ? hexagonColors[0] : value > maxValue * 0.5 ? hexagonColors[1] : value > maxValue * 0.25 ? hexagonColors[3] : hexagonColors[4]),
        trailColor: '#E0E0E0',
        textSize,
        strokeLinecap: 'butt'
      })}
      strokeWidth={strokeWidth}
    />
  );
}

export default CircularProgress;