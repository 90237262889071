import { useState } from 'react';
import useDispatchError from '../useDispatchError';
import axios from 'axios';

const useApiPrepare = (url, initialData = [], method = 'get', noReload = false) => {
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(true);
  const dispatchError = useDispatchError();

  const fetchData = async (reqData, with_loading = true) => {
    if (with_loading) setIsLoading(true);
    try {
      const result = await axios({
        url,
        method,
        data: reqData
      });

      setData(result.data);
      // if (with_loading) {
      setIsLoading(false);
      // }
      return result;
    } catch (err) {
      // console.log('ERREUR', err.response.data.);
      dispatchError(err.response);
      // console.error('Erreur useApiPrepare : ', err);
      return err;
      // return { data: initialData, isLoading, makeCall };
    }
  };

  const makeCall = async (d, with_loading = true) => {
    const res = await fetchData(d, with_loading);
    return res;
  };

  const updateData = newData => setData(newData);

  return [data, isLoading, makeCall, updateData];
};

export default useApiPrepare;
