/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';

const INITIAL_CONTEXT = {
  shouldHide: false,
  title: '',
  subtitle: '',
  onGoBackCallback: () => {
    return true;
  } // a callback that should return true if the operation should continue
};

export const NavbarContext = React.createContext(INITIAL_CONTEXT);

function NavbarProvider({ children, history, auth }) {
  const [state, setState] = React.useState(INITIAL_CONTEXT);
  const location = useLocation();

  const popover = (title, subtitle, callback) => {
    setState({ title, subtitle, shouldHide: true, onGoBackCallback: callback });
  };

  const popout = () => {
    setState({ ...INITIAL_CONTEXT, onGoBack });
  };

  const onGoBack = async () => {
    if (!state.onGoBackCallback || (await state.onGoBackCallback())) {
      if (
        history.action === 'POP' ||
        (!location.pathname.includes('/results') && !location.pathname.includes('/replay'))
      ) {
        history.replace('/list/' + auth.currentGroup.token);
        window.location.reload();
      } else {
        if (location.state && location.state.from === 'evaluation_room') {
          history.go(-2);
        } else {
          history.goBack();
        }
      }
      popout();
    }
  };

  React.useEffect(() => popout(), [location]);

  return <NavbarContext.Provider value={{ ...state, popover, popout, onGoBack }}>{children}</NavbarContext.Provider>;
}

// withRouter
const mapStateToProps = state => ({ auth: state.auth });

export default connect(mapStateToProps)(withRouter(NavbarProvider));
