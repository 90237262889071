import { GET_DEBATE_LIST } from './actions';

const INITIAL_STATE = {
  debateList: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DEBATE_LIST:
      return Object.assign({}, state, { debateList: action.payload });
    default:
      return state;
  }
}
