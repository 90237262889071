// import 'babel-polyfill';
import 'core-js/stable';
import 'es6-promise';
import 'isomorphic-fetch';
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import * as serviceWorker from './serviceWorker';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import {
  setCurrentUser,
  logoutUser,
  setCurrentGroup,
  setCurrentHexagonGroup,
  setExercisesCount
} from './services/auth/actions';
import './index.css';
import App from './App';

import jquery from 'jquery';
// import './utils/init_rgpd';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './styles/hexagon.css';
import './styles/ux.scss';
// import './styles/one-page-wonder.scss';
// import './styles/phantom.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'simplebar/dist/simplebar.min.css';
import { GET_HEXAGON_GROUP, GET_HOME_DATA, UPDATE_HEXAGON_GROUP } from './services/api_routes';
import Axios from 'axios';

window.jQuery = jquery;

// Setup a beacon that will send a request to
// update the hexagon group on page unload
document.addEventListener('visibilitychange', () => {
  const group = store.getState().auth.user.currentHexagonGroup;
  const token = localStorage.jwtToken;

  let data = new FormData();
  data.append('group', group);
  data.append('jwt', token);

  navigator.sendBeacon(UPDATE_HEXAGON_GROUP, data);
});

if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);

  // Decode token and get user info and exp
  const decoded = jwt_decode(token);

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser({ ...decoded, token }));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = '/';
  }

  setTimeout(() => {
    if (store.getState().auth.user.isPCL) {
      store.dispatch(setCurrentHexagonGroup(6))
    } else {
      Axios.get(GET_HEXAGON_GROUP).then(res => setCurrentHexagonGroup(res.data.group));
    }
  }, 1000)

  // if (currentHexagonGroup) {
  //   try {
  //     store.dispatch(setCurrentHexagonGroup(JSON.parse(currentHexagonGroup)));
  //   } catch (err) {}
  // }
}

const currentGroup = localStorage.getItem('currentGroup');
if (currentGroup) {
  try {
    store.dispatch(setCurrentGroup(JSON.parse(currentGroup), store.dispatch));

    Axios.get(GET_HOME_DATA + '?group_token=' + JSON.parse(currentGroup.token)).then(res => {
      store.dispatch(setExercisesCount(res.data.has_exercises ? 1 : 0));
    });
  } catch (err) {}
}

// Axios.post(UPDATE_HEXAGON_GROUP, { group });

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
