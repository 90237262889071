import React from 'react';
import GroupSelect from '../GroupSelect';
import IconButton from '../../../../components/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ArgumentCreationModal from '../ArgumentCreationModal';
import { richTextToHTML } from '../../../../utils';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

/**
 * Contains the argument edition sidebar for the grid creation page.
 */
const GridCreationSidebar = ({
  editedGrid,
  setEditedGrid,
  argumentsList,
  setArgumentsList,
  flashing,
  setFlashing,
  selectedArgument,
  setSelectedArgument
}) => {
  /**
   * ==== STATE =====
   */
  const [showArgumentCreation, setShowArgumentCreation] = React.useState(false);
  const [showDescription, setShowDescription] = React.useState(false);
  // const [isSelectionDifferent, setIsSelectionDifferent] = React.useState(false);
  const autocompleteRef = React.useRef(null);
  const hasArgumentChanged = React.useRef(false);

  /**
   * ==== EVENT HANDLERS =====
   */
  document.onkeydown = e => {
    if (e.code === 'Delete' && selectedArgument) {
      deleteArgument();
    } else {
      if (
        autocompleteRef.current &&
        document.activeElement !== autocompleteRef.current &&
        document.activeElement === document.body
      ) {
        autocompleteRef.current.focus();
        autocompleteRef.current.select();
      }
    }
  };

  const toggleArgumentCreation = () => setShowArgumentCreation(s => !s);
  const onValidateNewArg = arg => {
    editArgument({ ...selectedArgument, ...arg });
    setArgumentsList([...argumentsList, arg].sort((a, b) => a.name.localeCompare(b.name)));
    setFlashing([]);
  };

  const onUpdateCurrentArg = (_, name) => {
    if (hasArgumentChanged.current) return;
    hasArgumentChanged.current = true;

    const new_arg = argumentsList.find(arg => arg.name === name);
    updateArgument({ ...selectedArgument, ...new_arg, pointsGroup: selectedArgument.pointsGroup });
  };

  // Necessary for [PVCV-5833]. The "onChange" event is not triggered
  // when re-selecting the same value for a null argument. Only onClose is triggered
  // but doesn't access the value, so has to be accessed in combination with onChange.
  // The hasArgumentChanged flag is used to not update the arguments twice when they are changed
  // and both callbacks are triggered.
  const onArgumentSelectionClose = (_, reason) => {
    if (reason === 'select-option') {
      onUpdateCurrentArg(null, autocompleteRef.current.value);
      hasArgumentChanged.current = false;
    }
  };

  // == Arguments modifications ==
  const argumentIndexes = arg => {
    const cellIdx = arg.cellIdx;
    const cellsPerRow = editedGrid.precision + 1;
    return { grpIdx: Math.floor(cellIdx / cellsPerRow), argIdx: cellIdx % cellsPerRow, cellIdx };
  };

  const editArgument = arg => {
    const argument = arg ? arg : selectedArgument;
    const { grpIdx, argIdx, cellIdx } = argumentIndexes(argument);

    if (cellIdx === -1) {
      editedGrid.buzzerArgument = argument;
      editedGrid.groupForBuzzer = argument.pointsGroup;
    } else {
      editedGrid.argumentGroups[grpIdx].arguments[argIdx] = argument;
      editedGrid.argumentGroups[grpIdx].groupsForArguments[argIdx] = argument.pointsGroup;
    }

    setEditedGrid({ ...editedGrid, renderId: editedGrid.renderId + 1 });
  };

  const deleteArgument = () => {
    const { grpIdx, argIdx, cellIdx } = argumentIndexes(selectedArgument);
    const emptyArgument = { _id: grpIdx + '-' + argIdx, name: ' ', empty: true };

    if (cellIdx === -1) {
      editedGrid.buzzerArgument = emptyArgument;
      editedGrid.groupForBuzzer = 5;
    } else {
      editedGrid.argumentGroups[grpIdx].arguments[argIdx] = emptyArgument;
      editedGrid.argumentGroups[grpIdx].groupsForArguments[argIdx] = 5;
    }

    setFlashing([]);
    setEditedGrid({ ...editedGrid });
  };

  const updateArgument = arg => {
    setSelectedArgument({ ...arg });
    editArgument(arg);
  };

  /**
   * ======= RENDER ========
   */
  return (
    <div className="gcr-right">
      <ArgumentCreationModal
        open={showArgumentCreation}
        onClose={toggleArgumentCreation}
        withGroup
        onValidate={onValidateNewArg}
      />

      {/* <> Selected argument details with definition, group and delete button */}
      <div className="gcr-right-detail">
        {flashing.length > 0 && (
          <div style={{ padding: 30 }}>
            <div>
              <h3 className="text-center">Détail du critère</h3>
            </div>
            <div>
              <div className="mt-3">
                <div>
                  <label>Nom</label>
                </div>
                <div>
                  <Autocomplete
                    disableClearable
                    disableUnderline
                    InputProps={{ disableUnderline: true }}
                    autoComplete
                    value={selectedArgument.name}
                    options={argumentsList.map(v => v.name)}
                    getOptionLabel={arg => arg}
                    onChange={onUpdateCurrentArg}
                    onClose={onArgumentSelectionClose}
                    renderInput={params => (
                      <TextField
                        {...params}
                        inputRef={autocompleteRef}
                        InputProps={{ ...params.InputProps, disableUnderline: true }}
                        style={{ fontWeight: 'bold' }}
                      />
                    )}
                  />
                </div>
              </div>
              {selectedArgument && (
                <>
                  <div className="mt-3">
                    <div>
                      <button
                        className="btn btn-text btn-icon d-flex small btn-no-effect"
                        style={{ fontWeight: '500', color: '#A2D146', fontSize: '1rem' }}
                        onClick={() => setShowDescription(s => !s)}
                      >
                        Description{' '}
                        {!selectedArgument.description || selectedArgument.description.length < 80 ? (
                          ''
                        ) : showDescription ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </button>
                    </div>
                    <div className="gcr-description-parent">
                      <div className="gcr-description" style={{ WebkitBoxOrient: showDescription ? '' : 'vertical' }}>
                        {richTextToHTML(selectedArgument.description)}
                      </div>
                    </div>
                  </div>
                  <GroupSelect
                    noDetail
                    onChange={grp => () => updateArgument({ ...selectedArgument, pointsGroup: grp })}
                    value={selectedArgument.pointsGroup}
                  />
                  <div style={{ flexGrow: 1 }} />
                </>
              )}
              <div className="btn-group d-flex ai-c jc-c" style={{ marginTop: 30, marginBottom: 30 }}>
                <span>
                  <IconButton
                    disabled={selectedArgument.pointsGroup === 5}
                    onClick={deleteArgument}
                    label="Supprimer ce critère"
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </span>
              </div>
            </div>
            {/* </> Selected argument details with definition, group and delete button */}

            {/* <> Argument creation section */}
            <div className="text-center mt-3">
              <div className="or-option">
                <span />
                <div>OU</div>
                <span />
              </div>

              <div style={{ fontSize: 14 }}>Aucun critère ne correspond à vos attentes ? Créez le vôtre.</div>
              <div className="mt-4">
                <button className="btn btn-primary" onClick={toggleArgumentCreation}>
                  Nouveau critère
                </button>
              </div>
            </div>
            {/* </> Argument creation section */}
          </div>
        )}
      </div>
    </div>
  );
};

export default GridCreationSidebar;
