import React from 'react';
import MDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function Dialog({ open, title, content, actions, onClose, noClose = false, ...props }) {
  return (
    <MDialog open={open} onClose={onClose} {...props}>
      {title && (
        <DialogTitle>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flexGrow: 1 }}>{title}</div>
            {!noClose && (
              <IconButton onClick={onClose} size="small">
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </DialogTitle>
      )}
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </MDialog>
  );
}

export default Dialog;
