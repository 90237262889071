import React from 'react';
import { Controller } from 'react-hook-form';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F4F4F4',
    borderRadius: 6,
    padding: 10
  }
});

const MuiSelect = ({ label, control, name, defaultValue = '', ...props }) => {
  const classes = useStyles();

  return control ? (
    <Controller
      as={
        <Select {...props} classes={{ root: classes.root }} disableUnderline>
          {props.children}
        </Select>
      }
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  ) : (
    <Select {...props} classes={{ root: classes.root }} name={name} disableUnderline>
      {props.children}
    </Select>
  );
};

export default MuiSelect;
