import React from 'react';
import './Home.scss';
import useApi from '../../hooks/api/useApi';
import { GET_HOME_DATA, GET_VIDEOS_PER_SCREEN } from '../../services/api_routes';
import { useDispatch, useSelector } from 'react-redux';
import { setExercisesCount } from '../../services/auth/actions';
import HomeHeader from './components/HomeHeader';
import Onboarding from './components/Onboarding';
import HomeActions from './components/HomeActions';
import HomePendingActivities from './components/HomePendingActivities';
import TutorialFloater from '../../components/TutorialFloater/TutorialFloater';
import withTutorial from '../../hooks/withTutorial';
import { tutorialScreens } from '../../utils/texts';

const Home = ({ startGuidedTour }) => {
  const currentGroup = useSelector(state => state.auth.currentGroup);
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const [videos] = useApi(GET_VIDEOS_PER_SCREEN);

  const [homeData, isLoading] = useApi(GET_HOME_DATA + '?group_token=' + currentGroup.token, {}, 'GET');
  const [isRedirecting, setIsRedirecting] = React.useState(false);

  React.useEffect(() => {
    if (!isLoading) {
      dispatch(setExercisesCount(homeData.has_exercises ? 1 : 0));
      setTimeout(() => startGuidedTour(), 300);
    }
  }, [isLoading]);

  return isLoading ? (
    ''
  ) : (user.teacher && !user.hasDoneOnboarding) || isRedirecting ? (
    <Onboarding {...homeData} isDemo={currentGroup.isDemo} setIsRedirecting={setIsRedirecting} />
  ) : (
    <div>
      <HomeHeader title={'Bonjour ' + user.name} />
      <div className="body-wrapper">
        <HomeActions isDemo={currentGroup.isDemo} isLoading={isLoading} {...homeData} />

        <div className="bottom-wrapper d-flex">
          <HomePendingActivities group={currentGroup} />
          {!videos['Accueil'] || videos['Accueil'].length === 0 ? (
            <div style={{ flex: 1 }} />
          ) : (
            <TutorialFloater style={{ flex: 1, marginTop: '7%' }} videos={videos['Accueil']} vertical />
          )}
        </div>
      </div>
    </div>
  );
};

export default withTutorial(Home, tutorialScreens.TEACHER_HOME);
