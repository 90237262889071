import React from 'react';

export const precisionLabels = ['Découverte', 'Pratique', 'Classique', 'Confirmé', 'Expert', 'Pro'];

export const rankLabels = ['Novice', 'Analyste I', 'Analyste II', 'Analyste III', 'Emérite I', 'Emérite II', 'Rhéteur'];

export const hexagonGroups = ['Collège', 'Lycée', 'Supérieur', 'Argumentation', 'Professionnel', 'Autres'];
export const hexagonGroupPCL = "Primaire"

export const defaultGlobalAppreciationLabels = [
  'Sujet traité',
  'Argumentaire construit',
  'Exemples pertinents',
  'Vocabulaire riche & adapté',
  'Expression',
  'Voix',
  'Débit',
  'Posture',
  'Énergie',
  'Capacité de conviction',
  'Gestion du temps',
  "Qualité de l'échange"
];

export const onboardingSteps = [
  {
    title: <>Bienvenue&nbsp;!</>,
    subtitle: (
      <>
        Découvrez votre tableau de bord Mon oral <br />
        et les modèles de grilles d'évaluation à votre disposition.
      </>
    )
  },
  {
    title: <>Laissez-vous guider&nbsp;!</>,
    subtitle: (
      <>
        Sur chaque écran, vous trouverez des tutoriels vidéo pour <br />
        vous accompagner dans la prise en main de l'outil. Ces tutoriels sont <br />
        tous disponibles dans la rubrique «&nbsp;Besoin d'aide&nbsp;».
      </>
    )
  },
  {
    title: <>Lancez-vous&nbsp;!</>,
    subtitle: <>Créez votre première activité ou découvrez votre tableau de bord.</>
  }
];

export const tutorialScreens = {
  EXERCISES_CREATION_SELECTION: "Création d'exercice (choix du type)",
  EXERCISES_CREATION_TO_DO: "Création d'exercice de production",
  EXERCISES_CREATION_TO_EVALUATE: "Création d'exercice à évaluer",
  EXERCISES_CREATION_PRESENTIAL: "Création d'exercice en présentiel",
  EXERCISES_CREATION_COPY: "Création d'exercice en partant d'un existant",
  EXERCISES_LIST: 'Liste des exercices',
  SUBMITTED_LIST_TO_DO: 'Liste des activités déposées (ex de production)',
  SUBMITTED_LIST_TO_DO_PCL_MODEL: 'Liste des activités déposées (ex de production) MODEL',
  SUBMITTED_LIST_TO_DO_PCL_TEST: 'Liste des activités déposées (ex de production) TEST',
  SUBMITTED_LIST_TO_EVAL: "Liste des activités déposées (ex d'évaluation)",
  SUBMITTED_LIST_PRESENTIAL: 'Liste des activités déposées (ex en présentiel)',
  EVALUATION: "Interface d'évaluation",
  GRID_LIST: 'Liste des grilles',
  GRID_CREATION: 'Création de grille',
  RESULTS_TO_DO: 'Résultats (ex de production et présentiel)',
  RESULTS_TO_EVAL: "Résultats (ex d'évaluation)",
  REPLAY: 'Replay',
  GROUP_CHOICE: 'Choix du groupe',
  ARGUMENTS_LIST: 'Liste des critères',
  ONBOARDING: 'Onboarding',
  TEACHER_HOME: 'Tableau de bord enseignant',
};
