import React from 'react';
import Dialog from '../../../../components/dialog/Dialog';
import MicIcon from '@material-ui/icons/Mic';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import { makeStyles } from '@material-ui/core';
import { CREATE_IN_PERSON_SESSION } from '../../../../services/api_routes';
import useApiPrepare from '../../../../hooks/api/useApiPrepare';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(_ => ({
  backdrop: { background: '#9A9A9A' }
}));

const InPersonRecordingModal = ({ open, onClose, token, studentExtId }) => {
  const [, , createInPersonSession] = useApiPrepare(CREATE_IN_PERSON_SESSION, {}, 'POST');
  const history = useHistory();

  const dialogProps = { open, onClose };
  const classes = useStyles();

  const sourceCards = [
    { key: 'video', icon: <PhotoCameraIcon fontSize="inherit" />, title: 'Votre webcam' },
    { key: 'mobile', icon: <PhoneIphoneIcon fontSize="inherit" />, title: 'Un appareil externe' },
    { key: 'audio', icon: <MicIcon fontSize="inherit" />, title: 'Votre micro uniquement', subtitle: '(Pas de vidéo)' },
    { key: 'none', icon: <VideocamOffIcon fontSize="inherit" />, title: 'Aucun enregistrement' }
  ];

  const onSubmitSource = recordingMode => async () => {
    const res = await createInPersonSession({ token, student_extid: studentExtId, recording_mode: recordingMode });
    if (res.status === 200) {
      history.push('/join/' + res.data.token);
    }
  };

  const disabledStyle = { opacity: 0.5, pointerEvents: 'none' };
  return (
    <Dialog
      {...dialogProps}
      maxWidth={'md'}
      title=" "
      BackdropProps={{ classes: { root: classes.backdrop } }}
      content={
        <div className="text-center mt-2 in-person-wrapper">
          <h2>Choix source</h2>
          <div className="subtitle">Quelle source va enregistrer la prestation ?</div>

          <div className="in-person-icons">
            {sourceCards.map(({ icon, title, subtitle, key }) => (
              <div
                className="deposit-card"
                onClick={onSubmitSource(key)}
                key={key}
                style={key === 'mobile' ? disabledStyle : {}}
              >
                <div className="deposit-card-icon">{icon}</div>
                <div className="deposit-card-title">{title}</div>
                <div className="subtitle">{subtitle || <>&nbsp;&nbsp;</>}</div>
              </div>
            ))}
          </div>
        </div>
      }
    />
  );
};

export default InPersonRecordingModal;
