import { MenuItem } from '@material-ui/core';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import TextInput from '../../../../components/TextInput';
import { CREATE_HEXAGON, EDIT_HEXAGON } from '../../../../services/api_routes';
import '../../GridsList.scss';
import { hexagonGroupPCL, hexagonGroups, tutorialScreens } from '../../../../utils/texts';
import useHexagonGroup from '../../../../hooks/useHexagonGroup';
import MuiSelect from '../../../../components/forms/MuiSelect';
import GridCreationSidebar from './GridCreationSidebar';
import GridCreationP1 from './GridCreationP1';
import GridCreationP2 from './GridCreationP2';
import NavigationBar from '../../../../components/NavigationBar';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '../../../../components/IconButton';
import withTutorial from '../../../../hooks/withTutorial';
import { useSelector } from 'react-redux';

const GridCreationInterface = ({ grid, argumentsList, setArgumentsList, isEditing, onGoBack, startGuidedTour }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isPCL } = useSelector(state => state.auth.user);

  /*
   * ======= STATE =======
   */

  // GRID STATE
  const [gridName, setGridName] = React.useState('');
  const [hexagonGroup, setHexagonGroup] = useHexagonGroup();
  const [editedAppreciations, setEditedAppreciations] = React.useState(grid.globalAppreciationLabels);
  const [editedGrid, setEditedGrid] = React.useState({
    ...grid,
    argumentGroups: grid.argumentGroups.map(x => ({
      // Important: perform deep copy of inner arrays and objects to prevent reference edition
      ...x,
      groupsForArguments: [...x.groupsForArguments],
      arguments: [...x.arguments]
    })),
    renderId: 0
  });

  // ARG STATUS STATE
  const [selectedArgument, setSelectedArgument] = React.useState(null);
  const [flashing, setFlashing] = React.useState([]);

  // STATUS STATE
  const [currentPhase, setCurrentPhase] = React.useState(1);

  // HOOKS
  React.useEffect(() => {
    if (isEditing) {
      setGridName(grid.name);
    }
  }, [grid.name, isEditing]);

  /**
   * ======= EVENT HANDLERS =======
   */
  const changePhase = () => {
    setSelectedArgument(null);
    setFlashing([]);
    setCurrentPhase(c => (c === 1 ? 2 : 1));
  };

  // Unselected argument when clicking on white background
  const onBackgroundClick = e => {
    const tagName = e.target.tagName;
    if (tagName === 'DIV' || tagName === 'svg') {
      setSelectedArgument(null);
      setFlashing([]);
    }
  };

  const onSubmit = async () => {
    // Make all empty arguments null
    const newGrid = {
      ...editedGrid,
      argumentGroups: editedGrid.argumentGroups.map(x => ({
        // Important: perform deep copy of inner arrays and objects to prevent reference edition
        ...x,
        groupsForArguments: [...x.groupsForArguments],
        arguments: [...x.arguments]
      })),
      globalAppreciationLabels: [...editedAppreciations]
    };

    for (let [argGrpIdx, argGrp] of newGrid.argumentGroups.entries()) {
      for (let [argIdx, arg] of argGrp.arguments.entries()) {
        if (arg && arg.empty) {
          newGrid.argumentGroups[argGrpIdx].arguments[argIdx] = null;
        }
      }
    }
    if (newGrid.buzzerArgument && newGrid.buzzerArgument.empty) newGrid.buzzerArgument = null;

    // Submit data
    const data = {
      name: gridName,
      group: hexagonGroup,
      evaluationControl: newGrid,
      id: grid._id
    };

    const res = await Axios.post(isEditing ? EDIT_HEXAGON : CREATE_HEXAGON, data, { validateStatus: s => s < 401 });
    if (res.status === 200) {
      history.replace('/grilles');
      enqueueSnackbar('La grille a bien été ' + (!isEditing ? 'créée' : 'modifiée'), { variant: 'success' });
    } else {
      enqueueSnackbar(res.data.error, { variant: 'error' });
    }
  };

  const sharedState = {
    grid,
    editedGrid: editedGrid,
    setEditedGrid,
    argumentsList,
    flashing,
    setFlashing,
    selectedArgument,
    setSelectedArgument
  };

  /**
   * ======= RENDER =======
   */
  const Navigation = () => (
    <div className="d-flex" style={{ gap: 5, minWidth: '40%' }}>
      <div style={{ flex: 2 }} className="ml-2">
        <div>
          <MuiSelect className="fullwidth" value={hexagonGroup} onChange={e => setHexagonGroup(e.target.value)}>
            {!isPCL ? (
              hexagonGroups.map((grp, idx) => (
                <MenuItem key={'grp-' + grp} value={idx}>
                  <div style={{ fontWeight: 900 }}>{grp}</div>
                </MenuItem>
              ))
            ) : (
              <MenuItem key={'grp-' + hexagonGroupPCL} value={6}>
                <div style={{ fontWeight: 900 }}>{hexagonGroupPCL}</div>
              </MenuItem>
            )}
          </MuiSelect>
        </div>
      </div>
      <div className="fullwidth ml-2" style={{ flex: 8 }}>
        <TextInput
          className="fullwidth strong"
          placeholder="Nom de la grille"
          fullHeight
          value={gridName}
          onChange={e => setGridName(e.target.value)}
        />
      </div>
    </div>
  );

  const Header = () => (
    <div className="hex-phase-bubbles">
      {[1, 2].map(n => (
        <div
          onClick={() => setCurrentPhase(n)}
          className={(currentPhase === n ? 'hex-bubble-current' : '') + ' clickable'}
        >
          {n}
        </div>
      ))}
    </div>
  );

  const Footer = () => (
    <div className="gcr-footer">
      <div className="gcr-footer-content mb-2 text-center">
        <IconButton
          label={`étape ${currentPhase === 1 ? 'suivante' : 'précédente'}`}
          iconRight={currentPhase === 1}
          onClick={changePhase}
        >
          {currentPhase === 1 ? (
            <ArrowForwardIosIcon fontSize="inherit" />
          ) : (
            <ArrowBackIosIcon fontSize="inherit" style={{ paddingLeft: 5 }} />
          )}
        </IconButton>
        <button className="btn btn-primary mt-3 fullwidth" onClick={onSubmit}>
          Enregistrer
        </button>
      </div>
    </div>
  );

  const paddingStyle = currentPhase === 1 ? {} : { paddingLeft: 0, paddingRight: 0, flex: 12 };

  return (
    <div className="body-wrapper">
      <NavigationBar onBack={onGoBack} override title={Navigation()} />
      <div className="gcr-wrapper">
        <div className="gcr-left" style={paddingStyle} onClick={onBackgroundClick}>
          <Header />
          {currentPhase === 1 ? (
            <GridCreationP1 {...sharedState} />
          ) : (
            <GridCreationP2
              editedAppreciations={editedAppreciations}
              setEditedAppreciations={setEditedAppreciations}
              initialAppreciations={grid.globalAppreciationLabels}
            />
          )}
          <Footer />
        </div>

        <GridCreationSidebar {...sharedState} setArgumentsList={setArgumentsList} />
      </div>
    </div>
  );
};

export default withTutorial(GridCreationInterface, tutorialScreens.GRID_CREATION, true);
