/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { GET_ARGUMENTS, CREATE_ARGUMENT } from '../../../services/api_routes';
import useApi from '../../../hooks/api/useApi';
import useForm from '../../../hooks/useForm';
// import List from 'react-list-select';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { List, ListItem, ListItemText, Radio, ListItemIcon } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import useApiPrepare from '../../../hooks/api/useApiPrepare';

const ArgumentModal = props => {
  const [argumentList, , reloadArguments] = useApi(GET_ARGUMENTS);
  const [createArgumentRes, isCreatingArgument, createArgument] = useApiPrepare(CREATE_ARGUMENT, {}, 'post');
  const [inputs, handleInputChange] = useForm({ search: '' });
  const [selectedArgument, setSelectedArgument] = useState(0);

  useEffect(() => {
    if (!isCreatingArgument) {
      props.onSubmit(createArgumentRes);
      reloadArguments();
    }
  }, [isCreatingArgument]);

  const filteredArguments = argumentList.filter(
    arg => inputs.search === '' || arg.name.toLowerCase().includes(inputs.search.toLowerCase())
  );

  const submitArgumentSelection = () => {
    const { onSubmit } = props;

    onSubmit(filteredArguments[selectedArgument]);
  };

  const submitArgumentCreation = e => {
    e.preventDefault();

    const { argName: name, argDesc: description } = inputs;

    createArgument({
      argument: {
        name,
        description,
        rating: 1
      }
    });
  };

  const ArgumentList = () => {
    return argumentList.length === 0 ? (
      <em>Chargement des arguments...</em>
    ) : (
      <List>
        {filteredArguments.map((arg, idx) => (
          <ListItem button onClick={() => setSelectedArgument(idx)}>
            <ListItemIcon>
              <Radio edge="start" checked={selectedArgument === idx} />
            </ListItemIcon>

            <ListItemText primary={arg.name} secondary={arg.description} />
          </ListItem>
        ))}
      </List>
    );
  };
  const { argName, argDesc } = inputs;

  return (
    <Dialog {...props} aria-labelledby="contained-modal-title-vcenter" maxWidth="md" fullWidth>
      <DialogTitle id="contained-modal-title-vcenter">
        Sélection de l'argument
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: '1em',
            top: '1em',
            color: grey[500]
          }}
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="d-flex">
          <div style={{ flex: 1, overflow: 'auto' }}>
            <h2 style={{ marginBottom: '1em', textAlign: 'center' }}>Choisir un critère</h2>
            <form>
              <TextField
                name="search"
                placeholder="Rechercher..."
                style={{ width: '100%' }}
                value={inputs.search}
                onChange={handleInputChange}
              />
            </form>
            <div style={{ height: '50vh', overflow: 'auto' }}>
              <ArgumentList />
            </div>
            <Button variant="contained" color="primary" fullWidth onClick={submitArgumentSelection}>
              Valider
            </Button>
          </div>
          <div style={{ width: '2em' }} />
          <div style={{ flex: 1 }}>
            <h2 style={{ marginBottom: '1em', textAlign: 'center' }}>Créer un critère</h2>
            <form>
              <div style={{ marginBottom: '1em' }}>
                <TextField
                  fullWidth
                  variant="filled"
                  onChange={handleInputChange}
                  name="argName"
                  placeholder="Mensonge"
                  value={argName}
                  label="Nom"
                />
              </div>
              <div style={{ marginBottom: '1em' }}>
                <TextField
                  fullWidth
                  rows={10}
                  variant="filled"
                  label="Description"
                  multiline
                  onChange={handleInputChange}
                  name="argDesc"
                  placeholder="Le fait d'avancer des propos fallacieux dans une phrase."
                  value={argDesc}
                />
              </div>
              <Button variant="contained" color="primary" fullWidth onClick={submitArgumentCreation}>
                Créer et Valider
              </Button>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ArgumentModal;
