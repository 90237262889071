import React from 'react';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { squareColors } from '../styles/palette';

// mode === "to_do/to_eval"
// prestationMode === "audio/video"
function SessionIcon({ mode, prestationMode, ...props }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50%',
        padding: 8,
        backgroundColor:
          mode === 'to_eval' ? squareColors.purple : mode === 'in_person' ? squareColors.yellow : squareColors.blue,
        color: 'white',
        transform: 'scaleY(-1) scaleX(1)',
        fontSize: '1em'
      }}
    >
      {mode === 'to_do' && prestationMode === 'audio' ? (
        <VolumeUpIcon fontSize="inherit" />
      ) : (
        <VideoLibraryIcon fontSize="inherit" />
      )}
    </div>
  );
}

export default SessionIcon;
