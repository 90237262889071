import React from 'react';
import { Link } from 'react-router-dom';
import TutorialCard from '../TutorialCard';
import './TutorialFloater.scss';

const TutorialFloater = ({ videos, style, vertical }) => {
  const plural = videos && videos.length > 1 ? 's' : '';

  return videos && videos.length > 0 ? (
    <div className={'tf-wrapper' + (vertical ? ' tf-vertical' : '')} style={style}>
      <div className="strong mb-2">Besoin d'aide ?</div>

      <div className="tf-content">
        <div className="tf-videos">
          {videos.map(video => (
            <TutorialCard {...video} titleOnly />
          ))}
        </div>

        <div className="text-center tf-button">
          {!vertical && (
            <div className="subtitle">
              {videos.length} vidéo{plural} disponible{plural}
            </div>
          )}
          <Link className="small btn btn-outline" to="/tutorials">
            Tout voir
          </Link>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

export default TutorialFloater;
