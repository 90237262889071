import React from 'react';
import NavigationBar from '../../components/NavigationBar';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GET_ALL_TUTORIALS } from '../../services/api_routes';
import useApi from '../../hooks/api/useApi';
import TutorialCard from '../../components/TutorialCard';
import './TutorialsList.scss';
import { useState } from 'react';
import { isSubstring } from '../../utils';
import SearchIcon from '@material-ui/icons/Search';

/**
 * A list of all the tutorials grouped by theme.
 */
const TutorialsList = props => {
  const history = useHistory();
  const isTeacher = useSelector(state => state.auth.user.teacher);
  if (!isTeacher) window.location.href = '/';

  const [tutorials] = useApi(GET_ALL_TUTORIALS);
  const [currentSearch, setCurrentSearch] = useState('');

  const onChangeCurrentSearch = e => setCurrentSearch(e.target.value);

  const applySearchQuery = ({ title, description }) =>
    isSubstring(currentSearch, title) || (description && isSubstring(currentSearch, description));

  const filteredTutorials = !currentSearch
    ? tutorials
    : tutorials.map(({ theme, videos }) => ({ theme, videos: videos.filter(applySearchQuery) }));

  const onGoBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace('/list');
    }
  };

  return !isTeacher ? (
    ''
  ) : (
    <>
      <NavigationBar title="Besoin d'aide ?" onBack={onGoBack} />
      <div className="tutos-header">
        Regardez nos vidéos explicatives pour utiliser la plateforme au mieux. Ces vidéos sont accessibles à tout moment
        dans la rubrique «&nbsp;aide&nbsp;».
      </div>
      <div className="body-wrapper tutos-wrapper">
        <div className="d-flex jc-c fullwidth">
          <div className="slist-bi">
            <span>
              <SearchIcon fontSize="inherit" />
            </span>
            <input
              type="text"
              placeholder="Rechercher..."
              value={currentSearch}
              onChange={onChangeCurrentSearch}
              className="fullwidth"
            />
          </div>
        </div>

        <div className="tutos-card-wrapper">
          {filteredTutorials.map(({ theme, videos }) =>
            videos.length > 0 ? (
              <>
                <div className="tuts-theme-title">{theme.title}</div>
                <div className="tuts-theme">
                  {videos.map(video => (
                    <TutorialCard {...video} />
                  ))}
                </div>
              </>
            ) : (
              ''
            )
          )}
        </div>
      </div>
    </>
  );
};

export default TutorialsList;
