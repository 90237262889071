/* eslint-disable no-loop-func */
import React from 'react';
import { isMobileSafari } from 'react-device-detect';

function getTextWidth(text, fontSize = 16) {
  const font = `500 ${fontSize + 2}px sans-serif`;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  return context.measureText(text).width;
}

function breakString(word, maxWidth, fontSize, hyphenCharacter = '-') {
  const characters = word.split('');
  const lines = [];
  let currentLine = '';
  characters.forEach((character, index) => {
    const nextLine = `${currentLine}${character}`;
    const lineWidth = getTextWidth(nextLine, fontSize);
    if (lineWidth >= maxWidth) {
      const currentCharacter = index + 1;
      const isLastLine = characters.length === currentCharacter;
      const hyphenatedNextLine = `${nextLine}${hyphenCharacter}`;
      lines.push(isLastLine ? nextLine : hyphenatedNextLine);
      currentLine = '';
    } else {
      currentLine = nextLine;
    }
  });
  return { hyphenatedStrings: lines, remainingWord: currentLine };
}

function wrapLabel(label, maxWidth, hexagonSize) {
  const words = label.split(' ');
  let completedLines = [];
  let fontSize = 18;

  while (
    completedLines.length === 0 ||
    completedLines.length > 2 ||
    (hexagonSize > 24 && completedLines.length > 1) ||
    completedLines.some(l => completedLines.length > 1 && l.length < 4)
  ) {
    completedLines = [];
    let nextLine = '';

    words.forEach((word, index) => {
      const wordLength = getTextWidth(`${word} `, fontSize);
      const nextLineLength = getTextWidth(nextLine, fontSize);

      if (wordLength > maxWidth) {
        const { hyphenatedStrings, remainingWord } = breakString(word, maxWidth, fontSize);
        completedLines.push(nextLine, ...hyphenatedStrings);
        nextLine = remainingWord;
      } else if (nextLineLength + wordLength >= maxWidth) {
        completedLines.push(nextLine);
        nextLine = word;
      } else {
        nextLine = [nextLine, word].filter(Boolean).join(' ');
      }
      const currentWord = index + 1;
      const isLastWord = currentWord === words.length;
      if (isLastWord) {
        completedLines.push(nextLine);
      }

      completedLines = completedLines.filter(line => line !== '');
    });

    if (
      fontSize < 11 &&
      hexagonSize < 24 &&
      !completedLines.some(
        (l, idx) => idx > 0 && completedLines[idx - 1].includes('-') && completedLines.length > 1 && l.length < 4
      )
    )
      break;
    fontSize--;
  }

  return { spans: completedLines, fontSize };
}

function WrappedText({ x, y, width, height, text, hexagonSize }) {
  let label;

  if (!text.trim()) {
    label = { fontSize: 16, spans: [''] };
  } else {
    label = wrapLabel(text, width, hexagonSize);
  }

  const pad = isMobileSafari ? 2 : 8;

  return (
    <text
      x={x}
      y={y}
      className="svg-wrap"
      textAnchor="middle"
      dominantBaseline="central"
      style={{ strokeWidth: 30, fill: 'white', fontSize: label.fontSize }}
    >
      {label.spans.map((word, index) => (
        <tspan
          key={word + index}
          style={{ fontWeight: 900 }}
          x={x + width / 2}
          y={
            index === 0
              ? y - (label.spans.length - 1) * pad + height / 2
              : y - (label.spans.length - 1) * pad + height / 2 + index * 14
          }
        >
          {word}
        </tspan>
      ))}
    </text>
  );
}

export default WrappedText;
