/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import GroupIcon from '@material-ui/icons/Group';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import './GroupsHome.scss';
import useApi from '../../hooks/api/useApi';
import { GET_OWNED_GROUPS, RESET_GUIDED_TOUR } from '../../services/api_routes';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { refreshToken, setCurrentGroup, setCurrentHexagonGroup } from '../../services/auth/actions';
import { isSubstring } from '../../utils';
import withTutorial from '../../hooks/withTutorial';
import { tutorialScreens } from '../../utils/texts';
import useApiPrepare from '../../hooks/api/useApiPrepare';
import { withSnackbar } from 'notistack';

// const groups = ["Premiere ES2", "Première STI", "Terminale ES4"];

function GroupButton({ name, ...props }) {
  return (
    <button className="gh-btn" {...props}>
      <div className="gh-lc">
        <GroupIcon className="gh-ico" />
        {name}
      </div>
      <ArrowForwardIosIcon className="subtitle" fontSize="small" />
    </button>
  );
}

/**
 * GroupsHome
 * @route /
 * @desc Allow the teacher to pick the group he wants to manage
 */
function GroupsHome({ history, setCurrentGroup, isTeacher, isPCL, startGuidedTour, enqueueSnackbar }) {
  const [groups, isLoading] = useApi(GET_OWNED_GROUPS, [], 'get');
  const [, , resetGuidedTour] = useApiPrepare(RESET_GUIDED_TOUR, {});
  const [search, setSearch] = React.useState('');
  const [filteredGroups, setFilteredGroups] = React.useState(groups);
  const dispatch = useDispatch();

  const onSearchUpdate = e => {
    const { value } = e.target;
    setSearch(value);
    setFilteredGroups(value === '' ? [...groups] : groups.filter(({ name }) => isSubstring(value, name)));
  };

  const onGroupClick = group => () => {
    setCurrentGroup(group);

    if (group && isPCL && !group.isDemo) {
      dispatch(setCurrentHexagonGroup(6));
    }

    history.push(isTeacher ? '/home' : '/list');
  };

  const onResetGuidedTour = async () => {
    const res = await resetGuidedTour();
    if (res && res.status === 200) {
      const { token } = res.data;
      refreshToken(token, dispatch);

      enqueueSnackbar('La visite guidée a bien été réinitialisée.', { variant: 'success' });
    }
  };

  React.useEffect(() => {
    if (groups.length > 0 && !isTeacher) {
      onGroupClick(groups[0])();
    } else {
      setFilteredGroups(groups);
      startGuidedTour();
    }
  }, [groups, isLoading]);

  return !isTeacher ? (
    ''
  ) : (
    <div className="gh-container">
      <div>
        <div className="gh-bigico">
          <GroupIcon fontSize="inherit" />
        </div>
        <h2 className="primary">GROUPES</h2>
        <p className="subtitle">Sélectionnez un groupe pour accéder aux activités qui le concernent : </p>
      </div>

      <div>
        <input id="siuu" type="text" placeholder="Rechercher" value={search} onChange={onSearchUpdate} />

        {filteredGroups.map(({ name, token, isDemo }, idx) => (
          <GroupButton id={`gh-grp-${idx}`} key={token} name={name} onClick={onGroupClick({ name, token, isDemo })} />
        ))}

        {isPCL && (
          <button className="btn btn-outline mt-4 small" onClick={onResetGuidedTour}>
            Revoir la visite guidée
          </button>
        )}
      </div>
    </div>
  );
}

export default connect(state => ({ isTeacher: state.auth.user.teacher, isPCL: state.auth.user.isPCL }), {
  setCurrentGroup
})(withRouter(withSnackbar(withTutorial(GroupsHome, tutorialScreens.GROUP_CHOICE))));
