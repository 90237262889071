import React from 'react';
import Hexagon from '../../../../components/Hexagon';
import { hexagonColors, p2_labels } from '../../../../styles/palette';
import { HUB_MODES } from '../../../TeacherHub/constants';
import TitleSection from '../../TitleSection';
import MatchingTable from '../MatchingTable';
import './Step2Tab.scss';

function Step2Tab({ title, mode, hexagon, selection, speakers, isTeacher, pics }) {
  const useLegacyP2 = !hexagon || !hexagon.globalAppreciationLabels || hexagon.globalAppreciationLabels.length === 0;

  return (
    <div className="st2-wrapper">
      <TitleSection title={isTeacher ? title : ''} label="Appréciation générale" />
      <div style={{ width: '100%' }} id="p2-res">
        {selection.map((selected, idx) =>
          mode !== HUB_MODES.TEACHER_FOLDER_TO_EVAL ? (
            <Hexagon
              evaluationControl={hexagon}
              phase2Selection={selection[0]}
              buzzerLabel={mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL ? speakers[idx] : ''}
              onCellClick={() => {}}
              phase={2}
            />
          ) : (
            <MatchingTable
              key={'hex' + idx}
              title={speakers[idx]}
              headers={[
                'Critères',
                isTeacher ? 'Votre avis' : 'Avis enseignant',
                isTeacher ? 'Avis élève' : 'Votre avis',
                'Concordance'
              ]}
              labels={
                useLegacyP2
                  ? hexagon.argumentGroups.reduce((acc, grp) => [...acc, grp.arguments[0].name], [])
                  : hexagon.globalAppreciationLabels
              }
              teacher={selected.teacher.map(val => ({
                label: val === 0 ? '- -' : p2_labels[val - 1],
                color: hexagonColors[4 - val + 1]
              }))}
              student={selected.student.map(val => ({
                label: val === 0 ? '- -' : p2_labels[val - 1],
                color: hexagonColors[4 - val + 1]
              }))}
              matching={selected.matching.map(val => ({
                label: val > -1 ? val * 25 + '%' : '--',
                color: hexagonColors[4 - val]
              }))}
              pic={pics[idx]}
            />
          )
        )}
      </div>
    </div>
  );
}

export default Step2Tab;
