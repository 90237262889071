/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Dialog, TextField, DialogContent, DialogTitle, Button, Grid, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { loginUser, registerUser } from '../../../services/auth/actions';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(4)
  },
  input: {
    width: '100%',
    margin: 5
  }
}));

const initialForm = {
  username: '',
  name: '',
  surname: '',
  email: '',
  password: '',
  password2: '',
  phone: ''
};

const RegisterDialog = props => {
  const classes = useStyles();
  const [loginForm, setLoginForm] = useState(initialForm);
  const theme = useTheme();
  const { onClose, open } = props;
  const dialogProps = { onClose, open };
  const { push } = props.history;
  useEffect(() => {
    if (props.auth.isAuthenticated) {
      if (onClose)
        onClose();
      push('/list');
    }
  }, [onClose, props.auth.isAuthenticated, push]);

  useEffect(() => setLoginForm({ ...loginForm, password: '', password2: '' }), []);

  const onChange = e => {
    setLoginForm({ ...loginForm, [e.target.id]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();
    // props.registerUser(loginForm, props.history, props.onSuccessfullyRegistered);
    // props.onClose();
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} fullWidth maxWidth="sm" {...dialogProps}>
      <DialogTitle style={{ paddingBottom: 4, paddingTop: 10 }}>
        S'inscrire
        <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <TextField
              size="small"
              variant="filled"
              type="text"
              id="username"
              onChange={onChange}
              value={loginForm.username}
              label="Nom d'utilisateur *"
              className={classes.input}
            />

            <TextField
              size="small"
              variant="filled"
              type="email"
              id="email"
              onChange={onChange}
              value={loginForm.email}
              label="E-mail *"
              className={classes.input}
            />
            <TextField
              size="small"
              variant="filled"
              type="password"
              id="password"
              onChange={onChange}
              value={loginForm.password}
              label="Mot de passe *"
              className={classes.input}
            />

            <TextField
              size="small"
              variant="filled"
              type="password"
              id="password2"
              onChange={onChange}
              value={loginForm.password2}
              label="Vérification du mot de passe *"
              className={classes.input}
            />
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  variant="filled"
                  type="text"
                  id="surname"
                  onChange={onChange}
                  value={loginForm.surname}
                  label="Prénom"
                  style={{ width: '100%', marginTop: 5 }}
                  // className={classes.input}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  variant="filled"
                  type="text"
                  id="name"
                  onChange={onChange}
                  value={loginForm.name}
                  label="Nom"
                  style={{ width: '100%', marginTop: 5 }}
                  // className={classes.input}
                />
              </Grid>
            </Grid>
            <small style={{ marginTop: '2em', marginBottom: 5 }}>
              Les champs marqués d'une étoile (*) sont obligatoires.
            </small>
            <Button fullWidth type="submit" variant="contained" color="primary" onClick={onSubmit}>
              S'inscrire
            </Button>
            {/* <div
              style={{
                textAlign: 'center',
                marginTop: '0.5em'
              }}
            >
              ou
            </div>
            <SocialLogin
              service="google"
              className={classes.input}
              style={{ marginTop: '0em' }}
              onClose={props.onClose}
              onSuccess={props.onUsername}
            />
            <SocialLogin onClose={props.onClose} onSuccess={props.onUsername} service="facebook" /> */}

            <hr />

            <p>
              En cliquant sur
              <em> S'inscire</em> vous acceptez nos{' '}
              <a href="/cgu" target="_blank">
                conditions d'utilisation
              </a>
            </p>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { loginUser, registerUser })(withRouter(RegisterDialog));
