import axios from 'axios';
import { GET_ERRORS } from '../error/actions';
import { getDebateList } from '../hub/actions';

export const CONNECT_TO_DEBATE = 'CONNECT_TO_DEBATE';
export const CREATE_DEBATE = 'CREATE_DEBATE';
export const GET_EVALUATION_CONTROL = 'GET_EVALUATION_CONTROL';
export const POST_VOTE = 'POST_VOTE';
export const GET_DEBATE_RESULTS = 'GET_DEBATE_RESULTS';
export const GET_ALL_ARGUMENTS = 'GET_ALL_ARGUMENTS';
export const CREATE_ARGUMENT = 'CREATE_ARGUMENT';
export const UNDO_VOTE = 'UNDO_VOTE';
export const CLEAR_DEBATE_RESULTS = 'CLEAR_DEBATE_RESULTS';
export const GET_EXPERIENCE = 'GET_EXPERIENCE';
export const CLEAR_DEBATE = 'CLEAR_DEBATE';
export const GET_DEBATE_METADATA = 'GET_DEBATE_METADATA';

export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const POST_COMMENT = 'POST_COMMENT';

export const getXLS = shareCode => async dispatch => {
  try {
    await axios.get('/api/debates/excelExport/' + shareCode, { responseType: 'blob' }).then(function(res) {
      var data = new Blob([res.data]);
      if (typeof window.navigator.msSaveBlob === 'function') {
        // If it is IE that support download blob directly.
        window.navigator.msSaveBlob(data, 'export_debat.xlsx');
      } else {
        var blob = data;
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'export_debat.xlsx';

        document.body.appendChild(link);

        link.click(); // create an <a> element and simulate the click operation.
      }
    });
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};

export const joinDebate = (history, shareCode, precision, fake_data = undefined) => async dispatch => {
  try {
    if (!fake_data) {
      let res = await axios.post('/api/debates/join', {
        shareCode,
        precision
      });
      history.push(`/join/${shareCode}`);
      dispatch({ type: CONNECT_TO_DEBATE, payload: res.data });
    } else {
      dispatch({ type: CONNECT_TO_DEBATE, payload: fake_data });
    }
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};

export const getAvailablePrecisions = (shareCode, callback) => async dispatch => {
  try {
    const availablePrecisions = await axios.get('/api/debates/getAvailablePrecisions?shareCode=' + shareCode);
    callback(availablePrecisions.data);
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err });
  }
};

export const clearDebate = () => dispatch => {
  dispatch({ type: CLEAR_DEBATE });
};

export const getDebateMetadata = debateCode => async dispatch => {
  try {
    let res = await axios.post('/api/debates/getMetadata', { debateCode });

    dispatch({ type: GET_DEBATE_METADATA, payload: res.data });
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};

export const getEvaluationControl = (controlName, precision, controlNameP2, controlNameInterview) => async dispatch => {
  try {
    let controlP1 = await axios.post('/api/evaluationControls/get', {
      controlName,
      precision
    });

    let controlInterview = controlNameInterview
      ? await axios.post('/api/evaluationControls/get', {
          controlName: controlNameInterview
        })
      : {};

    let controlP2 = controlNameP2
      ? await axios.post('/api/evaluationControls/get', {
          controlName: controlNameP2
        })
      : {};

    dispatch({
      type: GET_EVALUATION_CONTROL,
      payload: {
        controlP1: controlP1.data,
        controlP2: controlP2.data,
        controlInterviewer: controlInterview ? controlInterview.data : controlInterview
      }
    });
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};

export const vote = (
  speaker,
  argumentId,
  debateCode,
  evaluationSessionId,
  phase,
  weight = 1,
  callback
) => async dispatch => {
  try {
    let res = await axios.post('/api/debates/vote', {
      speaker,
      argumentId,
      debateCode,
      phase,
      weight,
      evaluationSessionId
    });

    if (callback) callback();

    dispatch({ type: POST_VOTE, payload: res.data });
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};

export const undoVote = (debateCode, callback) => async dispatch => {
  try {
    await axios.post('/api/debates/undo', { debateCode });

    callback();
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};

export const postUserFinishedDebate = (userId, shareCode, evaluationSessionId, notes, pauses, start, callback) => async dispatch => {
  try {
    let res = await axios.post('/api/debates/userFinished', {
      userId,
      shareCode,
      evaluationSessionId,
      notes,
      pauses,
      start
    });

    if (callback) callback();

    dispatch({ type: GET_EXPERIENCE, payload: res.data });
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};

export const clearLatestSession = shareCode => async dispatch => {
  try {
    await axios.post('/api/debates/clearLatestSession', {
      shareCode
    });
    dispatch(getDebateList());
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};
