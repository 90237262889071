/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import NavigationBar from '../../../components/NavigationBar';
import Spinner from '../../../components/Spinner';
import useApi from '../../../hooks/api/useApi';
import { GET_ARGUMENTS, DELETE_ARGUMENT, TOGGLE_PUBLIC_ARGUMENT } from '../../../services/api_routes';
import ArgumentCreationModal from '../GridCreation/ArgumentCreationModal';
import '../GridsList.scss';
import './ArgumentsList.scss';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { MenuItem, Select } from '@material-ui/core';
import { isSubstring, richTextToHTML } from '../../../utils';
import { AlertContext } from '../../../hooks/context/AlertProvider';
import SearchIcon from '@material-ui/icons/Search';
import { connect, useSelector } from 'react-redux';
import withTutorial from '../../../hooks/withTutorial';
import { tutorialScreens } from '../../../utils/texts';

const ARGUMENTS_SORTS = [
  { v: 'name_asc', n: 'Tri par ordre alphabétique croissant' },
  { v: 'name_desc', n: 'Tri par ordre alphabétique décroissant' },
  { v: 'creator_asc', n: 'Tri par créateur croissant' },
  { v: 'creator_desc', n: 'Tri par créateur décroissant' }
];
const ArgumentsFilters = ({ onChangeSort, onChangeQuery, currentSort, currentQuery }) => (
  <div className="alist-filter-bar">
    <Select onChange={onChangeSort} value={currentSort} style={{ width: '100%', flex: 1 }}>
      {ARGUMENTS_SORTS.map((sort, idx) => (
        <MenuItem value={sort.v} key={sort.v}>
          {sort.n}
        </MenuItem>
      ))}
    </Select>
    <div className="alist-bi">
      <span>
        <SearchIcon fontSize="inherit" />
      </span>
      <input
        type="text"
        placeholder="Rechercher..."
        value={currentQuery}
        onChange={onChangeQuery}
        className="fullwidth"
      />
    </div>
  </div>
);

function ArgumentsList(props) {
  const { enqueueSnackbar } = useSnackbar();

  const alertContext = React.useContext(AlertContext);
  const currentGroup = useSelector(state => state.auth.currentGroup);
  const isPCL = useSelector(state => state.auth.user.isPCL);

  const [argumentsList, isLoading, , setArgumentsList] = useApi(
    GET_ARGUMENTS + (currentGroup ? '?token=' + currentGroup.token : '')
  );
  const [filteredArgumentsList, setFilteredArgumentsList] = React.useState([]);
  const [showCreationModal, setShowCreationModal] = React.useState(false);
  const [editedArgument, setEditedArgument] = React.useState(null);
  const [filterState, setFilterState] = React.useState({
    sort: 'name_asc',
    query: ''
  });

  const onToggleCreationModal = () => {
    if (showCreationModal) setEditedArgument(null);
    setShowCreationModal(s => !s);
  };

  const findArgument = id => {
    const idx = argumentsList.findIndex(a => a._id === id);
    return idx;
  };

  const onEdit = arg => () => {
    setEditedArgument({ ...arg });
    setShowCreationModal(true);
  };

  const onDelete = arg => async () => {
    alertContext.confirm(
      {
        title: 'Supprimer le critère',
        content: (
          <>
            Êtes-vous sûr de vouloir supprimer le critère <br />"{arg.name}" ?
          </>
        ),
        submitText: 'Supprimer'
      },
      async bool => {
        if (bool) {
          const res = await Axios.post(DELETE_ARGUMENT, { id: arg._id });
          if (res.status === 200) {
            enqueueSnackbar('Le critère a bien été supprimé.', { variant: 'success' });

            const argIdx = findArgument(arg._id);
            if (argIdx > -1) {
              const f = argumentsList;
              f.splice(argIdx, 1);

              setArgumentsList([...f]);
              applyFilters();
            }
          }
        }
      }
    );
  };

  const onTogglePublic = arg => () => {
    alertContext.confirm(
      {
        title: 'Modifier le critère',
        content: (
          <>
            Êtes-vous sûr de vouloir rendre le critère <br />"{arg.name}" {arg.creator === null ? 'privé' : 'public'} ?
          </>
        ),
        submitText: 'Valider'
      },
      async bool => {
        if (bool) {
          const res = await Axios.post(TOGGLE_PUBLIC_ARGUMENT, { _id: arg._id });
          if (res.status === 200) {
            enqueueSnackbar('Le critère a bien été modifié.', { variant: 'success' });

            const argIdx = findArgument(arg._id);
            if (argIdx > -1) {
              const f = argumentsList;
              f[argIdx].creator = arg.creator === null ? props.user._id : null;

              setArgumentsList([...f]);
              applyFilters();
            }
          }
        }
      }
    );
  };

  const onValidate = arg => {
    if (arg.id) {
      const argIdx = findArgument(arg.id);
      if (argIdx > -1) {
        const f = argumentsList;
        f[argIdx] = arg;
        setArgumentsList([...f]);
      }
    } else {
      setArgumentsList(f => [arg, ...f]);
    }
    applyFilters();
  };

  React.useEffect(() => {
    if (!isLoading) setFilteredArgumentsList(argumentsList);
  }, [isLoading]);

  /** FILTERS */
  const { query, sort } = filterState;

  const applyFilters = () =>
    setFilteredArgumentsList([
      ...argumentsList
        .filter(({ name }) => !query || isSubstring(query, name))
        .sort((a, b) => {
          if (sort.includes('name'))
            return sort.includes('desc') ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
          if (sort.includes('creator'))
            return sort.includes('desc')
              ? (b.creator || '').localeCompare(a.creator || '')
              : (a.creator || '').localeCompare(b.creator || '');
          return 1;
        })
    ]);

  const onChangeSort = e => setFilterState({ ...filterState, sort: e.target.value });
  const onChangeQuery = e => setFilterState({ ...filterState, query: e.target.value });

  React.useEffect(() => {
    applyFilters();
  }, [query, sort, argumentsList, isLoading]);

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <NavigationBar title="Critères" to="/grilles" />
      <div className="body-wrapper">
        <div className="content-wrapper">
          <ArgumentCreationModal
            open={showCreationModal}
            onClose={onToggleCreationModal}
            argToEdit={editedArgument}
            onValidate={onValidate}
          />

          <div className="content-sidebar p-3" style={{ minWidth: 200 }}>
            <div className="content-title text-center">
              <div className="content-title-big">
                {argumentsList.length < 10 ? '0' : ''}
                {argumentsList.length}
              </div>
              <div>Critères</div>
            </div>

            <div className="mt-4 subtitle">
              Vous souhaitez personnaliser une grille d'évaluation (étape d'analyse continue)&nbsp;? Vous pouvez éditer
              les critères disponibles dans la liste ci-contre ou en créer un nouveau.
            </div>

            <div className="mt-4">
              <button
                className="btn btn-primary fullwidth d-flex jc-c ai-c"
                onClick={onToggleCreationModal}
                disabled={isPCL && currentGroup && currentGroup.isDemo}
              >
                <div style={{ marginRight: 20, fontSize: '1.7rem' }}>+</div>
                <div>Créer un critère</div>
              </button>
            </div>
          </div>

          <div className="content-data args-content" style={{ minWidth: 300 }}>
            <ArgumentsFilters
              currentQuery={query}
              currentSort={sort}
              onChangeSort={onChangeSort}
              onChangeQuery={onChangeQuery}
            />
            <div style={{ maxHeight: '61vh' }}>
              {filteredArgumentsList.map((arg, idx) => (
                <div className="box-shadow arg-card" key={'agc-' + idx}>
                  <div className="arg-card-head">
                    <div>
                      <strong>{arg.name}</strong>
                    </div>
                    <div className="subtitle">{richTextToHTML(arg.description)}</div>
                    {/* <div className="pastille-lightblue">A éviter</div> */}
                  </div>
                  {(props.admin || arg.creator !== null) && (
                    <div className="arg-card-controls">
                      <button className="btn btn-icon">
                        <EditIcon fontSize="inherit" onClick={onEdit(arg)} />
                      </button>
                      <button className="btn btn-icon">
                        <DeleteIcon fontSize="inherit" onClick={onDelete(arg)} />
                      </button>
                      {props.admin && (
                        <button className="btn small btn-outline capitalize" onClick={onTogglePublic(arg)}>
                          Rendre {arg.creator === null ? 'Privé' : 'Public'}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(({ auth: { user: { admin, _id } } }) => ({ admin, _id }))(
  withTutorial(ArgumentsList, tutorialScreens.ARGUMENTS_LIST, true)
);
