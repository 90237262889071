import axios from 'axios';
import { GET_ERRORS } from '../error/actions';

export const GET_DEBATE_LIST = 'GET_DEBATE_LIST';

export const getDebateList = () => async dispatch => {
  try {
    let { data } = await axios.get('/api/debates/list');
    dispatch({
      type: GET_DEBATE_LIST,
      payload: data
    });
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};
