import React, { useContext, useEffect } from 'react';
import { TutorialContext } from './context/TutorialProvider';

const withTutorial = (Component, screenLabel, withBackground, style) => props => {
  const tutorialContext = useContext(TutorialContext);

  useEffect(() => {
    tutorialContext.setScreen(screenLabel, withBackground);
    tutorialContext.setStyle({ ...style });

    return () => {
      tutorialContext.setScreen('');
      tutorialContext.setStyle({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Component {...props} startGuidedTour={tutorialContext.startGuidedTour} setTutoScreen={tutorialContext.setScreen} screenLabel={screenLabel} />;
};

export default withTutorial;
