import * as React from 'react';
import './TextInput.scss';

function TextInput({ label, multiline, fullHeight, inputRef, icon, ...props }) {
  const style = { height: fullHeight ? '100%' : '', ...props.style };
  return icon ? (
    <div className="input-icon">
      <input ref={inputRef} type="text" {...props} style={style} />
      <span className="icon">{icon}</span>
    </div>
  ) : (
    <div style={style}>
      <label className="lightblue">{label}</label>
      {multiline ? (
        <textarea ref={inputRef} {...props}></textarea>
      ) : (
        <input ref={inputRef} type="text" {...props} style={style} />
      )}
    </div>
  );
}

export default TextInput;
