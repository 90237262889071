import React from 'react';
import HexagonIcon from '../../../styles/assets/hexagon-ico.png';
import '../Home.scss';

const HomeHeader = ({ title, subtitle, content = '', ...props }) => {
  return (
    <div className="home-header" {...props}>
      <img className="home-img" src={HexagonIcon} alt="Icone Hexagone" />
      <div className="home-title">
        <h2>{title}</h2>
      </div>
      <div className="subtitle">{subtitle}</div>

      {content}
    </div>
  );
};

export default HomeHeader;
