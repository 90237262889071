import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { squareColors } from '../../../styles/palette';
import SquareActionButton from '../../../components/SquareActionButton';
import GridIcon from '../../../components/Icons/GridIcon';

const HomeActions = ({ isLoading, has_exercises, isDemo, grids_count }) => {
  const default_actions = [
    {
      icon: <EditIcon fontSize="inherit" />,
      disabled: isDemo,
      title: 'Créer une nouvelle activité',
      to: '/sessionCreation',
      label: <>&nbsp;</>,
      color: squareColors.green,
      key: 'ex'
    },
    {
      icon: <AssignmentTurnedInIcon fontSize="inherit" />,
      title: 'Corriger une activité',
      to: '/list',
      label: <>&nbsp;</>,
      color: squareColors.yellow,
      key: 'correct'
    },
    {
      icon: <GridIcon fontSize="inherit" viewBox="0 0 28 26" />,
      title: 'Gérer vos grilles',
      to: '/grilles',
      label: (isLoading ? 0 : grids_count) + ' grille' + (!isLoading && grids_count > 1 ? 's' : ''),
      color: squareColors.red,
      key: 'grids'
    }
  ];

  let actions = [...default_actions];
  if (!isLoading && !has_exercises) {
    actions = [actions[0], actions[2], null];
  }

  return (
    <div className="home-content">
      <div className="strong">Que voulez-vous faire aujourd'hui ?</div>
      <div className="home-actions">
        {actions.map(a => (!a ? <span style={{ flex: '1 1 100%' }} /> : <SquareActionButton id={a.key} {...a} />))}
      </div>
    </div>
  );
};

export default HomeActions;
