import React from 'react';
import ReactPlayer from 'react-player';
import { hexagonColors } from '../../../../styles/palette';
import { format_time } from '../../../../utils/format_time';
import TitleSection from '../../TitleSection';
import MatchingTable from '../MatchingTable';
import './Step1Tab.scss';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import StopIcon from '@material-ui/icons/Stop';
import TextInput from '../../../../components/TextInput';

function Step1Tab({ title, data, url, isTeacher, notes }) {
  const [vidTimer, setVidTimer] = React.useState(0);
  const [playing, setPlaying] = React.useState(false);

  const reactPlayer = React.useRef();
  const flashing = data.map(d => playing && Math.abs(d.time - vidTimer) <= 3.5);

  const onReplayClick = () => {
    if (!playing && reactPlayer.current && reactPlayer.current.getCurrentTime()) {
      reactPlayer.current.seekTo(0);
    }
    setPlaying(playing => !playing);
  };

  const onBack = label_idx => () => {
    const time = data[label_idx].time;
    if (reactPlayer.current) {
      reactPlayer.current.seekTo(Math.max(0, time - 10));
      setPlaying(true);
    }
  };

  return (
    <div className="content-wrapper p-0" style={{ height: '100%', flexGrow: 1, overflowX: 'hidden' }}>
      <div className="content-sidebar" style={{ margin: 0, maxWidth: '100vw' }}>
        <div className="st1-videoframe" style={{ overflow: 'hidden' }}>
          <ReactPlayer
            url={url}
            ref={reactPlayer}
            width="100%"
            height="100%"
            controls
            style={{ position: 'absolute', top: 0, left: 0, maxWidth: '100vw' }}
            playing={playing}
            onPlay={() => setPlaying(true)}
            onProgress={({ playedSeconds }) => setVidTimer(playedSeconds)}
          />
        </div>
        <button
          className="mt-3 mb-3 btn btn-outline d-flex align-items-center justify-content-center"
          style={{ width: 300, maxWidth: 300, margin: '0 auto', gap: 10 }}
          onClick={onReplayClick}
        >
          <div className="d-flex align-items-center" style={{ fontSize: 34 }}>
            {playing ? <StopIcon fontSize="inherit" /> : <PlayCircleOutlineIcon fontSize="inherit" />}
          </div>
          <div>{playing ? 'Arrêter' : "Lire l'enregistrement"}</div>
        </button>
        {notes && (
          <div style={{ padding: 20 }}>
            <div className="d-flex align-center">
              <div className="d-flex align-items-center">
                Commentaire {isTeacher ? 'appréciation générale' : 'de votre professeur(e)'}
              </div>
            </div>
            <TextInput
              multiline
              style={{ backgroundColor: 'white', fontSize: 12 }}
              placeholder="Texte libre"
              rows={4}
              value={notes}
              disabled
            />
          </div>
        )}
      </div>
      <div className="content-data" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className="st1-wrapper">
          <TitleSection title={isTeacher ? title : ''} label="Analyse continue" />
        </div>
        <div
          className="st1-table"
          style={{ flexGrow: 1, backgroundColor: playing ? '#1E1E1E33' : 'white', padding: 0, width: '100%' }}
        >
          <div style={{ width: '100%', flexGrow: 1, margin: '0 auto', paddingLeft: '10%', paddingRight: '10%' }}>
            <MatchingTable
              withBack
              onBack={onBack}
              time={vidTimer}
              headers={[
                'Temps',
                isTeacher ? 'Votre évaluation de référence' : "Évaluation de l'enseignant(e)",
                isTeacher ? "Évaluation de l'élève" : 'Votre évaluation',
                'Concordance'
              ]}
              labels={data.map(d => format_time(d.time * 1000))}
              teacher={data.map(({ teacher }) => ({
                label: teacher.name,
                color: hexagonColors[teacher.pointGroup],
                comment: teacher.comment
              }))}
              student={data.map(({ student }) => ({ label: student.name, color: hexagonColors[student.pointGroup] }))}
              matching={data.map(({ matching }) => ({
                label: matching * 25 + '%',
                color: hexagonColors[4 - matching]
              }))}
              flashing={flashing}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step1Tab;
