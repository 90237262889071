import React from 'react';
import { Link } from 'react-router-dom';
import './SquareActionButton.scss';

const SquareActionButton = ({
  title,
  label,
  icon,
  onClick,
  color,
  invert = false,
  disabled = false,
  iconStyle = {},
  titleStyle = {},
  to = '',
  ...props
}) => (
  <div className="sq-btn-container" {...props}>
    <Link
      className={'btn btn-square' + (disabled ? ' disabled ' : '')}
      style={{ backgroundColor: invert ? 'white' : color, ...props.style }}
      to={to ? to : undefined}
      onClick={to ? () => {} : onClick}
    >
      {icon && (
        <div
          className={'sq-btn-icon' + (invert ? ' sq-btn-invert' : '')}
          style={{ backgroundColor: invert ? color : '', ...iconStyle }}
        >
          {icon && icon}
        </div>
      )}
      <div className="sq-btn-title" style={{ color: invert ? 'black' : '', ...titleStyle }}>
        {title}
      </div>
      {label && (
        <div className="sq-btn-label" style={{ color: invert ? '#393939' : '' }}>
          {label}
        </div>
      )}
    </Link>
  </div>
);

export default SquareActionButton;
