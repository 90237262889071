import React from 'react';
import { hexagonColors } from '../../../styles/palette';

const groups = ['Très efficace', 'Efficace', 'Convenable', 'Peu efficace', 'À éviter'];
const descriptions = [
  'Les critères les plus convaincants.',
  "Les critères faisant de l'effet.",
  'Les critères qui fonctionnent mais peuvent être améliorés.',
  "Les critères dont l'utilisation n'a souvent que peu d'effet.",
  "Les critères qu'il est préférable d'éviter dans la plupart des situations."
];

function GroupSelect({ value, onChange, allowYellow, noDetail }) {
  let [currGroups, setCurrGroups] = React.useState(groups);

  React.useEffect(() => {
    if (!allowYellow) {
      setCurrGroups(groups.filter((_, idx) => idx !== 2));
    } else {
      setCurrGroups([...groups]);
    }
  }, [allowYellow]);

  const getGrpIdx = idx => (allowYellow || (!allowYellow && idx < 2) ? idx : idx + 1);

  return (
    <div>
      <div className="mt-3">
        <div>
          <label>Échelle de notation du critère</label>
        </div>
        <div className="group-select">
          {currGroups.map((group, idx) => (
            <button
              key={'grs-' + idx}
              type="button"
              className="group-item"
              onClick={onChange(getGrpIdx(idx))} // necessary to remove the yellow color (idx 2)
              style={{
                backgroundColor: value === getGrpIdx(idx) ? hexagonColors[getGrpIdx(idx)] : '',
                color: value === getGrpIdx(idx) ? 'white' : ''
              }}
            >
              {group}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

GroupSelect.defaultProps = {
  allowYellow: false
};

export default GroupSelect;
