/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { hexagonColors } from '../../styles/palette';
import wrapText from '../../utils/wrapText';
import './hexagons.scss';
import { Checkbox, makeStyles } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { isIE } from 'react-device-detect';
import TextInput from '../TextInput';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  root: {
    color: 'red',
    borderRadius: 10,
    '& .MuiIconButton-label': {
      position: 'relative',
      zIndex: 0
    },
    '&:not($checked) .MuiIconButton-label:after': isIE
      ? {}
      : {
          content: '""',
          left: 4,
          top: 4,
          height: 17,
          width: 17,
          position: 'absolute',
          borderRadius: '50%',
          backgroundColor: '#d8d8d8',
          zIndex: -1
        }
  },
  checked: {}
}));

export const p2_labels = ['Très insuffisant', 'Insuffisant', 'Satisfaisant', 'Très satisfaisant'];

const HexagonP2 = ({ isEditing, onEdit, onCellClick, cellsData, buzzerLabel, active, picture }) => {
  let svgRef = undefined;
  const classes = useStyles();

  useEffect(() => {
    if (svgRef !== undefined) wrapText(svgRef);
  });

  const onNameChange = idx => e => {
    onEdit(idx, e.target.value);
  };

  return (
    <div>
      <div className="hex-title mt-2">
        {picture && <img src={picture} alt="Logo Participant" />}
        {buzzerLabel}
      </div>
      <div className="hex-p2" style={{ padding: isEditing ? 0 : 20 }}>
        <table>
          <thead>
            <tr>
              <th style={{ flex: isEditing ? 3 : 1 }}></th>
              {p2_labels.map(label => (
                <th key={label} style={{ flex: isEditing ? 2 : 1 }}>
                  {label}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {cellsData.map((arg, argIdx) => (
              <tr key={argIdx}>
                <td style={{ flex: isEditing ? 3 : 1 }}>
                  {isEditing ? (
                    <TextInput
                      onChange={onNameChange(argIdx)}
                      style={{ fontWeight: 'bold' }}
                      value={arg.name}
                      icon={<EditIcon fontSize="inherit" />}
                    />
                  ) : (
                    arg.name
                  )}
                </td>

                {p2_labels.map((t, rating) => (
                  <td key={argIdx + '-' + rating} className="p2-choice" style={{ flex: isEditing ? 2 : 1 }}>
                    <Checkbox
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      checked={rating + 1 === arg.selected}
                      onClick={onCellClick.bind(this, argIdx, rating + 1)}
                      disabled={isEditing}
                      style={{
                        color: rating + 1 === arg.selected ? hexagonColors[4 - rating] : '#d8d8d8',
                        transform: 'scale(0.9)',
                        padding: 7
                      }}
                      classes={{ root: classes.root, checked: classes.checked }}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HexagonP2;
