import * as React from 'react';

export default ({ label, children, iconRight = false, ...props }) =>
  !label ? (
    <button className="icon-btn" {...props}>
      <div className="ico">{children}</div>
    </button>
  ) : (
    <button className="icon-btn d-flex" {...props}>
      {!iconRight && <span className="ico">{children}</span>}
      <span className="label">{label}</span>
      {iconRight && <span className="ico">{children}</span>}
    </button>
  );
