import { useState, useEffect } from 'react';
import axios from 'axios';
import useDispatchError from '../useDispatchError';

const useApi = (url, initialData = [], method = 'get', reqData = {}) => {
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(true);
  const dispatchError = useDispatchError();

  const fetchData = async (reqData2 = reqData) => {
    try {
      setIsLoading(true);
      const result = await axios({
        url,
        method,
        data: reqData2
      });

      setData(result.data);
      setIsLoading(false);
    } catch (err) {
      dispatchError(err.response);
      return err;
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data, isLoading, fetchData, setData];
};

export default useApi;
