/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useLongPress from '../../../hooks/useLongPress';
import { isMobile } from 'react-device-detect';
import { makeStyles, Tooltip } from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { connect } from 'react-redux';
import { richTextToHTML } from '../../../utils';

const useStyles = makeStyles(theme => ({
  tooltip: { backgroundColor: '#1E1E1E', fontSize: 14 },
  tooltipComment: { backgroundColor: '#00000000' },
  tooltipWhite: { backgroundColor: '#FFFFFF', color: 'black', fontSize: 14 }
}));

const HexagonCellWrapper = ({
  flipText,
  argument,
  children,
  onClick,
  onOpenComment,
  showArgumentDescription,
  onLongPress,
  isFlashing,
  someoneFlashing,
  active,
  currentComment,
  tooltipPlacement,
  isTeacher,
  isReplay,
  isBuzzer,
  commenting
}) => {
  // tooltipPlacement = isFlashing ? flipText ? 'top' : 'bottom' : tooltipPlacement
  const placement = flipText ? 'top' + tooltipPlacement : 'bottom' + tooltipPlacement;
  const [longPressing, setLongPressing] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const classes = useStyles();

  const onOverlayClick = e => {
    if (!isMobile || !longPressing) {
      if (onClick) onClick();
    } else {
      setLongPressing(false);
      e.preventDefault();
    }
  };

  const cellLongPress = useLongPress(
    () => {
      // setLongPressing(true);
      if (active) onLongPress(argument);
    },
    500,
    () => {}
  );

  useEffect(() => {
    if (!commenting && openComment) {
      setOpenComment(false);
    } else if (commenting) {
      setOpenComment(true);
    }
  }, [commenting]);

  const onOpenCommentWrapper = () => {
    setOpenComment(true);
    setOpenTooltip(false);
    onOpenComment();
  };

  const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);

  const commentForCellIdx =
    currentComment && currentComment.length
      ? currentComment.findIndex(
          c => c.comment.length > 0 && c.argument._id === argument._id && c.pointsGroup === argument.pointsGroup
        )
      : -1;
  const commentForCell = commentForCellIdx > -1 ? currentComment[commentForCellIdx] : null;
  return (
    <g onClick={onOverlayClick} {...cellLongPress}>
      {/* {children} */}
      <Tooltip
        title={
          commenting ? (
            ''
          ) : isFlashing && isTeacher && !isReplay && !isSamsungBrowser ? (
            <div>
              <button
                onClick={onOpenCommentWrapper}
                title="Commenter"
                className="btn btn-ico round-icon"
                style={{ boxShadow: '0px 0px 4px rgba(0,0,0, 1)' }}
              >
                <ChatBubbleIcon />
              </button>
            </div>
          ) : isReplay && commentForCell ? (
            <div>
              <div>
                <strong>{commentForCell.comment}</strong>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <strong>{argument.name}</strong>
              </div>
              <div>{richTextToHTML(argument.description)}</div>
            </div>
          )
        }
        classes={{
          tooltip:
            isFlashing && isTeacher && !isReplay
              ? classes.tooltipComment
              : commentForCell
              ? classes.tooltipWhite
              : classes.tooltip
        }}
        onOpen={() => {
          if (!isMobile && !isSamsungBrowser && !openComment)
            setOpenTooltip((argument.description || argument.name) && !openComment && !someoneFlashing);
        }}
        onClose={() => setOpenTooltip(false)}
        open={
          !commenting &&
          !isSamsungBrowser &&
          !longPressing &&
          !openComment &&
          (isFlashing ? !isReplay || (isReplay && commentForCell) : openTooltip)
        }
        enterDelay={600}
        enterNextDelay={600}
        placement={placement}
        interactive={!(someoneFlashing && !isFlashing)}
      >
        {children}
      </Tooltip>
    </g>
  );
};

export default connect(({ auth }) => ({ isTeacher: auth.user.teacher }))(HexagonCellWrapper);
