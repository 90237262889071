import { TEST_GET_LIST } from "./actions";

const INITIAL_STATE = {
  list: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TEST_GET_LIST:
      return Object.assign({}, state, { list: action.payload });
    default:
      return state;
  }
}
