import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentHexagonGroup } from '../services/auth/actions';

const useHexagonGroup = () => {
  const [hexagonGroup, updateHexagonGroup] = useState(0);

  const dispatch = useDispatch();
  const currentGroup = useSelector(state => state.auth.user.currentHexagonGroup);

  const setHexagonGroup = useCallback(
    group => {
      updateHexagonGroup(group);
      dispatch(setCurrentHexagonGroup(group));
    },
    [dispatch]
  );

  useEffect(() => {
    setHexagonGroup(currentGroup);
  }, [currentGroup, setHexagonGroup]);

  return [hexagonGroup, setHexagonGroup];
};

export default useHexagonGroup;
