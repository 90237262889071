import React from 'react';

export function remove_accents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function compareStrings(str1, str2) {
  return remove_accents(str1) === remove_accents(str2);
}

export function isSubstring(substring, src) {
  if (!substring || !src) return true;
  return remove_accents(src.toLowerCase()).includes(remove_accents(substring.toLowerCase()));
}

/**
 * For now this function only supports the following tags:
 * - [i]italic[/i] => replaced by <em>italic</em>
 * @param {String} richText text to parse
 */
export function richTextToHTML(richText) {
  if (!richText) return '';
  const words = richText.split(/(\[i\].*?\[\/i\])/g);

  return words.map(word => {
    if (word.includes('[i]')) return <em>{word.replace('[i]', '').replace('[/i]', '')}</em>;
    else return word;
  });
}

export function linesToHTML(text) {
  if (!text) return '';
  const lines = text.split('\n');

  return (
    <p>
      {lines.map(line => (
        <span className="text-break">
          {line} <br />
        </span>
      ))}
    </p>
  );
}
