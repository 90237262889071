import * as React from 'react';
import TitleSection from '../../TitleSection';
import DougnhutChart from '../../../../components/charts/DougnhutChart';
import BarChart from '../../../../components/charts/BarChart';
import './DetailsTab.scss';
import { hexagonColors } from '../../../../styles/palette';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { theme } from '../../../../styles/ui_theme';
import Hexagon from '../../../../components/Hexagon';

/**
 * Renders charts to see the detail of a given evaluation
 * @param {String} title
 * @param {*} data of form {
 *    pointGroups: [{ name: String, description: String }],
 *    groupsRepartition: [Int],
 *    argsRepartition: [{ name: String, description: String, pointGroup: Int, repartition: Int }]
 * }
 */
function DetailsTab({ title, data, isTeacher, phase2, shouldScrollToP2 }) {
  const { groupsRepartition, pointGroups, argsRepartition, hasYellowArgs } = data;
  const { hexagon, selected } = phase2;
  const BARS_PER_PAGE = 7;

  const [currentPage, setCurrentPage] = React.useState(0);

  // split args into pages
  const pages = [];
  for (let i = 0, j = argsRepartition.length; i < j; i += BARS_PER_PAGE) {
    pages.push(argsRepartition.slice(i, i + BARS_PER_PAGE));
  }

  if (pages.length === 0) {
    pages.push([]);
  }

  // Remove yellow description if there is no yellow arg in the grid
  const pointGroupsFiltered = hasYellowArgs ? pointGroups : pointGroups.filter((_, i) => i !== 2);
  const hexagonColorsFiltered = hasYellowArgs ? hexagonColors : Object.values(hexagonColors).filter((_, i) => i !== 2);

  React.useEffect(() => {
    if (shouldScrollToP2) {
      document.getElementById('p2-res').scrollIntoView();
    }
  }, [shouldScrollToP2]);

  return (
    <div className="sdt-wrapper">
      <TitleSection title={isTeacher ? title : ''} label="Analyse continue" />

      <div className="chart-container">
        <div className="cc-chart" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          {groupsRepartition.some(x => parseInt(x)) ? (
            <DougnhutChart
              data={groupsRepartition}
              colors={Object.keys(hexagonColors).map(key => hexagonColors[key])}
              labels={pointGroups.map(p => p.name)}
            />
          ) : (
            <div className="bold">Vous n'avez cliqué sur aucun critère.</div>
          )}
        </div>
        <div className="sdt-doug-desc">
          {pointGroupsFiltered.map(({ name, description }, idx) => (
            <div className="sdtdg-item" key={'sdtdg-' + idx} style={{ flex: hasYellowArgs ? '1 1 33%' : '1 1 25%' }}>
              <div className="sdtdg-title" style={{ color: hexagonColorsFiltered[idx] }}>
                <div className="sdtdg-pastille" style={{ backgroundColor: hexagonColorsFiltered[idx] }} />
                <div>{name}</div>
              </div>
            </div>
          ))}
          <div className="sdtdg-item" />
        </div>
      </div>

      <div className="chart-container mt-6">
        <div className="cc-chart cc-bars">
          {currentPage > 0 && (
            <div>
              <button
                className="btn btn-icon cc-arrow"
                onClick={() => setCurrentPage(currentPage - 1)}
                style={{ transform: 'scaleX(-1)', paddingRight: 3 }}
              >
                <ArrowForwardIosIcon fontSize="inherit" />
              </button>
            </div>
          )}
          {pages[currentPage].length > 0 ? (
            <BarChart
              labels={pages[currentPage].map(r => r.name)}
              datasets={[
                {
                  maxBarThickness: 100,
                  description: pages[currentPage].map(r => r.description),
                  data: pages[currentPage].map(r => r.repartition),
                  backgroundColor: pages[currentPage].map(r => hexagonColors[r.pointGroup])
                }
              ]}
            />
          ) : (
            <div>Vous n'avez cliqué sur aucun critère</div>
          )}
          {currentPage < pages.length - 1 && (
            <div>
              <button className="btn btn-icon cc-arrow" onClick={() => setCurrentPage(currentPage + 1)}>
                <ArrowForwardIosIcon fontSize="inherit" />
              </button>
            </div>
          )}
        </div>

        <div className="cc-bubbles">
          {pages.map((_, idx) => (
            <div
              className="cc-bubble"
              key={'cc-bubble' + idx}
              style={{ backgroundColor: idx === currentPage && pages.length > 1 ? theme.palette.primary : '' }}
            ></div>
          ))}
        </div>
      </div>

      <div style={{ marginTop: '4em' }} />

      <TitleSection title={''} label="Appréciation générale" />

      <div className="chart-container mt-6" id="p2-res">
        <Hexagon
          evaluationControl={hexagon}
          phase2Selection={selected[0]}
          buzzerLabel={''}
          onCellClick={() => {}}
          phase={2}
        />
      </div>
    </div>
  );
}

export default DetailsTab;
