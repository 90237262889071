/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import TutorialFloater from '../../components/TutorialFloater/TutorialFloater';
import TutorialCard from '../../components/TutorialCard/TutorialCard';
import {
  GET_VIDEOS_PER_SCREEN,
  DISMISS_TUTORIAL,
  GET_GUIDED_TOUR,
  DISMISS_GUIDED_TOUR
} from '../../services/api_routes';
import useApiPrepare from '../api/useApiPrepare';
import { refreshToken } from '../../services/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Joyride from 'react-joyride';
import parse from 'html-react-parser';

const INITIAL_CONTEXT = {
  tutorials: { Accueil: [] },
  currentScreen: '',
  tourSteps: {},
  withBackground: false,
  style: {},
  showFloater: true,
  startGuidedTour: false
};

export const TutorialContext = React.createContext(INITIAL_CONTEXT);

function RightFloater({ footerRef, ...props }) {
  const [offset, setOffset] = useState('1.5em');

  // Stop the right floater scrolling when it reacher the footer list.
  var scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;

  window.onscroll = () => {
    const position = window.scrollY;

    if (footerRef.current) {
      const shouldStopScroll = scrollHeight - position < footerRef.current.offsetHeight;
      setOffset(shouldStopScroll ? footerRef.current.offsetHeight + 30 - (scrollHeight - window.scrollY) : '1.5em');
    }
  };

  return (
    <div
      className="tut-context-floating"
      style={{
        bottom: offset
      }}
    >
      <TutorialCard {...props} />
    </div>
  );
}

function TutorialProvider({ children }) {
  const [tutorials, , fetchTutorials] = useApiPrepare(GET_VIDEOS_PER_SCREEN, {});
  const [guidedTour, isLoadingTour, fetchGuidedTour] = useApiPrepare(GET_GUIDED_TOUR, {});
  const [, , dismissTutorial] = useApiPrepare(DISMISS_TUTORIAL, {}, 'post');
  const [, , dismissGuidedTour] = useApiPrepare(DISMISS_GUIDED_TOUR, {}, 'post');
  const [state, setState] = React.useState(INITIAL_CONTEXT);
  const footerRef = useRef(null);

  const dispatch = useDispatch();
  const { isAuthenticated, user, currentGroup } = useSelector(state => state.auth);

  useEffect(() => {
    if (isAuthenticated && user.teacher) {
      fetchTutorials();
      fetchGuidedTour();
    }
  }, [isAuthenticated, user.teacher]);

  useEffect(() => {
    if (!isLoadingTour) {
      Object.keys(guidedTour).forEach(screen => {
        guidedTour[screen].steps = guidedTour[screen].steps.map(s => {
          return {
            ...s,
            content: typeof s.content === 'string' ? <div>{parse(s.content)}</div> : s.content
          };
        });
      });
    }
  }, [isLoadingTour]);

  const setScreen = (currentScreen, withBackground = false) => {
    setState(s => ({
      ...s,
      currentScreen,
      withBackground,
      showFloater: true,
      startGuidedTour: false,
      tourSteps: guidedTour[currentScreen] ? [...guidedTour[currentScreen].steps] : []
    }));
  };
  const setStyle = style => setState(s => ({ ...s, style }));

  const startGuidedTour = () =>
    setState(s => {
      return { ...s, startGuidedTour: true };
    });
  const onCloseFloater = () => setState(s => ({ ...s, showFloater: false }));

  const onDismissFloater = async () => {
    onCloseFloater();

    const res = await dismissTutorial({ screen: state.currentScreen });
    if (res.status === 200) {
      const { token } = res.data;
      refreshToken(token, dispatch);
    }
  };

  const videos = tutorials[state.currentScreen] || [];
  const wasDismissed = user.dismissedTutorials ? user.dismissedTutorials.includes(state.currentScreen) : true;

  const finishedTourObj = user.finishedGuidedTours
    ? user.finishedGuidedTours.filter(g => g.screen === state.currentScreen)[0]
    : {};
  const wasGuidedTourDismissed = finishedTourObj !== undefined;
  const shouldReplayTour = wasGuidedTourDismissed ? finishedTourObj.shouldReplay : false;

  const onGuidedTourEvent = async ({ type }) => {
    if (type === 'tour:end') {
      const res = await dismissGuidedTour({ screen: state.currentScreen });
      if (res.status === 200) {
        const { token } = res.data;
        refreshToken(token, dispatch);
      }
    }
  };

  const currentGuidedTour = guidedTour[state.currentScreen];
  const tourSteps = currentGuidedTour ? [...currentGuidedTour.steps] : [];

  return (
    <TutorialContext.Provider value={{ ...state, tutorials, setScreen, setStyle, startGuidedTour }}>
      {isAuthenticated && user.teacher && user.isPCL && !(currentGroup && currentGroup.isDemo) && (
        <Joyride
          showProgress={currentGuidedTour ? currentGuidedTour.showProgress : true}
          continuous
          steps={tourSteps}
          run={(!wasGuidedTourDismissed || shouldReplayTour) && state.startGuidedTour}
          debug
          showSkipButton={wasGuidedTourDismissed}
          hideCloseButton
          disableCloseOnEsc
          disableOverlayClose
          show
          callback={onGuidedTourEvent}
          locale={{
            back: 'Précédent',
            close: 'Femer',
            last: 'Fermer',
            next: 'Suivant',
            open: 'Ouvrir la fenêtre',
            skip: 'Ignorer'
          }}
        />
      )}

      {children}

      {isAuthenticated && user.teacher && (
        <>
          {videos.length > 0 && state.showFloater && !wasDismissed && (
            <RightFloater
              {...videos[0]}
              titleOnly={false}
              closable
              onClose={onCloseFloater}
              onCloseForever={onDismissFloater}
              footerRef={footerRef}
            />
          )}

          {state.currentScreen && videos.length > 0 && (
            <div style={{ backgroundColor: state.withBackground ? '#dae2cb' : '' }} ref={footerRef}>
              <div
                className="body-wrapper"
                style={{
                  height: 'unset',
                  paddingTop: 20,
                  paddingBottom: 20,
                  ...state.style
                }}
              >
                <TutorialFloater videos={videos} />
              </div>
            </div>
          )}
        </>
      )}
    </TutorialContext.Provider>
  );
}

export default TutorialProvider;
