import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './NavigationBar.scss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function NavigationBar({ title, to, override = false, onBack = null, history }) {
  const overrideStyle = { paddingTop: 0, backgroundColor: 'white' };

  return (
    <div className="nvbar-container" style={override ? overrideStyle : {}}>
      <div className="nvbar-content">
        {onBack ? (
          <button className="btn btn-text" onClick={onBack}>
            <ArrowBackIcon fontSize="inherit" />
          </button>
        ) : (
          <Link className="btn btn-text" to={to}>
            <ArrowBackIcon fontSize="inherit" />
          </Link>
        )}
        {override ? title : <h1>{title}</h1>}
      </div>
      {override && <hr />}
    </div>
  );
}

export default withRouter(NavigationBar);
