import React from 'react';
import PropTypes from 'prop-types';
import HexagonP1 from './HexagonP1';
import HexagonP2 from './HexagonP2';
import shortid from 'shortid';

const evaluationControlToArray = evaluationControl => {
  return evaluationControl.argumentGroups.reduce(
    (acc, grp) =>
      acc.concat(
        grp.arguments.map((arg, i) => ({
          ...arg,
          pointsGroup: grp.groupsForArguments[i]
        }))
      ),
    []
  );
};

function Hexagon(props) {
  const [someVoted] = React.useState(false);
  if (props.evaluationControl === undefined || Object.keys(props.evaluationControl).length === 0) return '';

  // console.log('In hexagon.js ', props.editedComment);
  const { onCellClick, ...rest } = props;

  // Pad appreciation labels to 12 items in edition mode
  const appreciationLabels = props.evaluationControl.globalAppreciationLabels;
  if (props.isEditing && props.phase >= 2 && appreciationLabels.length < 12) {
    for (let i = appreciationLabels.length; i < 12; i++) {
      appreciationLabels.push('');
    }
  }

  let cellsData =
    !props.evaluationControl.globalAppreciationLabels ||
    props.evaluationControl.globalAppreciationLabels.length === 0 ||
    props.phase < 2
      ? evaluationControlToArray(props.evaluationControl)
      : props.evaluationControl.globalAppreciationLabels.map(name => ({ name }));

  const onCellClickOverride = !props.active
    ? () => {}
    : id => {
        props.onCellClick(props.buzzerLabel, cellsData[id], id, props.speakerId, props.teamId);
      };

  const onCellClickP2Override = (argNb, rating) => {
    props.onCellClick(props.buzzerLabel, argNb, rating, props.speakerId, props.teamId);
  };

  const onBuzzerClick = !props.active
    ? () => {}
    : () => {
        props.onCellClick(
          props.buzzerLabel,
          { ...props.evaluationControl.buzzerArgument, pointsGroup: props.evaluationControl.groupForBuzzer },
          -1,
          props.speakerId,
          props.teamId
        );
      };

  if (props.phase < 2) {
    return props.cellNumber % 6 === 0 ? (
      <div className="text-center" id="gt-hexagon">
        <HexagonP1
          currentComment={props.currentComment}
          hexagonSize={props.cellNumber}
          hexagonId={shortid.generate()}
          isReplay={props.isReplay}
          onCellClick={onCellClickOverride}
          onBuzzerClick={onBuzzerClick}
          cellsData={cellsData}
          commenting={props.commenting}
          someVoted={someVoted}
          buzzerGroup={props.evaluationControl.groupForBuzzer}
          timer={props.timer}
          buzzerArgument={{
            ...props.evaluationControl.buzzerArgument,
            pointsGroup: props.evaluationControl.groupForBuzzer
          }}
          buzzerDescription={
            props.evaluationControl.buzzerArgument
              ? `Appuyez sur le buzzer central pour le vote principal. ${props.evaluationControl.buzzerArgument.name}: ${props.evaluationControl.buzzerArgument.description}`
              : ''
          }
          {...rest}
        />
      </div>
    ) : (
      <div>Nombre de cellules incorrect.</div>
    );
  } else {
    if (!props.isEditing) {
      props.phase2Selection.forEach((n, i) => {
        if (i !== -1) {
          cellsData[i]['selected'] = n;
        }
      });
    }

    return (
      <div className="text-center" style={{ flex: '1 1 50%' }}>
        <HexagonP2
          picture={props.picture}
          onCellClick={onCellClickP2Override}
          cellsData={cellsData}
          active={props.active}
          buzzerLabel={props.buzzerLabel}
          isEditing={props.isEditing}
          onEdit={props.onEdit}
        />
      </div>
    );
  }
}

Hexagon.defaultProps = {
  speakerId: -1,
  teamId: -1,
  flashing: []
};

Hexagon.propTypes = {
  cellNumber: PropTypes.oneOf([6, 12, 18, 24, 30, 36]),
  onCellClick: PropTypes.func,
  evaluationControl: PropTypes.object,
  active: PropTypes.bool,
  phase: PropTypes.number,
  buzzerLabel: PropTypes.string,
  speakerId: PropTypes.number,
  teamId: PropTypes.number
};

export default Hexagon;
