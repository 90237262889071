import React from 'react';
import NavigationBar from '../../components/NavigationBar';
import Spinner from '../../components/Spinner';
import useApi from '../../hooks/api/useApi';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { GET_FOLDER_CREATED } from '../../services/api_routes';
import './SessionsClone.scss';
import { withRouter } from 'react-router-dom';
import SessionIcon from '../../components/SessionIcon';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { isSubstring } from '../../utils';
import SearchIcon from '@material-ui/icons/Search';
import withTutorial from '../../hooks/withTutorial';
import { tutorialScreens } from '../../utils/texts';

function SessionClone({ history }) {
  const [existingSessions, isLoading] = useApi(GET_FOLDER_CREATED, []);
  const [searchQuery, setSearchQuery] = React.useState('');

  const filteredSessions = existingSessions
    .filter(session => isSubstring(searchQuery, session.title))
    .sort((a, b) => new Date(b.timeCreated) - new Date(a.timeCreated));

  const onCardClick = session => () => {
    const creation_pages = {
      in_person: '/create_inperson',
      to_do: '/create_folder',
      to_eval: '/create_presta'
    };

    history.push({
      pathname: creation_pages[session.mode],
      state: { session }
    });
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <NavigationBar title="Partir d'une activité existante" to="/sessionCreation" />

      <div className="body-wrapper">
        <div className="scl-wrapper">
          <div className="scl-ico mt-4">
            <CloudDownloadIcon fontSize="inherit" />
          </div>

          <h2 className="primary mt-4">Activités</h2>

          <div className="subtitle mt-2">
            Pour créer votre nouvelle activité, vous pouvez dupliquer <br />
            et adapter toutes les activités présentes dans cette liste.
          </div>

          <div className="d-flex">
            <div className="scl-search mt-4">
              <span>
                <SearchIcon fontSize="inherit" />
              </span>
              <input
                type="text"
                className="fullwidth"
                placeholder="Rechercher..."
                onChange={e => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          <div>
            {filteredSessions.map((session, idx) => (
              <div className="scl-card" onClick={onCardClick(session)} key={'sc-' + idx}>
                <SessionIcon mode={session.mode} prestationMode={session.prestationMode} />
                <div className="scl-card-title">{session.title}</div>
                <div>
                  <ArrowForwardIosIcon />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(withTutorial(SessionClone, tutorialScreens.EXERCISES_CREATION_COPY));
