const API_ROOT = '/api';
const API_DEBATES_ROOT = API_ROOT + '/debates';
const API_USERS_ROOT = API_ROOT + '/users';
const API_HEXAGON_ROOT = API_ROOT + '/evaluationControls';
const API_COMMENTS_ROOT = API_ROOT + '/comments';
const API_ARGUMENTS_ROOT = API_ROOT + '/arguments';
const API_ADMIN_ROOT = API_ROOT + '/admin';
const API_GROUPS_ROOT = API_ROOT + '/groups';
const API_FOLDERS_ROOT = API_ROOT + '/folders';
const API_TUTOS_ROOT = API_ROOT + '/tutorials';

export const DEBATE_LIST = API_DEBATES_ROOT + '/list';
export const GET_RESULTS = API_DEBATES_ROOT + '/votesStats';
export const CREATE_DEBATE = API_DEBATES_ROOT + '/create';
export const POST_VOTE = API_DEBATES_ROOT + '/vote';
export const DELETE_DEBATE = API_DEBATES_ROOT + '/delete';
export const REPLAY_DEBATE = API_DEBATES_ROOT + '/replay';
export const VALIDATE_DEBATE = API_DEBATES_ROOT + '/validate';
export const UPDATE_GRADE = API_DEBATES_ROOT + '/update_grade';
export const VALIDATE_GRADES = API_DEBATES_ROOT + '/validate_grades';
export const DELETE_EVALUATION = API_DEBATES_ROOT + '/delete_evaluation';
export const GET_HOME_DATA = API_DEBATES_ROOT + '/get_home_data';
export const GET_PENDING_ACTIVITIES = API_DEBATES_ROOT + '/get_pending_activities';

export const USER_NEWSFEED = API_USERS_ROOT + '/newsfeed';
export const PROFILE_DATA = API_USERS_ROOT + '/getProfileData';
export const REGISTER_USER = API_USERS_ROOT + '/register';
export const LOGIN_USER = API_USERS_ROOT + '/login';
export const GET_USER_STATS = API_USERS_ROOT + '/getUserStats';
export const GET_GLOBAL_SUMMARY = API_USERS_ROOT + '/globalSummary';
export const UPDATE_HEXAGON_GROUP = API_USERS_ROOT + '/updateHexagonGroup';
export const GET_HEXAGON_GROUP = API_USERS_ROOT + '/getHexagonGroup';
export const SKIP_ONBOARDING = API_USERS_ROOT + '/skipOnboarding';

export const GET_ALL_HEXAGONS = API_HEXAGON_ROOT + '/getAll';
export const GET_EDIT_HEXAGONS = API_HEXAGON_ROOT + '/getEdit';
export const GET_ALL_HEXAGONS_P2 = GET_ALL_HEXAGONS + '?p2=true';
export const GET_HEXAGON = API_HEXAGON_ROOT + '/get';
export const CREATE_HEXAGON = API_HEXAGON_ROOT + '/create';
export const EDIT_HEXAGON = API_HEXAGON_ROOT + '/edit';
export const DELETE_HEXAGON = API_HEXAGON_ROOT + '/delete';
export const TOGGLE_PUBLIC_HEXAGON = API_HEXAGON_ROOT + '/togglePublic';

export const GET_COMMENTS = API_COMMENTS_ROOT + '/getAll';
export const NEW_COMMENT = API_COMMENTS_ROOT + '/create';
export const LIKE_COMMENT = API_COMMENTS_ROOT + '/like';

export const GET_ARGUMENTS = API_ARGUMENTS_ROOT + '/getAll';
export const CREATE_ARGUMENT = API_ARGUMENTS_ROOT + '/create';
export const EDIT_ARGUMENT = API_ARGUMENTS_ROOT + '/edit';
export const DELETE_ARGUMENT = API_ARGUMENTS_ROOT + '/delete';
export const TOGGLE_PUBLIC_ARGUMENT = API_ARGUMENTS_ROOT + '/togglePublic';

export const GET_SCHOOLS = API_ADMIN_ROOT + '/schools/all';
export const CREATE_SCHOOL = API_ADMIN_ROOT + '/schools/create';
export const DELETE_SCHOOL = API_ADMIN_ROOT + '/schools/delete';
export const EDIT_SCHOOL = API_ADMIN_ROOT + '/schools/edit';
export const GET_SCHOOL_STATS = API_ADMIN_ROOT + '/schools/stats';
export const GET_TEACHERS = API_ADMIN_ROOT + '/schools/teachers';
export const DELETE_TEACHER = API_ADMIN_ROOT + '/schools/delete_teacher';
export const ADD_TEACHER = API_ADMIN_ROOT + '/schools/add_teacher';

export const GET_OWNED_GROUPS = API_GROUPS_ROOT + '/all_owned';
export const ADD_GROUP = API_GROUPS_ROOT + '/create';
export const EDIT_GROUP = API_GROUPS_ROOT + '/edit';
export const DELETE_GROUP = API_GROUPS_ROOT + '/delete';
export const JOIN_GROUP = API_GROUPS_ROOT + '/join';
export const CHECK_GROUP_TOKEN = API_GROUPS_ROOT + '/checkGroupToken';
export const GET_GROUP_MEMBERS = API_GROUPS_ROOT + '/members';
export const DELETE_GROUP_MEMBER = API_GROUPS_ROOT + '/remove_member';
export const LEAVE_GROUP = API_GROUPS_ROOT + '/leave';

export const CREATE_FOLDER = API_FOLDERS_ROOT + '/create';
export const GET_ALL_FOLDERS = API_FOLDERS_ROOT + '/get_all';
export const GET_FOLDER = API_FOLDERS_ROOT + '/get';
export const UPLOAD_FILE = API_FOLDERS_ROOT + '/upload';
export const VALIDATE_FILE_UPLOAD = API_FOLDERS_ROOT + '/validate_upload';
export const GET_FOLDER_WORKS = API_FOLDERS_ROOT + '/get_works';
export const GET_MISSING_SUBMISSIONS = API_FOLDERS_ROOT + '/missing_submissions';
export const DELETE_FOLDER = API_FOLDERS_ROOT + '/delete';
export const GET_FOLDER_EVALUATIONS = API_FOLDERS_ROOT + '/evals';
export const GET_FOLDER_CREATED = API_FOLDERS_ROOT + '/all_created';
export const EDIT_FOLDER = API_FOLDERS_ROOT + '/edit';
export const CREATE_IN_PERSON_SESSION = API_FOLDERS_ROOT + '/create_in_person_session';

export const GET_ALL_TUTOS = API_TUTOS_ROOT + '/get_all_videos';
export const GET_ALL_THEMES = API_TUTOS_ROOT + '/get_all_themes';
export const CREATE_TUTO_THEME = API_TUTOS_ROOT + '/edit_theme';
export const CREATE_TUTO_VIDEO = API_TUTOS_ROOT + '/edit_video';
export const DELETE_TUTO_THEME = API_TUTOS_ROOT + '/delete_theme';
export const DELETE_TUTO_VIDEO = API_TUTOS_ROOT + '/delete_video';
export const GET_ALL_TUTORIALS = API_TUTOS_ROOT + '/get_tutorials_list';
export const GET_GUIDED_TOUR = API_TUTOS_ROOT + '/get_guided_tour';
export const GET_TUTORIAL = API_TUTOS_ROOT + '/get_video';
export const GET_VIDEOS_PER_SCREEN = API_TUTOS_ROOT + '/get_videos_per_screen';
export const DISMISS_TUTORIAL = API_TUTOS_ROOT + '/dismiss';
export const DISMISS_GUIDED_TOUR = API_TUTOS_ROOT + '/dismiss_guided_tour';
export const RESET_GUIDED_TOUR = API_TUTOS_ROOT + '/reset_guided_tour';