import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Hexagon from '../../components/Hexagon';
import Spinner from '../../components/Spinner';
import useApi from '../../hooks/api/useApi';
import './GridsList.scss';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Axios from 'axios';
import { DELETE_HEXAGON, TOGGLE_PUBLIC_HEXAGON } from '../../services/api_routes';
import { AlertContext } from '../../hooks/context/AlertProvider';
import { useSnackbar } from 'notistack';
import HexagonGroupFilter from '../../components/HexagonGroupFilter';
import useHexagonGroup from '../../hooks/useHexagonGroup';
import { useSelector } from 'react-redux';
import SquareActionButton from '../../components/SquareActionButton';
import { hexagonColors } from '../../styles/palette';
import AddIcon from '@material-ui/icons/Add';
import NavigationBar from '../../components/NavigationBar';
import withTutorial from '../../hooks/withTutorial';
import { tutorialScreens } from '../../utils/texts';
import { useEffect } from 'react';

function GridsList(props) {
  const history = useHistory();
  const alertContext = React.useContext(AlertContext);
  const [hexagonGroup, setHexagonGroup] = useHexagonGroup();
  const { enqueueSnackbar } = useSnackbar();

  const isAdmin = useSelector(state => state.auth.user.admin);
  const isPCL = useSelector(state => state.auth.user.isPCL);
  const currentGroup = useSelector(state => state.auth.currentGroup);
  const grids_library_url = '/api/evaluationControls/get_grids' + (currentGroup ? '?token=' + currentGroup.token : '');
  const [grids, isLoading, reloadGrids] = useApi(grids_library_url);

  useEffect(() => {
    if (!isLoading) {
      props.startGuidedTour();
    }
  }, [isLoading]);

  const onEdit = gridId => () => {
    const gridIdx = grids.hexagons.findIndex(grid => grid._id === gridId);
    history.push('/grilles/creation', { grids: grids.hexagons, editedGrid: gridIdx });
  };

  const onTogglePublic = ({ name, ref, _id }) => async () => {
    alertContext.confirm(
      {
        submitText: 'Modifier',
        title: 'Modifier la grille',
        content: (
          <>
            Êtes-vous sûr de vouloir rendre la grille <br /> "{name}" {ref.isPublic ? 'privée' : 'publique'}?
          </>
        )
      },
      async bool => {
        if (bool) {
          const res = await Axios.post(TOGGLE_PUBLIC_HEXAGON, { _id });
          if (res.status === 200) {
            enqueueSnackbar('La grille a bien été modifiée.', { variant: 'success' });
            reloadGrids();
          }
        }
      }
    );
  };

  const onDelete = grid => async () => {
    alertContext.confirm(
      {
        submitText: 'Supprimer',
        title: 'Supprimer la grille',
        content: (
          <>
            Êtes-vous sûr de vouloir supprimer la grille <br /> "{grid.name}" ?
          </>
        )
      },
      async bool => {
        if (bool) {
          const res = await Axios.post(DELETE_HEXAGON, { id: grid._id });
          if (res.status === 200) {
            enqueueSnackbar('La grille a bien été supprimée.', { variant: 'success' });
            reloadGrids();
          }
        }
      }
    );
  };

  const hexagons = isLoading
    ? []
    : grids.hexagons.filter(
        hex => hex.ref.group === hexagonGroup || (hexagonGroup === 5 && hex.ref.group === undefined)
      );

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <NavigationBar title="Gérer vos grilles" to="/home" />
      <div className="body-wrapper">
        <div className="gl-wrapper">
          {/* LEFT SIDEBAR */}
          <div className="gl-top p-3" style={{ minWidth: 100 }}>
            <div className="gl-left">
              {/* <div className="strong">Ajouter une grille</div> */}
              <SquareActionButton
                onClick={e => {
                  e.preventDefault();
                  history.push('/grilles/creation', { grids: grids.hexagons });
                }}
                disabled={isPCL && currentGroup && currentGroup.isDemo}
                title="Créez une grille d'évaluation"
                label="Créez votre grille personnalisée en 2 étapes : l'analyse continue et l'appréciation générale."
                color={hexagonColors[4]}
                icon={<AddIcon fontSize="inherit" />}
              />
              {/* <div className="gr-button"> */}
              {/* </div> */}
            </div>

            <div>
              {/* <div className="strong">Vos critères</div> */}
              <div className="gl-arguments mt-2">
                <div className="gl-arguments-left">
                  {/* <div>Vous avez</div> */}
                  <div className="gl-arguments-count">{grids.argsCount}</div>
                  <div className="strong">critère{grids.argsCount > 1 ? 's' : ''}</div>
                  <div>disponible{grids.argsCount > 1 ? 's' : ''}</div>
                </div>

                <div className="gl-arguments-right">
                  <div className="subtitle">
                    Les critères peuvent être utilisés indépendamment dans toutes vos grilles (étape d'analyse
                    continue). Vous pouvez utiliser les critères existants ou en créer de nouveaux.
                  </div>
                  <Link className="btn btn-outline small mt-4" to="/grilles/arguments">
                    Ouvrir
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* RIGHT SIDEBAR */}
          <div className="content-data hexagons-content" style={{ flex: 12, flexDirection: 'column' }}>
            <div className="strong hex-content-title">Les grilles d'évaluation disponibles</div>
            <HexagonGroupFilter currentGroup={hexagonGroup} onGroupUpdate={setHexagonGroup} />
            <div className="hexagons-container">
              {hexagons.map((grid, key) => (
                <div
                  className="hexagons-cell"
                  key={'hexagons-cell-' + key}
                  id={'grid-' + (grid.ref.isPublic && grid.ref.group === 6 ? 'pcl' : key)}
                >
                  <div style={{ padding: 10 }}>
                    <Hexagon
                      key={key}
                      phase={1}
                      onCellClick={() => {}}
                      active={true}
                      evaluationControl={grid}
                      cellNumber={grid.argumentGroups[0].arguments.length * 6}
                    />
                  </div>
                  <div>
                    <strong>{grid.name}</strong>
                    <br />
                    <small>{(grid.precision + 1) * 6 + 1} critères</small>
                  </div>
                  {grid.isCreator && (
                    <div className="hexagons-cell-controls">
                      <button className="btn btn-icon">
                        <EditIcon fontSize="inherit" onClick={onEdit(grid._id)} />
                      </button>
                      <button className="btn btn-icon">
                        <DeleteIcon fontSize="inherit" onClick={onDelete(grid)} />
                      </button>
                      {isAdmin && (
                        <button className="btn btn-outline small capitalize" onClick={onTogglePublic(grid)}>
                          Rendre {grid.ref.isPublic ? 'privée' : 'publique'}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ))}
              {new Array(3 - (hexagons.length % 3)).fill().map(idx => (
                <div key={'h' + idx} className="hexagons-cell" style={{ visibility: 'hidden' }} />
              ))}
              {hexagons.length === 0 && <div className="empty-group">Il n'y a aucune grille dans cette catégorie.</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTutorial(GridsList, tutorialScreens.GRID_LIST, false, { paddingLeft: 40 });
