import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const GridIcon = props => (
  <SvgIcon {...props}>
    <g id="2.0-Dashboard-sans-exercice" transform="translate(-499.000000, -405.000000)">
      <g id="fonctionnalités" transform="translate(60.000000, 333.000000)">
        <g id="grilles" transform="translate(309.000000, 50.000000)">
          <g id="bulle" transform="translate(119.000000, 10.000000)">
            <path
              d="M27.3285084,31.5460614 L30.7703971,37.460452 L19.2292257,37.460452 L22.6711144,31.5460614 L27.3285084,31.5460614 Z M17.901694,26.1450308 L20.2214327,30.1310208 L16.7701142,36.0623851 L11,26.1450308 L17.901694,26.1450308 Z M39,26.1450308 L33.2298858,36.0623851 L29.7785673,30.1310208 L32.098306,26.1450308 L39,26.1450308 Z M27.3291685,20.7433402 L29.6489071,24.7302731 L27.3291685,28.7172061 L22.6708315,28.7172061 L20.3510929,24.7302731 L22.6708315,20.7433402 L27.3291685,20.7433402 Z M16.7701142,13.3981612 L20.2214327,19.3295255 L17.901694,23.3155154 L11,23.3155154 L16.7701142,13.3981612 Z M33.2292257,13.3978783 L38.9993399,23.3152325 L32.0985889,23.3152325 L29.7788502,19.3292426 L33.2292257,13.3978783 Z M30.7699256,12 L27.3289799,17.9143906 L22.6715859,17.9143906 L19.2296972,12 L30.7699256,12 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default GridIcon;
