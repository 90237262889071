import React, { useRef, useEffect } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js';

export default function DougnhutChart(props) {
  const chartRef = useRef(false);

  useEffect(() => {
    chartRef.current = new Chart(chartRef.current, {
      plugins: [ChartDataLabels],
      type: props.pie ? 'pie' : 'doughnut',
      data: {
        labels: props.labels,
        datasets: [
          {
            data: props.data,
            backgroundColor: props.colors,
            borderWidth: 8
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            beforeFooter: function(tooltipItem, data) {
              const chunk_size = 5;

              if (props.descriptions)
                return props.descriptions[tooltipItem[0].index]
                  .split(' ')
                  .reduce((acc, val, idx) => {
                    if (idx % chunk_size === 0) acc.push(val);
                    else acc[Math.floor(idx / chunk_size)] += ' ' + val;
                    return acc;
                  }, [])
                  .join('\n');
            },
            label: function(tooltipItem, data) {
              return ' ' + data.labels[tooltipItem.index] + ' ' + data.datasets[0].data[tooltipItem.index] + '%';
            }
          }
        },
        legend: {
          display: props.showLegend,
          labels: { fontColor: 'white' }
        },
        plugins: {
          datalabels: {
            color: '#fff',
            font: {
              size: 16,
              weight: 900
            },
            formatter: (value, context) =>
              props.dataLabels ? props.dataLabels(value, context) : value > 4 ? value + '%' : ''
          }
        }
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showLegend, props.pie]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data.labels = props.labels;
      chartRef.current.data.datasets[0].data = props.data;
      chartRef.current.data.datasets[0].backgroundColor = props.colors;
      chartRef.current.update();
    }
  }, [props.colors, props.data, props.labels]);

  return <canvas style={props.style} ref={chartRef} />;
}
