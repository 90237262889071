import React, { useState, useEffect } from 'react';
import { TextField, Button, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { loginUser } from '../../../services/auth/actions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '../../../components/dialog/Dialog';

const useStyles = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(4)
  },
  input: {
    width: '100%'
  }
}));

const LoginDialog = props => {
  const classes = useStyles();
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const theme = useTheme();
  const { onClose, open } = props;
  const dialogProps = { onClose, open };
  const { push } = props.history;

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      if (onClose)
        onClose();
      // push('/list');
    }
  }, [onClose, push, props.auth.isAuthenticated]);

  useEffect(() => setLoginForm({ email: '', password: '' }), [props.open]);

  const onChange = e => setLoginForm({ ...loginForm, [e.target.id]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    props.loginUser(loginForm, props.history, '/');
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      title="Connexion"
      fullScreen={fullScreen}
      {...dialogProps}
      fullWidth
      maxWidth="sm"
      content={
        <form onSubmit={onSubmit}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <TextField
              type="email"
              id="email"
              variant="filled"
              onChange={onChange}
              value={loginForm.email}
              label="E-mail"
              className={classes.input}
            />
            <TextField
              type="password"
              id="password"
              variant="filled"
              onChange={onChange}
              value={loginForm.password}
              label="Mot de passe"
              className={classes.input}
              style={{ marginTop: '1em' }}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: '2em' }}
              onClick={onSubmit}
            >
              Connexion
            </Button>
            {/* <div
              style={{
                textAlign: 'center',
                marginTop: '1em',
                marginBottom: '1em'
              }}
            >
              ou
            </div>
            <SocialLogin
              service="google"
              className={classes.input}
              mode="login"
              style={{ marginTop: '0em' }}
              onClose={props.onClose}
              onSuccess={props.onUsername}
            />

            <div style={{ width: '100%', marginTop: '0.75em' }}>
              <SocialLogin onClose={props.onClose} onSuccess={props.onUsername} service="facebook" mode="login" />
            </div> */}

            {/* <div style={{ marginTop: '1em' }}>
              <Button color="blue" size="small" onClick={props.onResetPassword}>
                Mot de passe oublié ?
              </Button>
            </div>
            <div>
              Pas inscrit ? <em> </em>
              <Button size="small" onClick={props.onGoRegister}>
                S'inscrire
              </Button>
            </div> */}
            {/* <Button>Mot de passe oublié ?</Button> */}
          </div>
        </form>
      }
    />
  );
};

LoginDialog.propTypes = {
  loginUser: PropTypes.func.isRequired,
  onGoRegister: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { loginUser })(withRouter(LoginDialog));
