import { useState, useReducer, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ioClient from 'socket.io-client';
import Timer from '../../../utils/Timer';

const actions = {
  userJoined: 'user-joined',
  userLeft: 'user-left',
  start: 'start-debate',
  pause: 'pause-debate',
  startP1: 'start-phase1',
  startP2: 'start-phase2',
  startP3: 'start-phase3',
  close: 'close-debate',
  tick: 'clock-tick',
  goBack: 'go-back',
  nextSpeaker: 'next-speaker'
};

const initialState = {
  active: true,
  paused: false,
  closed: false,
  phase: 0,
  timer: 0,
  users: []
};

function reducer(state, action) {
  switch (action.type) {
    case 'user-joined':
    case 'user-left':
      return { ...state, users: action.payload };
    case 'start-debate':
      return { ...state, active: true };
    case 'pause-debate':
      return { ...state, paused: !state.paused };
    case 'start-phase1':
      return { ...state, phase: 1 };
    case 'start-phase2':
      return { ...state, phase: 2, paused: true };
    case 'start-phase3':
      return { ...state, closed: true, active: false, paused: false };
    case 'close-debate':
      return { ...state, closed: true, active: false, paused: false };
    case 'go-back':
      return { ...state, phase: 0, paused: false };
    case 'load':
      return action.payload;
    case 'clock-tick':
      return { ...state, timer: action.payload };
    case 'next-speaker':
      return { ...state, phase: state.phase + 1 };
    default:
      return state;
  }
}

/**
 * Contains the state of an evaluation session (is it live, active, ...)
 * @param {*} live
 * @param {*} roomId
 * @param {*} isCreator
 * @param {*} onTick
 * @param {*} onClose
 * @param {*} onSocketRedirect
 */
function useSessionState(live, roomId, isCreator, onTick, onClose, onSocketRedirect, isReplay, shouldUseTimer) {
  const userId = useSelector(state => state.auth.user.id);
  const username = useSelector(state => state.auth.user.name);

  const [state, dispatch] = useReducer(reducer, initialState);

  const [socket, setSocket] = useState(undefined);
  useEffect(() => {
    if (live) setSocket(ioClient.connect());
  }, [live]);

  const preOnTick = time => {
    dispatch({ type: actions.tick, payload: time });
  };

  const [timer] = useState(
    new Timer((event, time) => {
      preOnTick(time);
      if (event === 'tick') onTick(time);
    })
  );

  const clearSocket = () => {
    if (socket) {
      socket.emit('leave', { room: roomId, username });
      socket.disconnect();
    }
  };

  const loadState = newState => dispatch({ type: 'load', payload: newState });

  const updateTimer = time => {
    preOnTick(time);
  };

  const pause = () => {
    if (live && isCreator) socket.emit(actions.pause + 'e', roomId);
    else {
      dispatch({ type: actions.pause });
      if (timer.paused) timer.resume();
      else timer.pause();
    }
  };

  const start = () => {
    if (live && isCreator && !state.active) socket.emit(actions.start, roomId);
    else {
      dispatch({ type: actions.start });
    }
  };

  const goBack = () => {
    dispatch({ type: actions.goBack });
  };

  const startPhase1 = () => {
    dispatch({ type: actions.startP1 });
    if (shouldUseTimer) timer.start();
  };

  const startPhase2 = () => {
    if (live && isCreator) socket.emit(actions.startP2, roomId);
    else dispatch({ type: actions.startP2 });
  };

  const nextSpeaker = () => {
    dispatch({ type: actions.nextSpeaker });
  };

  const startPhase3 = () => dispatch({ type: actions.startP3 });

  const close = (callback, fromSocket = false) => {
    if (!fromSocket && live && isCreator) socket.emit(actions.close, { room: roomId, senderId: userId });
    else {
      timer.stop();
      dispatch(actions.close);
    }

    onClose();
  };

  const sessionActions = {
    start,
    close,
    pause,
    startPhase1,
    startPhase2,
    startPhase3,
    goBack,
    nextSpeaker,
    updateTimer
  };
  return [state, sessionActions, clearSocket, loadState];
}

export default useSessionState;
