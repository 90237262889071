import React, { useEffect } from 'react';
import Home from './pages/Home';
import Register from './pages/Auth/Register';
import Login from './pages/Auth/Login';
import ControlCreation from './pages/ControlCreation';
import PrivateRoute from './components/PrivateRoute';
import { Route, Switch } from 'react-router-dom';
import { cleanErrors } from './services/error/actions';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import Admin from './pages/Admin';
import JoinGroup from './pages/JoinGroup';
import FolderCreation from './pages/FolderCreation';
import GroupsHome from './pages/GroupsHome';
import TeacherHub from './pages/TeacherHub/TeacherHub';
import SessionCreationSelect from './pages/SessionCreationSelect';
import { HUB_MODES } from './pages/TeacherHub/constants';
import EvaluationRoom from './pages/EvaluationRoom';
import PreparationResults from './pages/PreparationResults';
import GridsList from './pages/GridsList/GridsList';
import PreparationDeposit from './pages/PreparationDeposit';
import SessionClone from './pages/SessionClone';
import ArgumentsList from './pages/GridsList/ArgumentsList';
import GridsCreationList from './pages/GridsList/GridCreation';
import TutorialsAdmin from './pages/TutorialsAdmin/TutorialsAdmin';
import TutorialsList from './pages/TutorialsList';
import TutorialViewer from './pages/TutorialViewer';

// Setup routes
function Routes({ errors, cleanErrors, enqueueSnackbar }) {
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      enqueueSnackbar(errors[Object.keys(errors)[0]], { variant: 'error' });
    }
  }, [errors, enqueueSnackbar]);

  return (
    <Switch>
      <Route exact path="/" component={GroupsHome} />
      <Route exact path="/login/:token" component={Home} />
      <Route exact path="/register/:token" component={Home} />
      {/* <PrivateRoute exact path="/list" component={props => <TeacherHub mode={HUB_MODES.TEACHER_HUB} {...props} />} /> */}
      <Route exact path="/register" component={Register} />
      {process.env.NODE_ENV === 'development' && <Route exact path="/login" component={Login} />}
      <Route path="/group/:token" component={JoinGroup} />
      <PrivateRoute exact path="/list/" component={props => <TeacherHub mode={HUB_MODES.TEACHER_HUB} {...props} />} />
      <PrivateRoute
        exact
        path="/list/:token"
        component={props => <TeacherHub mode={HUB_MODES.TEACHER_HUB} {...props} />}
      />
      <PrivateRoute path="/join/:id" component={EvaluationRoom} />
      <PrivateRoute
        exact
        path="/debate/:id/results"
        component={props => <PreparationResults mode={HUB_MODES.TEACHER_FOLDER_TO_DO} {...props} />}
      />
      <PrivateRoute
        exact
        path="/debate/:id/results/:forUser"
        component={props => <PreparationResults mode={HUB_MODES.TEACHER_FOLDER_TO_EVAL} {...props} />}
      />
      <PrivateRoute exact path="/home" component={Home} />
      <PrivateRoute exact path="/sessionCreation" component={SessionCreationSelect} />
      <PrivateRoute exact path="/createControl" component={props => <ControlCreation {...props} mode="creation" />} />
      <PrivateRoute exact path="/editControl" component={props => <ControlCreation {...props} mode="edition" />} />
      <PrivateRoute exact path="/grilles" component={GridsList} />
      <PrivateRoute exact path="/grilles/arguments" component={ArgumentsList} />
      <PrivateRoute exact path="/grilles/creation" component={GridsCreationList} />
      <PrivateRoute exact admin path="/admin" component={Admin} />
      <PrivateRoute exact path="/create_presta" component={props => <FolderCreation mode="to_eval" {...props} />} />
      <PrivateRoute exact path="/create_folder" component={props => <FolderCreation mode="to_do" {...props} />} />
      <PrivateRoute exact path="/create_inperson" component={props => <FolderCreation mode="in_person" {...props} />} />
      <PrivateRoute
        exact
        path="/folders/:token"
        component={props => <TeacherHub mode={HUB_MODES.TEACHER_FOLDER_TO_DO} {...props} />}
      />
      <PrivateRoute
        exact
        path="/presentiel/:token"
        component={props => <TeacherHub mode={HUB_MODES.TEACHER_IN_PERSON} {...props} />}
      />
      <PrivateRoute
        exact
        path="/evals/:token"
        component={props => <TeacherHub mode={HUB_MODES.TEACHER_FOLDER_TO_EVAL} {...props} />}
      />
      <PrivateRoute exact path="/preparation/:token" component={PreparationDeposit} />
      <PrivateRoute exact path="/sessionCreation/copy" component={SessionClone} />
      <PrivateRoute exact path="/tutorialsAdmin" component={TutorialsAdmin} />
      <PrivateRoute exact path="/tutorials" component={TutorialsList} />
      <PrivateRoute exact path="/tutorials/:token" component={TutorialViewer} />
      {/* <PrivateRoute exact path="/students/:username/results/:id/:group" component={DebateResults} /> */}
      <PrivateRoute exact path="/replay/:id" component={props => <EvaluationRoom mode="replay" {...props} />} />
    </Switch>
  );
}

const mapStateToProps = state => ({
  errors: state.errors
});

export default connect(mapStateToProps, { cleanErrors })(withSnackbar(Routes));
