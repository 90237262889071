/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Dialog from '../../../components/dialog/Dialog';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const COUNTDOWN_TIME = 5;

// Step 0: show an alert to the student (not teacher) to ask him if he want to evaluate
// Step 1: Show the timer start (for teacher & student)
function StartDialog({ open, onClose, onStart, onCancel, isTeacher }) {
  const [step, setStep] = React.useState(0);
  const [timer, setTimer] = React.useState(COUNTDOWN_TIME);

  const onSuccess = () => {
    onClose();
    onStart();
  };

  React.useEffect(() => {
    if (isTeacher) setStep(1);
    else setStep(0);
  }, [isTeacher]);

  React.useEffect(() => {
    let interval;
    if (open && step === 1) {
      setTimer(5);
      interval = setInterval(() => {
        setTimer(timer => timer - 1);

        if (timer <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [open, step]);

  React.useEffect(() => {
    if (timer <= 0) {
      onSuccess();
    }
  }, [timer]);

  return (
    <Dialog
      open={open}
      onClose={onSuccess}
      title={step === 0 ? '' : ' '}
      content={
        <div className="start-dialog">
          {step === 1 && (
            <>
              <div className="start-dialog-progress">
                <CircularProgressbar
                  value={timer}
                  maxValue={COUNTDOWN_TIME}
                  text={'0' + timer}
                  counterClockwise
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    pathColor: '#A2D146',
                    textColor: '#9B9B9B',
                    trailColor: '#F4F4F4',
                    textSize: '30px',
                    textFontFamily: 'TJ Evolette A Basic'
                  })}
                />
              </div>
              <div>
                <h2>Préparez-vous...</h2>
              </div>
              <div className="subtitle">La prestation ne pourra être consultée qu'une seule fois.</div>
              <button className="btn btn-primary p-2" onClick={onCancel}>
                ANNULER
              </button>
            </>
          )}

          {step === 0 && (
            <>
              <h3>Commencer</h3>
              <div className="subtitle">
                La prestation ne pourra être consultée qu'une seule fois.
                <br />
                Assurez-vous d'avoir assez de temps pour aller jusqu'au
                <br />
                bout de l'évaluation.
              </div>
              <div className="d-flex flex-wrap align-items-center" style={{ gap: 10 }}>
                <button className="btn btn-outline fullwidth" style={{ flex: 1 }} onClick={onCancel}>
                  Annuler
                </button>
                <button className="btn btn-primary fullwidth" style={{ flex: 1 }} onClick={() => setStep(1)}>
                  Commencer
                </button>
              </div>
            </>
          )}
        </div>
      }
    />
  );
}

export default StartDialog;
