import React from 'react';

const TitleSection = ({title, label}) => (
  <div className="pr-title">
    {title.length > 0 && <h2>{title}</h2>}
    <h3 className="mt-4">{label}</h3>
    <hr />
  </div>
)

export default TitleSection;