import React from 'react';
import ReactPlayer from 'react-player';
import { MATCH_URLS } from '../../../../utils/video_patterns';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import IconButton from '../../../../components/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import AddCommentIcon from '@material-ui/icons/AddComment';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';
import { Collapse, makeStyles } from '@material-ui/core';
import { linesToHTML } from '../../../../utils';
import TextInput from '../../../../components/TextInput';
import { hexagonColors } from '../../../../styles/palette';
import { format_time } from '../../../../utils/format_time';
import useInterval from '../../../../hooks/useInterval';
import MediaRecorder from '../../../../components/MediaRecorder';
import { useRef } from 'react';
import { useState, useEffect } from 'react';

const EvVideo = React.forwardRef(
  (
    {
      url,
      sessionState,
      sessionActions,
      shouldPlay,
      shouldShowTimer,
      recordingMode,
      isReplay,
      isUploading,
      prestationMode
    },
    ref
  ) => {
    const canPlayContent = ReactPlayer.canPlay(url);
    const mediaRecorderRef = useRef();
    const [timer, setTimer] = useState(0);

    useInterval(() => {
      if (shouldPlay) {
        setTimer(t => t + 1);
      }
    }, 1000);

    const shouldRecord = !isReplay && (recordingMode === 'video' || recordingMode === 'audio'); // Only for "in_person" exercises
    const isAudio = MATCH_URLS.AUDIO_EXTENSIONS.test(url) || prestationMode === 'audio' || recordingMode === 'audio';
    const { phase } = sessionState;

    if (shouldShowTimer) {
      ref.current = {
        getCurrentTime: () => timer
      };
    } else if (shouldRecord && mediaRecorderRef.current) {
      ref.current = mediaRecorderRef.current;
    }

    return shouldRecord ? (
      <>
        {!isUploading && recordingMode === 'audio' && sessionState.phase !== 1 && (
          <div className="evr-audio">
            <VolumeUpIcon fontSize="inherit" />
          </div>
        )}

        {isUploading && sessionState.phase > 1 && (
          <div className="evr-videoframe evr-uploading">
            <div className="evr-uploading-icon">
              <CachedIcon fontSize="inherit" />
            </div>
            <div className="evr-uploading-text">Chargement vidéo en cours...</div>
          </div>
        )}

        <MediaRecorder
          shouldTick={shouldPlay}
          mode={recordingMode}
          ref={mediaRecorderRef}
          style={{ display: isUploading ? 'none' : '' }}
        />
      </>
    ) : shouldShowTimer ? (
      <div className="evr-videoframe evr-timer">
        <div>{format_time(timer * 1000)}</div>
      </div>
    ) : !canPlayContent ? (
      ''
    ) : (
      <div
        className="evr-videoframe"
        style={{ paddingTop: isAudio ? 0 : '56.25%', position: isAudio ? '' : 'relative' }}
      >
        {isAudio && (
          <div className="evr-audio">
            <VolumeUpIcon fontSize="inherit" />
          </div>
        )}
        <ReactPlayer
          url={url}
          volume={0.5}
          // width={window.innerWidth > 650 ? window.innerWidth / 3.5 : "100%"}
          height={isAudio ? 54 : '100%'}
          width="100%"
          style={{
            top: 0,
            left: 0,
            pointerEvents: phase > 0 ? '' : 'none',
            position: isAudio ? '' : 'absolute'
          }}
          controls
          playsinline
          ref={ref}
          playing={shouldPlay}
          progressInterval={900}
          onProgress={({ playedSeconds }) => sessionActions.updateTimer(Math.round(playedSeconds))}
        />
      </div>
    );
  }
);

const EvSidebarCollapse = ({ title, children, className, collapseClasses, controlledState, ...props }) => {
  const [collapsed, setCollapsed] = React.useState(false);

  useEffect(() => {
    if (controlledState !== undefined) {
      setCollapsed(controlledState);
    }
  }, [controlledState]);

  const toggleCollapse = () => setCollapsed(c => !c);

  return (
    <div className={className || 'ml-4 mr-4'} id={props.id}>
      <div className={'d-flex align-center' + (className ? ' ml-4' : '')}>
        <div className="d-flex ai-center">
          <button className="btn btn-icon btn-text d-flex subtitle small btn-no-effect" onClick={toggleCollapse}>
            {title} {!collapsed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </button>
        </div>
      </div>
      <Collapse classes={collapseClasses} in={!collapsed}>
        {children}
      </Collapse>
    </div>
  );
};

const EvVotesHistory = React.forwardRef(
  ({ isTeacher, votes, setVotes, isReplay, replayData, sessionState, sessionActions }, ref) => {
    // In a replay, the votes that should be blinking
    const timer = sessionState.timer;
    const votesInWindow = replayData ? replayData.filter(v => timer - v.time >= -1.5 && timer - v.time <= 1.5) : [];

    /// STATE
    const [editedComment, setEditedComment] = React.useState({ idx: -1, value: '' });
    const resetComment = () => setEditedComment({ idx: -1, value: '' });

    // Used to persist the command in evaluation room
    ref.current = {
      getEditedComment: () => editedComment,
      resetEditedComment: () => resetComment()
    };

    /// HANDLERS
    const onActionButtonClick = (idx, action) => () => {
      const previouslyEdited = editedComment;

      // The "Validate" button was clicked
      if (editedComment.idx === idx) {
        resetComment();
        votes[idx].comment = '';
      } else {
        // The "Start Editing button was clicked"
        if (editedComment.idx !== -1) votes[editedComment.idx].comment = editedComment.value;
        setVotes([...votes]);

        // Start editing this comment
        setEditedComment({ idx, value: votes[idx].comment });
      }

      if (!(previouslyEdited.idx !== -1 && action === 'new') || previouslyEdited.idx === idx) sessionActions.pause();
    };

    // Called when pressing Enter or clicking on the checkmark
    const onValidateComment = idx => () => {
      votes[idx].comment = editedComment.value;
      setVotes([...votes]);
      resetComment();
      sessionActions.pause();
    };

    // Called when clicking on the bin, remove the entire argument
    const onDeleteArgument = idx => () => {
      const newVotes = votes
        .map((v, i) => ({ ...v, rank_idx: i < idx ? v.rank_idx - 1 : v.rank_idx }))
        .filter((_, i) => i !== idx);

      setVotes([...newVotes]);
    };

    // Called when typing in the comment's text field
    const onUpdateCommentValue = e => {
      e.persist();
      if (e.target && e.target.value.length <= 140) setEditedComment(c => ({ ...c, value: e.target.value }));
    };

    // Called when pressing an action button when editing a comment in the text field
    const onUpdateCommentAction = idx => e => {
      if (e.keyCode === 13) {
        // Enter key
        onValidateComment(idx)();
      } else if (e.keyCode === 27) {
        // Escape key
        resetComment();
        sessionActions.pause();
      }
    };

    return (
      <div className="evr-histo" style={{ marginTop: isReplay ? 5 : -5 }}>
        {votes.map((v, idx) => {
          return (
            <div
              key={'evr-hr' + idx}
              className={
                'evr-histo-row-wrap' + (votesInWindow.some(vw => vw.time === v.time) ? ' evr-histo-flashing' : '')
              }
            >
              <div className="evr-histo-row" key={'histo-' + idx} style={{ marginBottom: v.comment ? 0 : 4 }}>
                <div className="evr-histo-pastille" style={{ backgroundColor: hexagonColors[v.pointsGroup] }} />
                <div className="evr-histo-time subtitle">{format_time(v.time * 1000)}</div>
                <div style={{ color: hexagonColors[v.pointsGroup] }} className="evr-histo-name">
                  <div className="evr-histo-name-title">{v.name}</div>
                  {!v.comment && isTeacher && !isReplay && (
                    <IconButton onClick={onActionButtonClick(idx, 'new')} title="Ajouter une remarque">
                      <AddCommentIcon fontSize="inherit" />
                    </IconButton>
                  )}
                  {!isReplay && editedComment.idx !== idx && (
                    <IconButton onClick={onDeleteArgument(idx)} title="Supprimer le critère" style={{ marginLeft: 10 }}>
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </div>
              </div>

              <div className="evr-histo-comment" style={{ marginBottom: v.comment ? 10 : 0 }}>
                {editedComment.idx === idx ? (
                  <TextInput
                    multiline
                    autoFocus
                    value={editedComment.value}
                    style={{ backgroundColor: 'white' }}
                    onChange={onUpdateCommentValue}
                    onKeyDown={onUpdateCommentAction(idx)}
                  />
                ) : (
                  <div className="text-left">{v.comment}</div>
                )}
                {isTeacher && !isReplay && (
                  <div>
                    {v.comment && (
                      <IconButton
                        onClick={onActionButtonClick(idx)}
                        title={
                          !isReplay && editedComment.idx === idx ? 'Supprimer la remarque' : 'Modifier la remarque'
                        }
                      >
                        {!isReplay &&
                          (editedComment.idx === idx ? (
                            <DeleteIcon fontSize="inherit" />
                          ) : (
                            <EditIcon fontSize="inherit" />
                          ))}
                      </IconButton>
                    )}
                    {editedComment.idx === idx && (
                      <IconButton style={{ marginTop: 5 }} title="Valider" onClick={onValidateComment(idx)}>
                        <CheckIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </div>
                )}
              </div>

              <div className="evr-row-sep" />
            </div>
          );
        })}

        {isReplay && (
          <div className="evr-histo-mask">
            <div className="evr-histo-mask-fixed"></div>
          </div>
        )}
      </div>
    );
  }
);

const EvaluationSidebar = React.forwardRef(
  (
    {
      session,
      sessionState,
      sessionActions,
      mode,
      isTeacher,
      votes,
      setVotes,
      replayData,
      isUploading,
      prestationMode
    },
    ref
  ) => {
    const { active, paused, phase } = sessionState;
    const { diffusionLink: url, description, folderMode, recordingMode, notes } = session;
    const { videoPlayerRef, historyRef, appreciationRef } = ref;

    const isReplay = mode === 'replay';
    const shouldShowTimer = folderMode === 'in_person' && (recordingMode === 'none' || recordingMode === 'mobile');

    const [appreciation, setAppreciation] = useState(notes);
    const [appreciationCollapsed, setAppreciationCollapsed] = useState(!isReplay);

    const shouldPlayVideo = active && !paused && phase !== 0;
    const shouldShowAppreciation =
      session.folderMode !== 'to_eval' && ((!isReplay && isTeacher) || (isReplay && notes));

    const defaultProps = { sessionState, sessionActions, isTeacher, isReplay, isUploading, prestationMode };

    // Classes for history's collapse
    const useStyles = makeStyles({
      wrapperInner: {
        display: 'flex'
      },
      wrapper: {
        height: '100%'
      },
      root: { height: '100%' }
    });
    const histoCollapseClasses = useStyles();

    // Hide appreciation in evaluation mode, show it in phase 2.
    useEffect(() => {
      if (!isReplay) {
        setAppreciationCollapsed(phase < 2);
      }
    }, [phase]);

    return (
      <div className="content-sidebar" style={{ margin: 0, borderRadius: 0 }}>
        <EvVideo
          {...defaultProps}
          shouldPlay={shouldPlayVideo}
          url={url}
          ref={videoPlayerRef}
          shouldShowTimer={shouldShowTimer}
          recordingMode={recordingMode}
        />

        <div className="evr-content">
          {description && (
            <EvSidebarCollapse
              title="Consigne"
              children={<div className="evr-col-shrink">{linesToHTML(description)}</div>}
            />
          )}

          {shouldShowAppreciation && (
            <EvSidebarCollapse
              id="gt-appreciation-generale"
              title={`Commentaire ${isTeacher ? 'appréciation générale' : 'de votre professeur(e)'}`}
              controlledState={appreciationCollapsed}
              children={
                <TextInput
                  inputRef={appreciationRef}
                  multiline
                  style={{ backgroundColor: 'white', fontSize: 12 }}
                  placeholder="Texte libre"
                  rows={4}
                  onChange={e => setAppreciation(e.target.value)}
                  value={appreciation}
                  disabled={mode === 'replay'}
                />
              }
            />
          )}

          <EvSidebarCollapse
            title="Historique"
            className="evr-histo-mobile mt-2"
            collapseClasses={histoCollapseClasses}
            children={
              <EvVotesHistory
                {...defaultProps}
                votes={votes}
                setVotes={setVotes}
                replayData={replayData}
                ref={historyRef}
              />
            }
          />
        </div>
      </div>
    );
  }
);

export default EvaluationSidebar;
