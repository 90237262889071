import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import NavigationBar from '../../../components/NavigationBar';
import useApi from '../../../hooks/api/useApi';
import withTutorial from '../../../hooks/withTutorial';
import { GET_ARGUMENTS } from '../../../services/api_routes';
import { defaultGlobalAppreciationLabels, tutorialScreens } from '../../../utils/texts';
import '../GridsList.scss';
import CreationList from './CreationList';
import GridCreationInterface from './GridCreationInterface';

const emptyHexagon = {
  _id: 1234,
  name: 'Grille vierge',
  argumentGroups: new Array(6).fill().map((grp, grpIdx) => ({
    arguments: new Array(3).fill().map((x, idx) => ({ _id: grp + '-' + grpIdx + '-' + idx, name: ' ', empty: true })),
    groupsForArguments: new Array(3).fill(5)
  })),
  buzzerArgument: { _id: 1, name: ' ', empty: true },
  groupForBuzzer: 5,
  phase: 1,
  precision: 2,
  globalAppreciationLabels: defaultGlobalAppreciationLabels
};

function GridsCreationList(props) {
  const location = useLocation();
  const history = useHistory();
  const [argumentsList, , setArgumentsList] = useApi(GET_ARGUMENTS);

  const [editedGrid, setEditedGrid] = React.useState(null);

  if (!location.state || !location.state.grids) {
    history.push('/grilles');
  }

  const grids = [...location.state.grids, emptyHexagon];
  const onGridSelection = gridId => e => {
    e.preventDefault();
    const grid_idx = grids.findIndex(g => g._id === gridId);
    setEditedGrid(grid_idx);
  };

  React.useEffect(() => {
    if (location.state.editedGrid !== undefined) setEditedGrid(location.state.editedGrid);
  }, [location.state.editedGrid]);

  React.useEffect(() => {
    console.log('Starting guided tour');
    setTimeout(() => props.startGuidedTour(), 300);
  }, []);

  const isEditing = location.state.editedGrid !== undefined;
  const shouldResetGrid = !isEditing && editedGrid !== null;
  const onGoBack = () => (shouldResetGrid ? setEditedGrid(null) : history.goBack());

  return editedGrid !== null ? (
    <div className="content-wrapper" style={{ flexDirection: 'column', flexWrap: 'nowrap' }}>
      <GridCreationInterface
        grid={grids[editedGrid]}
        argumentsList={argumentsList}
        setArgumentsList={setArgumentsList}
        onGoBack={onGoBack}
        isEditing={isEditing}
      />
    </div>
  ) : (
    <>
      <NavigationBar title="Nouvelle Grille" onBack={onGoBack} />
      <div className="body-wrapper">
        <div className="content-wrapper" style={{ flexDirection: 'column', flexWrap: 'nowrap' }}>
          <CreationList grids={grids} onGridClick={onGridSelection} />
        </div>
      </div>
    </>
  );
}

export default withTutorial(GridsCreationList, tutorialScreens.GRID_CREATION);
