/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { logoutUser, setCurrentGroup, setExercisesCount } from '../../../services/auth/actions';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import CreateIcon from '@material-ui/icons/Create';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import HelpIcon from '@material-ui/icons/Help';
import { changeTheme } from '../../../services/settings/actions';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import withAuth from '../../../hooks/withAuth';
import { Avatar } from '@material-ui/core';
import './Navbar.scss';
import GroupIcon from '@material-ui/icons/Group';
import { NavbarContext } from '../../../hooks/context/NavbarProvider';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import logoProjetVoltaire from '../../../styles/assets/logo-projet-voltaire-nav.png';
import PersonIcon from '@material-ui/icons/Person';

const NavBar = ({
  setCurrentGroup,
  auth,
  history,
  location,
  errors,
  logoutUser,
  cleanErrors,
  changeTheme,
  theme,
  ...props
}) => {
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);

  const { isAuthenticated, user } = auth;

  const beforeAction = action => () => {
    if (openDrawer) setOpenDrawer(false);
    action();
  };

  const goTo = path => e => {
    setOpenDrawer(false);

    if (e.button === 1) {
      let win = window.open(path, '_blank');
      win.focus();
    } else {
      history.push(path);
    }
  };

  const logout = () => {
    const redirectUrl = localStorage.getItem('redirectUrl');
    if (redirectUrl !== null) {
      window.location.href = redirectUrl;
    } else {
      logoutUser(history);
    }
  };

  const getButton = (forDrawer, text, onClick, icon, className = '') =>
    forDrawer ? (
      <ListItem button key={text} onClick={onClick} title={text} onAuxClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    ) : (
      <Button size="small" onClick={onClick} onAuxClick={onClick} title={text} className={className}>
        {text}
      </Button>
    );

  const navButtons = forDrawer =>
    isAuthenticated ? (
      <>
        {forDrawer ? getButton(forDrawer, 'Accueil', goTo('/list'), <HomeIcon />) : ''}
        {getButton(forDrawer, 'Déconnexion', beforeAction(logout), <ExitToAppIcon />)}
        {getButton(forDrawer, 'Encyclopédie', goTo('/encyclopedia'), <MenuBookIcon />, 'tour-encyclopedia')}
        {forDrawer &&
          getButton(
            forDrawer,
            user.name,
            goTo('/profile'),
            <Avatar src={user.avatarURL} style={{ width: 24, height: 24 }} />
          )}
        {forDrawer && getButton(forDrawer, "Création d'hexagones", goTo('/createControl'), <CreateIcon />)}
        {forDrawer ? getButton(forDrawer, 'Foire aux Questions', beforeAction(props.openHelp), <HelpIcon />) : ''}
        {forDrawer
          ? getButton(forDrawer, 'Thème ' + (theme === 'dark' ? 'Clair' : 'Sombre'), changeTheme, <Brightness4Icon />)
          : ''}
      </>
    ) : (
      <>
        {getButton(forDrawer, "S'inscrire", beforeAction(props.openRegister), <ArrowForwardIcon />)}
        {getButton(forDrawer, 'Connexion', beforeAction(props.openLogin), <ArrowForwardIcon />)}
      </>
    );

  // console.log('HERE', classes.buttons);
  // const isSM = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const onChangeGroup = () => {
    setCurrentGroup(null);
    dispatch(setExercisesCount(0));
    goTo('/')({ button: 0 });
  };

  React.useEffect(() => {
    if (!isAuthenticated) {
      if (process.env.NODE_ENV === 'development' || window.location.hostname.includes('lucasrlt.com')) {
        goTo('/login')({ button: 0 });
      } else {
        const redirectUrl = localStorage.getItem('redirectUrl');
        if (redirectUrl === null) {
          window.location.href = 'https://www.projet-voltaire.fr/';
        } else {
          window.location.href = redirectUrl;
        }
      }
    }

    if (
      isAuthenticated &&
      !auth.currentGroup &&
      !location.pathname.startsWith('/grilles') &&
      !location.pathname.startsWith('/tutorials')
    ) {
      goTo('/')({ button: 0 });
    }
  }, [isAuthenticated]);

  const navbarContext = React.useContext(NavbarContext);

  return (
    <>
      <header className="header">
        {!navbarContext.shouldHide ? (
          <div className="header-container">
            <div className="header-title">
              <Link to={isAuthenticated ? (user.teacher ? '/home' : '/list') : '/'} className="uppercase">
                <img alt="Logo Projet Voltaire" src={logoProjetVoltaire} /> Mon oral
              </Link>
            </div>
            <div style={{ flexGrow: 1 }} />

            {isAuthenticated ? (
              <>
                {auth.user.teacher && auth.user.hasDoneOnboarding && (
                  <>
                    <Link
                      to={!auth.currentGroup ? '/' : '/home'}
                      className={location.pathname.startsWith('/home') ? 'header-active' : ''}
                    >
                      <HomeIcon />
                    </Link>
                    {!auth.currentGroup ||
                      (!auth.currentGroup.isDemo && (
                        <Link
                          to={!auth.currentGroup ? '/' : '/sessionCreation'}
                          className={location.pathname.startsWith('/sessionCreation') ? 'header-active' : ''}
                        >
                          CRÉER
                        </Link>
                      ))}
                    {window.location.pathname !== '/' && auth.exercisesCount > 0 && (
                      <Link
                        to={!auth.currentGroup ? '/' : '/list/' + auth.currentGroup.token}
                        className={location.pathname.startsWith('/list') ? 'header-active' : ''}
                      >
                        CORRIGER
                      </Link>
                    )}
                    {((user.isPCL && auth.currentGroup) || !user.isPCL) && (
                      <Link to="/grilles" className={location.pathname.startsWith('/grilles') ? 'header-active' : ''}>
                        GRILLES
                      </Link>
                    )}
                    {auth.user.adminTutorials && (
                      <Link
                        to="/tutorialsAdmin"
                        className={location.pathname.startsWith('/tutorialsAdmin') ? 'header-active' : ''}
                      >
                        TUTORIELS
                      </Link>
                    )}
                  </>
                )}
                <a className="d-flex align-items-center justify-content-center" style={{ gap: 10 }}>
                  <div>
                    <PersonIcon />
                  </div>
                  <div>{auth.user.name}</div>
                </a>

                <div className="leave-btn">
                  <a onClick={logout}>SORTIR</a>
                </div>
              </>
            ) : (
              <>
                <a onClick={goTo('/login')}>Connexion</a>
              </>
            )}
          </div>
        ) : (
          <div className="popover-navbar">
            <div className="popover-navbar-title">{navbarContext.title}</div>
            <div className="popover-navbar-subtitle">{navbarContext.subtitle}</div>
            <div>
              <button
                className="btn btn-icon btn-text"
                style={{ color: 'white' }}
                onClick={() => {
                  navbarContext.onGoBack();
                }}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        )}
      </header>

      {auth.user.teacher && auth.currentGroup && !navbarContext.shouldHide && (
        <div className="group-header">
          <div className="group-header-name">
            <GroupIcon className="primary" />
            <span>{auth.currentGroup.name}</span>
          </div>
          <div className="leave-btn" id="group-change">
            <a onClick={onChangeGroup}>Changer de groupe</a>
          </div>
        </div>
      )}

      <Drawer anchor="left" onClose={() => setOpenDrawer(false)} open={openDrawer}>
        <List style={{ width: 250 }}>{navButtons(true)}</List>
      </Drawer>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  theme: state.settings.theme
});

export default connect(mapStateToProps, { logoutUser, changeTheme, setCurrentGroup })(withAuth(NavBar));
