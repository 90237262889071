import { useState } from 'react';

const useForm = (initialData = {}) => {
  const [inputs, setInputs] = useState(initialData);

  const handleInputChange = event => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const updateInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
  };

  const updateInputs = newInputs => {
    setInputs({ ...inputs, ...newInputs });
  };

  return [inputs, handleInputChange, updateInput, updateInputs];
};

export default useForm;
