/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef } from 'react';
import { isMobile } from 'react-device-detect';
import useApiPrepare from '../../../../hooks/api/useApiPrepare';
import { POST_VOTE } from '../../../../services/api_routes';
import HexagonsGrid from './HexagonsGrid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '../../../../components/IconButton';

/**
 * Renders the hexagons controls in the debate room for each debatteer.
 * Handles any event related to the hexagons.
 */
const HexagonsSection = forwardRef((props, ref) => {
  const { roomId, phase, onStart, onValidateP1, onFinish } = props;
  const { evaluationControlInterviewer, evaluationControl, evaluationControlP2 } = props.hexagons;
  const { evaluationSession, teams, mode } = props.debate;
  const { active } = props.options;

  const isInterview = mode === 'interview';
  const usesLegacyP2 =
    !evaluationControl.globalAppreciationLabels || evaluationControl.globalAppreciationLabels.length === 0;

  const [, , submitVotes] = useApiPrepare(POST_VOTE, {}, 'post', true);
  const [currPage, setCurrPage] = useState(0);
  const [clickedCell, setClickedCell] = useState({ _id: '' });

  // Stores user's selection in phase 2 for each speaker.
  // It is composed of an array of size [speakerNbs] of size 12 arrays storing the current state for each p2 hexagon (phase 2 12 options)
  const [phase2Selection, setPhase2Selection] = useState(props.replayP2Selection);
  const [, setLocalTimer] = useState(0);

  // The user can only vote once every second. This variable stores whether or not he can vote.
  const [canVote, setCanVote] = useState(true);

  // Init phase 2 selection according to the speakers count
  useEffect(() => {
    if (!props.replay) {
      let speakersCount = teams.reduce((acc, team) => (acc += team.speakers.length), 0);
      const p2selectionInit = [];
      for (let i = 0; i < speakersCount; i++) {
        p2selectionInit.push(new Array(usesLegacyP2 ? 12 : evaluationControl.globalAppreciationLabels.length).fill(0));
      }

      setPhase2Selection([...p2selectionInit]);
    }
  }, [teams]);

  const speakersNames = teams.reduce((acc, team) => acc.concat(team.speakers.map(speaker => speaker)), []);

  // Kept for legacy reasong when developing v2.0b.
  // Used when votes for phase 2 were still handled as a standard vote type an linked to
  // an actual argument object in database and not just a label like today.
  const legacy_getP2votes = () =>
    phase2Selection.reduce(
      (acc, votes, speakerId) =>
        acc.concat(
          votes.map((vote, voteId) => ({
            speaker: speakersNames[speakerId],
            argumentId: evaluationControlP2.argumentGroups[voteId].arguments[0],
            debateCode: roomId,
            phase: Math.min(phase, 2),
            weight: vote
          }))
        ),
      []
    );

  // Return the vote objects for p2 vores
  const getP2votes = () =>
    phase2Selection.map((weights, speakerId) => ({ speaker: speakersNames[speakerId], weights }));

  const submitP2 = async (callback, votes, notes) => {
    if (props.replay) return;

    const posted_votes = {
      p1: votes,
      p2: usesLegacyP2 ? legacy_getP2votes() : getP2votes()
    };

    const post_votes = await submitVotes({ ...posted_votes, notes, debateCode: roomId });
    if (post_votes.status === 200) {
      if (callback) callback();
    }
  };

  ref.current = {
    /**
     * This function is called by the parent component to submit phase 2.
     * Loops through phase 2 selection and submits each vote.
     */
    ...ref.current,
    submitPhase2Votes: submitP2
  };

  useEffect(() => {
    if (!canVote)
      setTimeout(() => {
        if (!props.commenting) {
          setCanVote(true);
          setClickedCell({ _id: '' });
        }
      }, 1800);
  }, [canVote]);

  /**
   * Called when an hexagon's cell is clicked. Submits the vote or store current state for phase 2
   * @param {*} speaker The speaker concerned by the vote
   * @param {*} argument Argument clicked
   * @param {*} id Cell's id in hexagon
   * @param {*} speakerId Speaker's id in hexagon
   * @param {*} teamId Team's id in hexagon
   * @param {*} weight Useful for phase 2
   */
  const onCellClick = (speaker, argument, idx, speakerId, teamId, weight = 1) => {
    if (argument.isEmpty || props.replay) return;
    if (phase === 1) {
      setCanVote(canVote => {
        if (canVote) {
          const voteOptions = {
            speaker: teams[0].speakers[0],
            argumentId: phase === 1 ? argument._id : argument,
            debateCode: roomId,
            phase,
            weight,
            evaluationSessionId: evaluationSession,
            time: props.getTime(),
            pointsGroup: argument.pointsGroup
          };

          // submitVote(voteOptions);
          setClickedCell({ _id: argument._id, pointsGroup: argument.pointsGroup, idx });

          if (props.onVote) props.onVote({ ...argument, ...voteOptions });
        }

        return false;
      });
    }

    if (phase >= 2) {
      // const selectionId =
      // teamId > 0
      // ? teams.slice(0, teamId).reduce((acc, team) => acc + team.speakers.length, 0) + speakerId
      // : speakerId;

      const selectionId = phase - 2;

      if (phase2Selection[selectionId][argument] === idx) phase2Selection[selectionId][argument] = 0;
      else phase2Selection[selectionId][argument] = idx;

      setPhase2Selection([...phase2Selection]);
    }
  };

  useEffect(() => {
    setLocalTimer(props.timer);
  }, [props.timer]);

  /**
   * Hexagons must be submitted to HexagonsGrid as an array of props for the Hexagon component.
   * Computes and stores the props used for rendering hexagons.
   */
  const hexagons = React.useMemo(
    () =>
      (phase < 2 ? [teams[0]] : teams).reduce(
        (acc, team, teamId) =>
          acc.concat(
            team.speakers.map((speaker, n) => {
              return {
                cellNumber:
                  isInterview && team.interviewers[n]
                    ? evaluationControlInterviewer.argumentGroups[0].arguments.length * 6
                    : evaluationControl.argumentGroups[0].arguments.length * 6,
                buzzerLabel: phase < 2 ? '' : props.debate.folderMode !== 'to_eval' ? props.debate.userName : speaker,
                evaluationControl:
                  phase < 2 ? evaluationControl : usesLegacyP2 ? evaluationControlP2 : evaluationControl,
                picture: team.pic,
                active: active,
                phase: phase,
                rp: phase,
                phase2Selection:
                  phase2Selection[
                    teamId > 0 ? teams.slice(0, teamId).reduce((acc, team) => acc + team.speakers.length, 0) + n : n
                  ],

                speakerId: n,
                teamId: teamId
              };
            })
          ),
        []
      ),
    [teams, phase, currPage]
  );

  const onPageChange = (page, pagesLength) => {
    setCurrPage(page);

    if (props.onPageChange) props.onPageChange(page, pagesLength);
  };

  const InformationBar = () => {
    const numPhases = speakersNames.length + 1;

    const onBubbleClick = idx => () => {
      if (props.replay && phase > 0) {
        if (idx === 1) onFinish();
        else props.onGoBack();
      }
    };

    const PhaseBubbles = () => (
      <div className="hex-phase-bubbles mt-4">
        {new Array(numPhases).fill().map((_, idx) => (
          <div
            key={'pbu' + idx}
            onClick={onBubbleClick(idx)}
            className={
              (phase === idx + 1 ? 'hex-bubble-current' : '') + (props.replay && phase > 0 ? ' clickable' : '')
            }
          >
            {idx + 1}
          </div>
        ))}
      </div>
    );

    const InfoP1 = (
      <>
        <PhaseBubbles />
        <div className="uppercase">Analyse continue</div>
      </>
    );

    const InfoP0 = props.replay ? (
      InfoP1
    ) : (
      <>
        <div className="uppercase mt-2" style={{ fontWeight: 700 }}>
          Avant de commencer
        </div>
        <div className="subtitle">
          Prenez connaissance de la grille et de ses critères d'évaluation <br />
          (définitions disponibles au survol). Pendant l'écoute, cliquez au fur et à mesure <br />
          sur ces critères pour évaluer les propos et l'attitude de l'intervenant.
        </div>
      </>
    );

    const InfoP2 = (
      <>
        <PhaseBubbles />
        <div className="uppercase">Appréciation générale</div>
      </>
    );

    const infos = [InfoP0, InfoP1, InfoP2];

    return <div style={{ flex: 1 }}>{infos[Math.min(phase, 2)]}</div>;
  };

  const ActionBar = () => {
    const button = {
      0: { name: 'COMMENCER', callback: onStart, id: "gt-start-evaluation" },
      1: { name: 'VALIDER CETTE ETAPE', callback: onValidateP1 },
      2: { name: 'TERMINER', callback: onFinish }
    };

    return props.replay && phase >= 1 ? (
      <div className="d-flex jc-c flex-column">
        <div className="d-flex jc-c">
          <IconButton
            label={`étape  ${phase === 1 ? 'suivante' : 'précédente'}`}
            iconRight={phase === 1}
            onClick={phase === 1 ? onFinish : props.onGoBack}
          >
            {phase === 1 ? (
              <ArrowForwardIosIcon fontSize="inherit" />
            ) : (
              <ArrowBackIosIcon fontSize="inherit" style={{ paddingLeft: 5 }} />
            )}
          </IconButton>
        </div>

        {/* <button className="btn btn-primary mt-1" onClick={phase === 1 ? onFinish : props.onGoBack}>
          {phase === 1 ? 'étape suivante' : 'étape précédente'}
        </button> */}

        {phase > 1 && (
          <div className="mt-2">
            <button className="btn btn-primary mt-1" onClick={() => props.history.goBack()}>
              Quitter
            </button>
          </div>
        )}
      </div>
    ) : (
      <div style={{ flex: 1 }}>
        <button className="btn btn-primary mt-1" onClick={button[Math.min(phase, 2)].callback} id={button[Math.min(phase, 2)].id}>
          {phase >= 2 ? (phase - 2 === hexagons.length - 1 ? 'TERMINER' : 'SUIVANT') : button[Math.min(phase, 2)].name}
        </button>
      </div>
    );
  };

  return (
    <div
      style={{
        display: '',
        flexShrink: 0,
        flexDirection: 'column',
        overflow: 'hidden',
        textAlign: 'center',
        minWidth: '300px',
        WebkitUserSelect: 'none'
      }}
    >
      <InformationBar />
      <div style={{ flex: 2, minHeight: '300px' }}>
        <HexagonsGrid
          canVote={canVote}
          hexagons={hexagons}
          onPageChange={onPageChange}
          maxSize={props.maxSize}
          isReplay={props.replay}
          active={active}
          timer={props.getTime()}
          height={props.height}
          isMobile={props.isMobile}
          currentComment={props.currentComment}
          onCellClick={onCellClick}
          onOpenComment={props.onOpenComment}
          flashing={props.flashing.length > 0 ? props.flashing : clickedCell._id ? [clickedCell] : []}
          phase={phase}
          commenting={props.commenting}
          editedComment={props.editedComment}
        />
      </div>
      <ActionBar />
    </div>
  );
});

HexagonsSection.defaultProps = {
  isMobile: false
};

export default HexagonsSection;
