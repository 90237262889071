import * as React from 'react';
import CircularProgress from '../../../../components/charts/CircularProgress';
import './ScoreTab.scss';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TextInput from '../../../../components/TextInput';
import TitleSection from '../../TitleSection';
import { hexagonColors } from '../../../../styles/palette';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { HUB_MODES } from '../../../TeacherHub/constants';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '../../../../components/dialog/Dialog';
import Axios from 'axios';
import { UPDATE_GRADE } from '../../../../services/api_routes';
import { withRouter } from 'react-router-dom';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

const get_grade_color = (grade, max_grade) =>
  grade >= max_grade * 0.75
    ? hexagonColors[0]
    : grade >= max_grade * 0.5
    ? hexagonColors[1]
    : grade >= max_grade * 0.25
    ? hexagonColors[3]
    : hexagonColors[4];

const EditGradeModal = ({ open, onClose, grade, onSubmit }) => {
  const [editedGrade, setEditedGrade] = React.useState(grade);

  React.useEffect(() => setEditedGrade(grade), [grade]);

  const onChangeGrade = e => {
    const parsed = e.target.value;
    if (parsed >= 0 && parsed <= 20) {
      setEditedGrade(e.target.value);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title=" "
      content={
        <div className="text-center">
          <div className="text-center">
            <h3>Note de l'élève</h3>
          </div>
          <div>
            <div className="subtitle mb-2">Saisissez votre note</div>
            <TextInput
              min="0"
              max="20"
              type="number"
              value={editedGrade}
              onChange={onChangeGrade}
              style={{ fontSize: '24px', textAlign: 'center' }}
            />
          </div>
          <div className="d-flex mt-4">
            <button className="btn btn-outline mr-2 fullwidth" onClick={onClose}>
              Annuler
            </button>
            <button className="btn btn-primary ml-2 fullwidth" onClick={onSubmit(editedGrade)}>
              Valider
            </button>
          </div>
        </div>
      }
    />
  );
};

/**
 * Display the scores of a given evaluation
 * @param {String} title
 * @param {*} scores of form { p1: Int, p2: Int, grade: Int }
 * @param {*} grade of form { grade: Int, comment: String }
 */
function ScoreTab({
  title,
  scores,
  grade,
  onReplay,
  onGradeUpdate,
  mode,
  teacher,
  match,
  history,
  folderToken,
  recordingMode,
  isDemo,
  isDemoToEvaluate,
  isInPerson,
  graded
}) {
  const [showEditGradeModal, setShowEditGradeModal] = React.useState(false);

  const shouldDisableReplay = mode === 'to_do' && recordingMode === 'none';

  const circular_charts = [
    { label: 'Analyse continue', props: { value: scores.p1, maxValue: 12 } },
    { label: 'Appréciation générale', props: { value: scores.p2, maxValue: 8 } },
    {
      label: mode === HUB_MODES.TEACHER_FOLDER_TO_DO ? 'Note' : 'Note proposée',
      props: { value: scores.grade, maxValue: 20 }
    }
  ];

  const onEditClose = () => setShowEditGradeModal(false);
  const onChangeComment = e => {
    e.preventDefault();

    onGradeUpdate({ ...grade, comment: e.target.value });
  };
  // const onChangeGrade = e => setEditedGrade(e.target.value);
  const onValidateGradeChange = editedGrade => async () => {
    if (editedGrade && parseInt(editedGrade) >= 0 && parseInt(editedGrade) <= 20)
      onGradeUpdate({ ...grade, grade: String(editedGrade).slice(0, parseInt(editedGrade >= 10 ? 5 : 4)) });
    onEditClose();
  };

  const onFinishGrading = async () => {
    if (!isDemo || isDemoToEvaluate) {
      const res = await Axios.post(UPDATE_GRADE, { token: match.params.id, forUser: match.params.forUser, ...grade });
      if (res.status === 200) {
        redirectToRoot();
      }
    } else {
      redirectToRoot();
    }
  };

  const redirectToRoot = () => {
    if (!teacher || !folderToken) {
      history.replace('/list/');
    } else {
      if (isInPerson) {
        history.replace('/presentiel/' + folderToken);
      } else if (mode === HUB_MODES.TEACHER_FOLDER_TO_DO) {
        history.replace('/folders/' + folderToken);
      } else {
        history.replace('/evals/' + match.params.id);
      }
    }
  };

  const ScoresSection = () => (
    <div className="st-scores">
      {circular_charts.map((chart, idx) => (
        <React.Fragment key={'sts-' + idx}>
          {idx === circular_charts.length - 1 && (
            <div className="st-sep">
              <ArrowForwardIosIcon />
            </div>
          )}
          <div className={'st-circle text-center st-grade-' + idx}>
            <div>
              <CircularProgress
                {...chart.props}
                strokeWidth={25}
                color={get_grade_color(chart.props.value, chart.props.maxValue || 10)}
                text={
                  <>
                    <tspan x={idx === 1 ? '45' : '43'} y="50">
                      {chart.props.value}
                    </tspan>
                    {/* <tspan y="53" x={chart.props.value >= 10 ? "57" : idx === 1 ? "55": "53"} style={{ fontSize: 8, left: 10, marginTop: 25}}>/</tspan> */}
                    <tspan
                      y="53"
                      x={(idx === 1 ? 49 : 45) + String(chart.props.value).length * 6}
                      style={{ fontSize: 8, left: 10, marginTop: 25 }}
                    >
                      /
                    </tspan>
                    <tspan
                      y="53"
                      x={(idx === 1 ? 49 : 45) + String(chart.props.value).length * 6 + 7}
                      style={{ fontSize: 8 }}
                    >
                      {idx === 0 ? 12 : idx === 1 ? 8 : 20}
                    </tspan>
                  </>
                }
              />
            </div>
            <div className="st-circle-title">{chart.label}</div>
            {idx < 2 && (
              <div>
                <button
                  className="btn btn-icon btn-text"
                  onClick={onReplay(idx + 1)}
                  title={shouldDisableReplay ? 'Pas de média à revisionner' : ''}
                  disabled={idx === 0 && shouldDisableReplay}
                >
                  <PlayCircleOutlineIcon />
                </button>
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  const GradeSection = () => (
    <div>
      {teacher ? (
        <>
          <div className="st-grade">
            <div className="st-grade-item">
              <h3>Note finale</h3>
              <div className="subtitle">
                {mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL && !graded ? (
                  <>
                    Vous pouvez modifier la note finale.
                    <br />
                    (N.B. La « note proposée » plus haut a été calculée automatiquement en fonction de l'évaluation de
                    référence.)
                  </>
                ) : (
                  <>La note que vous avez attribuée après correction du travail de l'élève. Vous pouvez la modifier.</>
                )}
              </div>
            </div>
            <div className="st-circle st-grade-item text-center" id="gt-final-score" style={{ minWidth: '200px' }}>
              <div>
                <CircularProgress
                  value={grade.grade}
                  color={get_grade_color(grade.grade, 20)}
                  maxValue={20}
                  textSize="25px"
                  text={
                    <>
                      <tspan x={grade.grade >= 10 ? '41' : '47'} y="50">
                        {grade.grade}
                      </tspan>
                      <tspan
                        y="54"
                        x={(grade.grade >= 10 ? 45 : 51) + String(grade.grade).length * 6 + 1}
                        style={{ fontSize: 8 }}
                      >
                        /
                      </tspan>
                      <tspan
                        y="54"
                        x={(grade.grade >= 10 ? 41 : 47) + String(grade.grade).length * 6 + 11}
                        style={{ fontSize: 8 }}
                      >
                        20
                      </tspan>
                    </>
                  }
                />
              </div>
              {teacher && (!isDemo || isDemoToEvaluate) && (
                <button onClick={() => setShowEditGradeModal(true)} className="btn btn-icon st-circle-title p-1">
                  <EditIcon />
                </button>
              )}
            </div>
            <div className="st-grade-item" id="gt-comm-general">
              <div>Commentaire appréciation générale</div>
              <TextInput
                key="ello"
                disabled={isDemo && !isDemoToEvaluate}
                multiline
                placeholder="Texte libre"
                value={grade.comment}
                onChange={onChangeComment}
                className="mt-2"
                rows={5}
              />
            </div>
          </div>
          <button className="btn btn-primary mt-4" onClick={onFinishGrading}>
            Valider et fermer
          </button>
        </>
      ) : (
        <>
          {mode === HUB_MODES.TEACHER_FOLDER_TO_DO ? (
            <div className="d-flex flex-wrap">
              <div className="st-circle text-center" style={{ minWidth: '250px', flex: 1, margin: '0 auto' }}>
                <div>
                  <CircularProgress
                    value={grade.grade}
                    color={get_grade_color(grade.grade, 20)}
                    maxValue={20}
                    textSize="30px"
                    strokeWidth={5}
                    withColor
                    withMaxIndicator
                  />
                </div>
              </div>
              <div style={{ flex: 1 }} className="text-left p-4">
                {/* <div className="uppercase">En savoir plus</div> */}
                <div className="subtitle mt-3">
                  Vous pouvez revoir votre prestation
                  <br />
                  et l'évaluation de votre enseignant(e).
                </div>
                <div>
                  <button
                    className="mt-4 btn btn-outline fullwidth d-flex align-items-center p-2 justify-content-center"
                    onClick={onReplay(1)}
                    disabled={shouldDisableReplay}
                    title={shouldDisableReplay ? 'Pas de média à revisionner' : ''}
                  >
                    <PlayCircleOutlineIcon fontSize="large" />
                    <div className="ml-1">Lire l'enregistrement</div>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="st-circle text-center" style={{ minWidth: '250px', margin: '0 auto' }}>
              <div>
                <CircularProgress
                  value={grade.grade}
                  color={get_grade_color(grade.grade, 20)}
                  maxValue={20}
                  textSize="25px"
                  strokeWidth={5}
                  withColor
                  withMaxIndicator
                />
              </div>
            </div>
          )}
          <div className="text-center" style={{ marginTop: '10%' }}>
            <div className="round-icon">
              <ChatBubbleIcon fontSize="inherit" />
            </div>
            <div style={{ marginTop: 10, fontWeight: 900 }}>
              <strong className="uppercase">Commentaire - appréciation générale</strong>
            </div>
            <div>
              {grade.comment &&
                grade.comment.split('\n').map(str => (
                  <>
                    {str}
                    <br />
                  </>
                ))}
            </div>
            {!grade.comment && <div className="subtitle">Votre professeur(e) n'a pas laissé d'appréciation.</div>}
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="st-wrapper">
      <TitleSection
        title={teacher ? title : ''}
        label={
          teacher
            ? mode === HUB_MODES.TEACHER_FOLDER_TO_DO
              ? 'Récapitulatif de votre évaluation de la prestation'
              : "Récapitulatif de l'évaluation de la prestation par l'élève"
            : 'Votre note'
        }
      />
      <EditGradeModal
        open={showEditGradeModal}
        onClose={onEditClose}
        grade={grade.grade}
        onSubmit={onValidateGradeChange}
      />

      {teacher && <ScoresSection />}
      {GradeSection()}
    </div>
  );
}

export default connect(({ auth }) => ({ teacher: auth.user.teacher }))(withRouter(ScoreTab));
