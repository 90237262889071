import React from 'react'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { withSnackbar } from 'notistack';
import axios from "axios";

export const FileDeposit = withSnackbar(({ folder, open, onClose, url, onUpload, ...props }) => {
  const getUploadParams = ({ meta }) => ({ url, headers: { "Authorization": axios.defaults.headers.common['Authorization'] } });
  const handleChangeStatus = ({ meta }, status) => {
    if(status === 'ready'){ meta.status = 'headers_received' }
    if (status === 'done') { onUpload(); onClose(); }
  }
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach(f => { f.restart(); });
    // onClose();
    props.enqueueSnackbar("Votre fichier a bien été enregistré.", { variant: 'success' });
  }

  return (
        <Dropzone 
          getUploadParams={getUploadParams}
          inputContent="Déposer un fichier"
          submitButtonContent="Valider"
          onChangeStatus={handleChangeStatus}
          multiple={false}
          autoUpload={false}
          onSubmit={handleSubmit}
          maxFiles={1}
          accept="video/*,.mp3,audio/*"
          styles={{
            dropzone: { width: "100%", height: 200, borderColor: 'white', backgroundColor: 'white !important', background: 'white', borderRadius:6 },
            dropzoneActive: { borderColor: 'green' },
            preview: { color: 'white', background: 'white' },
            previewImage: { color: 'white'},
            previewFileName: { color: 'white' },
            inputLabel: { color: 'black' },
          }}
        />
      )
})
