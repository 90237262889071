import * as React from 'react';
import { withRouter } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import SquareActionButton from '../../components/SquareActionButton';
import './SessionCreationSelect.scss';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { squareColors } from '../../styles/palette';
import withTutorial from '../../hooks/withTutorial';
import { tutorialScreens } from '../../utils/texts';

function SessionCreation({ history, startGuidedTour }) {
  React.useEffect(() => {
    setTimeout(() => {
      if (startGuidedTour) startGuidedTour();
    }, 100);
  }, []);

  return (
    <>
      <NavigationBar title="Créer une nouvelle activité" to="/home" />
      <div className="body-wrapper">
        <div className="sc-container">
          <div className="fullwidth" id="gt-ac-creation">
            <div className="sc-title">Quel type d'activité voulez-vous créer ?</div>
            <div className="creation-options">
              <div className="creation-option" id="gt-ac-toeval">
                <SquareActionButton
                  title={
                    <>
                      Apprendre à
                      <br />
                      évaluer
                    </>
                  }
                  icon={<AddIcon fontSize="inherit" />}
                  color={squareColors.purple}
                  to="/create_presta"
                  label={<>Vos élèves utilisent une grille d'évaluation pour apprendre à noter une prestation orale.</>}
                />
              </div>

              <div className="creation-option" id="gt-ac-todo">
                <SquareActionButton
                  title={
                    <>
                      S'entraîner à
                      <br />
                      l'oral
                    </>
                  }
                  icon={<AddIcon fontSize="inherit" />}
                  color={squareColors.blue}
                  to="/create_folder"
                  label={
                    <>
                      Vos élèves réalisent une prestation orale. <br />À l'aide d'une grille d'évaluation, vous notez
                      leur travail.
                    </>
                  }
                />
              </div>

              <div className="creation-option" id="gt-ac-inperson">
                <SquareActionButton
                  title={
                    <>
                      S'entraîner en
                      <br />
                      conditions d'examen
                    </>
                  }
                  icon={<AddIcon fontSize="inherit" />}
                  color="#E8BF65"
                  to="/create_inperson"
                  label={
                    <>
                      Vos élèves réalisent une prestation orale en votre présence. Vous utilisez une grille d'évaluation
                      pour noter leur travail en direct.
                    </>
                  }
                />
              </div>
            </div>
          </div>
          <div className="fullwidth text-center">
            <div className="or-option">
              <span />
              <div>OU</div>
              <span />
            </div>

            <div className="creation-options">
              <div className="creation-option" />
              <div className="creation-option">
                <SquareActionButton
                  title={
                    <>
                      À partir d'une activité <br /> existante
                    </>
                  }
                  icon={<FileCopyIcon fontSize="inherit" />}
                  color={squareColors.green}
                  to="/sessionCreation/copy"
                  label={<>Dupliquez une activité déjà créée et adaptez-la pour en créer une nouvelle.</>}
                />
              </div>
              <div className="creation-option" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTutorial(withRouter(SessionCreation), tutorialScreens.EXERCISES_CREATION_SELECTION);
