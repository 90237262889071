import React from 'react';
import './MatchingTable.scss';
import Replay10Icon from '@material-ui/icons/Replay10';
import { IconButton } from '@material-ui/core';

function MatchingTable({ title, headers, labels, teacher, student, matching, onBack, withBack = false, pic, flashing = [] }) {
  return (
    <div>
      <div className="hex-title mt-2">
        {pic && <img src={pic} alt="Logo Participant" />}
        {title}
      </div>
    <div className="hex-p2">
      <table>
        <thead>
          <tr>
            {withBack && <th style={{ width: 18, maxWidth: 18 }}></th>}
            {headers.map((label, idx) => <th key={label}>{label}</th>)}
          </tr>
        </thead>

        <tbody>
          {labels.map((label, idx) => 
          <div style={{ backgroundColor: flashing.length > 0 && flashing[idx] ? 'white' : '', borderRadius: '6px', boxShadow: flashing.length > 0 && flashing[idx] ? "0px 0px 4px rgba(0,0,0,0.3)" : "", margin: 3}}>
            <tr key={idx} style={{ 
              borderBottom: teacher[idx].comment ? 'none' : undefined, 
              paddingBottom: teacher[idx].comment ? 0 : undefined,
            }}>
              {withBack && <td style={{ maxWidth: 18, width: 18 }}>
                <IconButton title="Revenir 10 secondes en arrière" onClick={onBack(idx)} className="small" style={{ fontSize: 24, opacity: 0.6 }}>
                  <Replay10Icon fontSize="inherit"/>
                </IconButton>
              </td>}
              <td >{label}</td>
              <td style={{ color: teacher[idx].color }}>
                {teacher[idx].label}
              </td>
              <td style={{ color: student[idx].color }}>{student[idx].label}</td>
              <td className="match-container">
                <div className="round-match" style={{ backgroundColor: matching[idx].color }} />
                <div>{matching[idx].label}</div>
              </td>
            </tr>
            {teacher[idx].comment && 
              <tr className="text-left no-style" style={{ marginTop: -15 }}> 
                <td />
                <td colspan="2" className="text-left">
                  <small>{teacher[idx].comment}</small>
                </td>
                <td />
              </tr>
            }
          </div>
          )}
        </tbody>
      </table>
    </div>
  </div>
  )
}

export default MatchingTable;