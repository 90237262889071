import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { HUB_MODES } from '../constants';
import { DELETE_DEBATE, DELETE_EVALUATION, DELETE_FOLDER, VALIDATE_GRADES } from '../../../services/api_routes';
import useApiPrepare from '../../../hooks/api/useApiPrepare';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import SessionsFiltersRow from './components/SessionsFiltersRow';
import SessionCard from './components/SessionCard';
import { AlertContext } from '../../../hooks/context/AlertProvider';
import { isSubstring } from '../../../utils';
import InPersonRecordingModal from './components/InPersonRecordingModal';

const { TEACHER_FOLDER_TO_DO: TO_DO, TEACHER_FOLDER_TO_EVAL: TO_EVAL, TEACHER_IN_PERSON: IN_PERSON } = HUB_MODES;

function SessionsList({
  sessions,
  history,
  mode,
  enqueueSnackbar,
  reloadData,
  isTeacher,
  prestationMode,
  closeDate,
  isDemo,
  isDemoToEvaluate,
  isGroupDemo,
  isLoading,
  token,
  sessionMode,
  isPCL,
  ...props
}) {
  const [, , deleteFolder] = useApiPrepare(DELETE_FOLDER, {}, 'post');
  const [, , deleteDebate] = useApiPrepare(DELETE_DEBATE, {}, 'post');
  const [, , deleteEvaluation] = useApiPrepare(DELETE_EVALUATION, {}, 'post');
  const [, , validateGrades] = useApiPrepare(VALIDATE_GRADES, {}, 'post');

  // WHen opening the in person modal, we need to keep state of the student we want to evaluate
  const [inPersonState, setInPersonState] = React.useState({
    showConfigModal: false,
    selectedStudent: null
  });

  const [filteredSessions, setFilteredSessions] = React.useState(sessions);
  const [filterState, setFilterState] = React.useState({
    session_type: 'all',
    sort: 'date_asc',
    query: ''
  });

  const alertContext = React.useContext(AlertContext);

  /** CALLBACKS */
  const onChangeSessionType = session_type => () => setFilterState({ ...filterState, session_type });
  const onChangeSort = e => setFilterState({ ...filterState, sort: e.target.value });
  const onChangeQuery = e => setFilterState({ ...filterState, query: e.target.value });

  const onDeleteClick = session => async e => {
    e.stopPropagation();
    e.preventDefault();

    const isHub = mode === HUB_MODES.TEACHER_HUB;

    alertContext.confirm(
      {
        title: isHub ? "Supprimer l'activité" : 'Supprimer le travail',
        content: isHub ? (
          <>
            Êtes-vous sûr(e) de vouloir supprimer l'activité <br />
            «&nbsp;{session.title}&nbsp;»&nbsp;?
          </>
        ) : (
          <>
            Êtes-vous sûr(e) de vouloir supprimer le travail de «&nbsp;{session.title}&nbsp;» lié à l'activité <br />
            «&nbsp;{props.title}&nbsp;» &nbsp;?
          </>
        ),
        submitText: 'Oui, supprimer'
      },
      async bool => {
        if (bool) {
          const res =
            session.mode === 'to_do' || session.mode === 'in_person'
              ? await deleteFolder({ token: session.token })
              : mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL
                ? await deleteEvaluation({ token: session.token, forUser: session.user })
                : await deleteDebate({ token: session.token });
          if (res.status === 200) {
            reloadData();
            enqueueSnackbar(isHub ? "L'activité a bien été supprimée." : 'Le travail a bien été supprimé.', {
              variant: 'success'
            });
          }
        }
      }
    );
  };

  const onEvaluateClick = session => () => {
    if (isTeacher) {
      if (mode === TO_DO) {
        return '/join/' + session.token;
      } else if (mode === TO_EVAL) {
        return '/debate/' + session.token + '/results/' + session.user;
      } else if (mode === IN_PERSON) {
        setInPersonState({ showConfigModal: true, selectedStudent: session.externalId });
      }
    } else {
      if (session.mode === TO_EVAL) {
        return '/join/' + session.token;
      } else {
        return '/preparation/' + session.token;
      }
    }
  };

  const onValidateGrades = () => {
    alertContext.confirm(
      {
        title: 'Valider toutes les notes',
        content: (
          <>
            Par cette action, les notes calculées automatiquement par la plateforme seront validées comme notes finales.
            Si vous aviez modifié des notes individuellement, vos modifications seront conservées.
          </>
        ),
        submitText: 'Valider'
      },
      async bool => {
        if (bool) {
          const res = await validateGrades({ token, mode });
          if (res.status === 200) {
            reloadData();
            enqueueSnackbar('Les notes ont bien été validées.', { variant: 'success' });
          }
        }
      }
    );
  };

  const onReevaluateClick = session => e => {
    e.stopPropagation();
    e.preventDefault();

    if (mode === TO_DO || mode === IN_PERSON) {
      alertContext.confirm(
        {
          title: 'Re-corriger',
          content: (
            <>
              Êtes-vous sûr(e) de vouloir évaluer à nouveau la prestation orale de l'élève&nbsp;?
              <br />
              L'évaluation et la note précédente seront écrasées.
            </>
          ),
          submitText: 'Oui, continuer'
        },
        bool => {
          if (bool) {
            history.push(onEvaluateClick(session)());
          }
        }
      );
    } else {
      history.push('/debate/' + session.token + '/results/' + session.user);
    }
  };
  const onResultsClick = session => {
    // e.stopPropagation();
    // e.preventDefault();

    if (mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL || (!isTeacher && session.mode === 'to_eval')) {
      return '/debate/' + session.token + '/results/' + session.user;
    } else {
      return '/debate/' + session.token + '/results';
    }
  };

  const onReplayClick = session => {
    // e.preventDefault();

    if (mode === TO_DO || session.mode === TO_DO || mode === IN_PERSON || session.mode === IN_PERSON) {
      return '/replay/' + session.token;
    } else {
      return '/debate/' + session.token + '/results/' + session.user + '?mode=replay';
    }
  };

  const onEditClick = session => e => {
    e.stopPropagation();
    e.preventDefault();

    const path =
      session.mode === TO_DO ? '/create_folder' : session.mode === IN_PERSON ? '/create_inperson' : '/create_presta';
    history.push(path, { session, isEditing: true });
  };

  const onCardClick = ({ mode: sessionMode, token }) => {
    if (mode === HUB_MODES.TEACHER_HUB && isTeacher) {
      if (sessionMode === TO_EVAL) {
        return '/evals/' + token;
      } else if (sessionMode === TO_DO) {
        return '/folders/' + token;
      } else if (sessionMode === IN_PERSON) {
        return '/presentiel/' + token;
      }
    } else if (mode === HUB_MODES.TEACHER_HUB && !isTeacher) {
      if (sessionMode === TO_EVAL) {
        return '/join/' + token;
      } else if (sessionMode === TO_DO) {
        return '/preparation/' + token;
      }
    }

    return '';
  };

  /** FILTERS */
  const { session_type, query, sort } = filterState;
  const hub_mode = mode;

  // Handle the ?filter url param query
  React.useEffect(() => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const urlFilter = urlParams.get('filter');

      setFilterState(s => ({ ...s, session_type: urlFilter }));
    }
  }, [window.location.search]);

  React.useEffect(() => setFilteredSessions(sessions), [sessions]);
  React.useEffect(() => {
    const applyFilters = () => {
      const afterFilter = sessions
        .filter(({ title, mode, closeDate, participated, notSubmitted, submitted, isDemo: isExDemo }) => {
          const today = new Date(Date.now());
          today.setHours(0, 0, 0, 0);
          const closeDateD = new Date(closeDate);
          closeDateD.setHours(0, 0, 0, 0);

          return (
            !(hub_mode === HUB_MODES.TEACHER_FOLDER_TO_DO && !isGroupDemo && isDemo && notSubmitted) &&
            (!query || isSubstring(query, title)) &&
            !(hub_mode === HUB_MODES.TEACHER_HUB && !isExDemo && isGroupDemo) &&
            (session_type === 'all' ||
              session_type === mode ||
              (session_type === 'finished' && (today > closeDateD || submitted)) ||
              (session_type === 'to_evaluate' &&
                !participated &&
                (!notSubmitted || (notSubmitted && sessionMode === IN_PERSON))) ||
              (session_type === 'evaluated' && participated) ||
              (session_type === 'not_submitted' && notSubmitted))
          );
        })
        .sort((a, b) => {
          if (sort.includes('date'))
            return sort.includes('asc')
              ? new Date(b.timeCreated) - new Date(a.timeCreated)
              : new Date(a.timeCreated) - new Date(b.timeCreated);
          if (sort.includes('name'))
            return sort.includes('desc') ? b.title.localeCompare(a.title) : a.title.localeCompare(b.title);
          if (sort.includes('grade'))
            return sort.includes('desc')
              ? parseInt(b.score) - parseInt(a.score)
              : parseInt(a.score) - parseInt(b.score);
          if (sort.includes('deadline'))
            return sort.includes('desc')
              ? new Date(b.closeDate) - new Date(a.closeDate)
              : new Date(a.closeDate) - new Date(b.closeDate);
          return 1;
        });


      let finalFilter = afterFilter;
      if (isTeacher && !sort.includes("name")) {
        finalFilter = [...afterFilter.filter(s => !s.notSubmitted), ...afterFilter.filter(s => s.notSubmitted).sort((a, b) => a.title.localeCompare(b.title))]
      }

      setFilteredSessions([...finalFilter]);
    }

    applyFilters();
  }, [session_type, query, sort, sessions, hub_mode, isGroupDemo]);

  const submitted_sessions = filteredSessions.filter(session => session.submitted);
  const unsubmitted_sessions = filteredSessions.filter(session => !session.submitted);

  const CardRender = ({ session, submitted, idx }) => (
    <SessionCard
      key={idx}
      submitted={submitted}
      mode={sessionMode}
      session={{ ...session, prestationMode: session.prestationMode ? session.prestationMode : prestationMode }}
      onClick={onCardClick(session)}
      isTeacher={isTeacher}
      isDemo={isDemo}
      isDemoToEvaluate={isDemoToEvaluate}
      isPCL={isPCL}
      closeDate={closeDate}
      callbacks={{
        onDelete: onDeleteClick(session),
        onEvaluate: onEvaluateClick(session),
        onReevaluate: onReevaluateClick(session),
        onResults: onResultsClick(session),
        onReplay: onReplayClick(session),
        onEdit: onEditClick(session)
      }}
    />
  );


  /** RENDER */
  return (
    <div className={isTeacher ? 'slist-content-teacher' : 'content-data'}>
      <InPersonRecordingModal
        open={inPersonState.showConfigModal}
        onClose={() => setInPersonState({ showConfigModal: false, selectedStudent: null })}
        token={token}
        studentExtId={inPersonState.selectedStudent}
      />

      <div className="slist-container">
        <SessionsFiltersRow
          mode={mode}
          isTeacher={isTeacher}
          currentState={filterState}
          isAllGraded={sessions.every(
            session =>
              (mode === TO_EVAL && session.graded) ||
              (mode !== TO_EVAL && (!session.participated || session.participated.graded)) ||
              session.notSubmitted
          )}
          onValidateGrades={onValidateGrades}
          callbacks={{ onChangeSessionType, onChangeSort, onChangeQuery }}
        />

        <div style={{ padding: 2 }}>
          {filteredSessions.length === 0 && (
            <div className="mt-4 text-center">
              {isLoading ? (
                <b>Chargement des activités en cours...</b>
              ) : mode !== HUB_MODES.TEACHER_HUB ? (
                <b>Il n'y a aucun travail à afficher.</b>
              ) : filterState.session_type === 'finished' ? (
                <b>Il n'y a aucune activité à afficher.</b>
              ) : !isTeacher ? (
                <b>
                  <h2>Aucune activité</h2>
                  <b>
                    Vous n'avez pas d'activité à réaliser pour le moment. <br />
                    Revenez ici une fois que votre professeur(e) aura créé une nouvelle activité.
                  </b>
                </b>
              ) : (
                <b>Vous n'avez encore créé aucune activité pour ce groupe.</b>
              )}
            </div>
          )}

          {!isTeacher ? (
            <>
              {unsubmitted_sessions.length > 0 && (
                <div className="mt-2">
                  <div className="h1-strong">À RENDRE ({unsubmitted_sessions.length})</div>
                  {unsubmitted_sessions.map((session, idx) => (
                    <CardRender key={idx} idx={idx} session={session} />
                  ))}
                </div>
              )}

              {submitted_sessions.length > 0 && (
                <div className="pt-6">
                  <div className="h1-strong">RENDUS ({submitted_sessions.length})</div>
                  {submitted_sessions.map((session, idx) => (
                    <CardRender key={idx} idx={idx} session={session} submitted={true} />
                  ))}
                </div>
              )}
            </>
          ) : (
            filteredSessions.map((session, idx) => <CardRender key={idx} idx={idx} session={session} />)
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(({ auth }) => ({ isTeacher: auth.user.teacher }))(withSnackbar(withRouter(SessionsList)));
