import React, { useRef, useState } from 'react';
import Dialog from '../../../components/dialog/Dialog';
import MediaRecorder from '../../../components/MediaRecorder';
import { AlertContext } from '../../../hooks/context/AlertProvider';

const DepositRecordingDialog = ({ open, onClose, mode, onUpload, fullScreen }) => {
  const alertContext = React.useContext(AlertContext);
  const [recordingState, setRecordingState] = useState({ isRecording: false, finished: false });

  const mediaRecorderRef = useRef();

  const onStart = async () => {
    await mediaRecorderRef.current.startRecording();
    setRecordingState({ isRecording: true, finished: false });
  };

  const onStop = async (_, callback) => {
    await mediaRecorderRef.current.stopRecording(callback);
    setRecordingState({ isRecording: false, finished: true });
    if (callback) callback();
  };

  const onSubmit = () => {
    onUpload(mediaRecorderRef.current.getFile());
  };

  const onReset = withConfirm => () => {
    if (withConfirm) {
      alertContext.confirm(
        {
          title: 'Attention',
          content: 'Cette action supprimera votre enregistrement.',
          submitText: 'Recommencer'
        },
        bool => {
          if (bool) {
            mediaRecorderRef.current.reset();
            mediaRecorderRef.current.initialize();
            setRecordingState({ isRecording: false, finished: false });
          }
        }
      );
    } else {
      mediaRecorderRef.current.reset();
      setRecordingState({ isRecording: false, finished: false });
    }
  };

  const onCloseOverride = async () => {
    onStop(null, () => {
      onReset(false)();
      onClose();
    });
  };

  const { isRecording } = recordingState;
  const dialogProps = { open, fullScreen, title: ' ' };

  return (
    <Dialog
      {...dialogProps}
      onClose={onCloseOverride}
      content={
        <div className="text-center">
          <h3>Depuis votre webcam</h3>

          <MediaRecorder mode={mode} ref={mediaRecorderRef} showTimeWarning />

          {recordingState.finished ? (
            <div className="ctrls">
              <button className="btn btn-outline fullwidth" onClick={onReset(true)}>
                Recommencer
              </button>
              <button className="btn btn-primary fullwidth" onClick={onSubmit}>
                Envoyer
              </button>
            </div>
          ) : (
            <button
              className={'mt-4 btn ' + (isRecording ? 'btn-outline' : 'btn-primary')}
              onClick={!isRecording ? onStart : onStop}
            >
              {!isRecording ? "Commencer l'enregistrement " + (mode === 'audio' ? 'audio' : 'vidéo') : 'Terminer'}
            </button>
          )}
        </div>
      }
    />
  );
};

export default DepositRecordingDialog;
