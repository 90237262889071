import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { GET_ERRORS } from '../error/actions';

export const USER_LOADING = 'USER_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const FETCH_USER_STATS = 'FETCH_USER_STATS';
export const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP';
export const SET_CURRENT_HEXAGON_GROUP = 'SET_CURRENT_HEXAGON_GROUP';
export const SET_EXERCISES_COUNT = 'UPDATE_EXERCISES_COUNT';

export const refreshToken = (token, dispatch) => {
  // Save to localStorage
  // Set token to localStorage
  localStorage.setItem('jwtToken', token);
  // Set token to Auth header
  setAuthToken(token);
  // Decode token to get user data
  const decoded = jwt_decode(token);

  // Set current user
  dispatch(setCurrentUser(decoded));
};

export const setExercisesCount = count => {
  return { type: SET_EXERCISES_COUNT, payload: count };
};

export const setCurrentHexagonGroup = group => async dispatch => {
  localStorage.setItem('currentHexagonGroup', JSON.stringify(group));
  dispatch({ type: SET_CURRENT_HEXAGON_GROUP, payload: group });
};

export const setCurrentGroup = (token, dispatch) => {
  localStorage.setItem('currentGroup', JSON.stringify(token));
  return { type: SET_CURRENT_GROUP, payload: token };
};

export const fetchUserStats = () => async dispatch => {
  const stats = await axios.get('/api/users/getUserStats');

  const { experience, persuasionRate, rank, fiability } = stats.data;
  dispatch({ type: FETCH_USER_STATS, payload: { fiability, experience, persuasionRate, rank } });
};

// Register User
export const registerUser = (userData, history, callback) => async dispatch => {
  try {
    let res = await axios.post('/api/users/register', userData);
    if (res.data && callback) callback();
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};

// Login - get user token
export const loginUser = (userData, history, redirect = '/', path = 'login') => dispatch => {
  axios
    .post('/api/users/' + path, userData)
    .then(async res => {
      const { token } = res.data;

      refreshToken(token, dispatch);

      history.push(redirect);
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = history => dispatch => {
  // Remove token from local storage
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('currentGroup');
  // localStorage.removeItem('redirectUrl');
  // Remove auth header for future requests
  setAuthToken(false);
  setCurrentGroup(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));

  // history.push('/');
};

export const postUserData = userData => async dispatch => {
  try {
    const res = await axios.post('/api/users/data', userData);

    if (res.data && res.data.token) {
      refreshToken(res.data.token, dispatch);
    }

    return res;
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};

export const postUserDescription = userDescription => async dispatch => {
  try {
    const res = await axios.post('/api/users/description', { userDescription });

    if (res.data && res.data.token) {
      refreshToken(res.data.token, dispatch);
    }

    return res;
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};

export const uploadAvatar = file => async dispatch => {
  try {
    const data = new FormData();
    data.append('image', file);

    const res = await axios.post('/api/users/uploadAvatar', data);

    if (res.status === 200) {
      refreshToken(res.data.token, dispatch);
    }

    return res;
  } catch (err) {
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};
