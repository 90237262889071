import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { hexagonColors } from '../../../styles/palette';

const GroupSelectionModal = props => {
  const [group, setGroup] = useState(0);
  const handleChange = e => setGroup(e.target.value);

  const groups = [
    'Meilleurs Arguments',
    'Arguments Efficaces',
    'Arguments Convenables',
    'Arguments Faibles',
    'Arguments à éviter'
  ];
  const descriptions = [
    'Les arguments les plus convaincants.',
    "Les arguments faisant de l'effet.",
    'Les arguments qui fonctionnent mais peuvent être améliorés.',
    "Les arguments dont l'utilisation n'a souvent que peu d'effet.",
    "Les arguments qu'il est préférable d'éviter dans la plupart des situations."
  ];

  const groupsDefaultRepartitions = {
    6: [1, 2, 1, 1, 2],
    12: [2, 3, 3, 2, 3],
    18: [3, 4, 4, 3, 5],
    24: [4, 5, 5, 4, 7],
    30: [5, 7, 6, 5, 8],
    36: [6, 8, 8, 6, 9]
  };

  return (
    <Dialog {...props}>
      <DialogTitle>Choix du Groupe</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vous devez choisir à quel groupe appartiendra cet argument dans cet hexagone. Cela influera sur la notation
          finale.
        </DialogContentText>
        <DialogContentText>
          Les arguments doivent nécessairement être répartis selon une répartition donnée.
          <br />
          <br />
          Répartition actuelle des groupes -par couleur:
          <br />
          <div style={{ display: 'flex' }}>
            {groupsDefaultRepartitions[props.hexagonSize].map((defaultRepartition, idx) => (
              <>
                <span className="pastille" style={{ backgroundColor: hexagonColors[idx] }} />
                <span style={{ marginRight: 20, marginLeft: 7 }}>
                  {props.groupsRepartition[idx]} / <strong>{defaultRepartition}</strong>
                </span>
              </>
            ))}
            arguments
          </div>
        </DialogContentText>
        <form style={{ width: '100%', marginTop: '1.5em' }}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel htmlFor="demo-dialog-native">Groupe d'Argument</InputLabel>
            <Select style={{ width: '100%' }} value={group} onChange={handleChange}>
              {groups.map((name, idx) => (
                <MenuItem value={idx}>
                  <Grid container direction="row">
                    <Grid item xs={1}>
                      <div className="pastille" style={{ backgroundColor: hexagonColors[idx] }} />
                    </Grid>
                    <Grid item xs={11}>
                      {name}
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
        <DialogContentText>{descriptions[group]}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" color="primary" onClick={() => props.onSubmit(parseInt(group))}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupSelectionModal;
