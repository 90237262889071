import React from 'react';
import { MenuItem } from '@material-ui/core';
import MuiSelect from '../../../../components/forms/MuiSelect';
import Hexagon from '../../../../components/Hexagon';

/**
 * Contains an editable grid for the grid creation page
 */
const GridCreationP1 = ({
  grid,
  editedGrid,
  setEditedGrid,
  flashing,
  setFlashing,
  selectedArgument,
  setSelectedArgument,
  argumentsList
}) => {
  /**
   * ======== STATE ========
   */
  const [selectedPrecision, setSelectedPrecision] = React.useState(grid.precision);

  /**
   * ======= EVENT HANDLERS =======
   */

  // Add or remove cells to the original hexagon
  const updatePrecision = e => {
    const precision = e.target.value;
    const argsPerRow = e.target.value + 1;

    const newGrid = { ...grid };
    newGrid.precision = precision;
    if (precision <= grid.precision) {
      newGrid.argumentGroups = grid.argumentGroups.map((grp, idx) => ({
        ...grp,
        arguments: grp.arguments.slice(0, argsPerRow),
        groupsForArguments: grp.groupsForArguments.slice(0, argsPerRow)
      }));
    } else {
      const originalArgsPerRow = grid.precision + 1;
      const argsToAdd = argsPerRow - originalArgsPerRow;

      newGrid.argumentGroups = grid.argumentGroups.map((grp, idx) => {
        const args = [...grp.arguments];
        const groupsForArguments = [...grp.groupsForArguments];

        for (let i = 0; i < argsToAdd; i++) {
          args.push({ name: ' ', _id: `${idx}-${i}`, empty: true });
          groupsForArguments.push(5);
        }

        return { ...grp, arguments: args, groupsForArguments };
      });
    }

    setEditedGrid({ ...newGrid });
    setSelectedPrecision(precision);

    setFlashing([]);
  };

  const onArgumentClick = (_, argument, cellIdx) => {
    if (flashing.length > 0 && selectedArgument.cellIdx === cellIdx) {
      setFlashing([]);
      return;
    }

    setFlashing([{ _id: argument._id, pointsGroup: argument.pointsGroup, idx: cellIdx }]);
    if (argument.pointsGroup === 5) {
      if (!selectedArgument) setSelectedArgument({ ...argumentsList[0], cellIdx, pointsGroup: 0 });
      else setSelectedArgument({ ...selectedArgument, cellIdx });
    } else {
      setSelectedArgument({ ...argument, cellIdx });
    }
  };

  /**
   * ======= RENDER =======
   */
  return (
    <>
      <div className="gcr-p1-top">
        <div className="gcr-p1-top-left"></div>
        <div className="gcr-p1-title">
          <h3 className="gcr-title">Analyse Continue</h3>
          <div className="subtitle text-center">Cliquez sur un critère pour le modifier</div>
        </div>

        <div className="gcr-p1-select">
          <div style={{ flex: 1 }}>
            <div>
              <label>Critères</label>
            </div>
            <div>
              <MuiSelect className="fullwidth" value={selectedPrecision} onChange={updatePrecision}>
                {[0, 1, 2, 3, 4, 5].map(precision => (
                  <MenuItem key={'pr-' + precision} value={precision}>
                    <div className="gcr-menuitem">{(precision + 1) * 6 + 1}</div>
                  </MenuItem>
                ))}
              </MuiSelect>
            </div>
          </div>
        </div>
      </div>

      <div className="gcr-hex">
        <Hexagon
          flashing={flashing}
          phase={1}
          onCellClick={onArgumentClick}
          active={true}
          evaluationControl={editedGrid}
          cellNumber={(editedGrid.precision + 1) * 6}
          isReplay={true}
          isEditing={true}
          currentComment={{ comment: '' }}
        />
      </div>
    </>
  );
};

export default GridCreationP1;
