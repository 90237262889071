import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { UPLOAD_FILE } from '../../../services/api_routes';
import { get_thumbnail_img } from '../../../utils/video_patterns';
import { FileDeposit } from './components/FileDeposit';
import { HUB_MODES } from '../constants';
import './ContentBar.scss';
import CircularProgress from '../../../components/charts/CircularProgress';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { Fragment } from 'react';
import QRCode from 'qrcode.react';
import { isMobile } from 'react-device-detect';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { squareColors } from '../../../styles/palette';

const { TEACHER_FOLDER_TO_EVAL: TO_EVAL, TEACHER_IN_PERSON: IN_PERSON } = HUB_MODES;

function ContentBar({ history, mode, data, isTeacher, isGAR, urlConnexionGar, token, isDemo, isPCL, isDemoSession }) {
  const onEvaluate = () => history.push('/join/' + data.token);

  const submissionsCount = data.participations.filter(p => !p.notSubmitted).length;
  const exercicesCount = !isDemo ? data.participations.length : data.participations.filter(p => p.isDemo).length;
  const hasSubmittedAll = submissionsCount === (isPCL && isDemoSession && !isDemo ? 1 : data.participations.length);

  const SessionsCount = () =>
    isTeacher || isMobile ? (
      <>
        <div id="th-sess-count" className="content-title">
          <div id="th-sess-num" className="content-title-big">
            {(exercicesCount < 10 ? '0' : '') + exercicesCount}
          </div>
          <div>EXERCICE{exercicesCount !== 1 ? 'S' : ''}</div>
        </div>
        {isTeacher && !isDemo && (
          <Link to="/sessionCreation" className="btn btn-primary mt-4">
            NOUVEL EXERCICE
          </Link>
        )}
      </>
    ) : (
      <div style={{ textAlign: 'left', padding: 20 }}>
        <div className="uppercase" style={{ fontWeight: 'bold', fontSize: 18 }}>
          Astuce
        </div>
        <div style={{ color: '#9B9B9B' }}>
          Enregistrez et envoyez vos travaux «&nbsp;S'entraîner à l'oral&nbsp;» depuis{' '}
          <strong>l'application mobile Mon oral Projet Voltaire.</strong>
        </div>
        {isGAR && (
          <div className="mt-2" style={{ color: '#9B9B9B' }}>
            Authentifiez-vous avec votre code 2D personnel :
          </div>
        )}
        <div className="text-center mt-4">
          {(urlConnexionGar || token) && <QRCode value={urlConnexionGar || token} />}
        </div>
      </div>
    );

  let thumbnail;
  if (mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL) {
    thumbnail = get_thumbnail_img(data);
  }

  const SessionDetail = () => (
    <div id="th-sess-detail">
      {mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL ? (
        thumbnail ? (
          <div>
            <img alt="Icone de la session" src={get_thumbnail_img(data)} width="100%" />
          </div>
        ) : (
          <div
            className="logo-todo logo-todo-ico"
            style={{
              backgroundColor: squareColors.purple,
              color: 'white'
            }}
          >
            <VideoLibraryIcon fontSize="inherit" />
          </div>
        )
      ) : (
        ''
      )}

      {mode !== TO_EVAL && (
        <div
          className="logo-todo logo-todo-ico"
          style={{
            backgroundColor: mode === IN_PERSON ? squareColors.yellow : squareColors.blue,
            color: 'white'
          }}
        >
          {data.prestationMode === 'audio' ? (
            <VolumeUpIcon fontSize="inherit" />
          ) : (
            <VideoLibraryIcon fontSize="inherit" />
          )}
        </div>
      )}

      <div id="th-sess-head">
        <div
          className={`pastille-lightblue small-text`}
          style={{
            backgroundColor:
              mode === IN_PERSON ? squareColors.yellow : mode === TO_EVAL ? squareColors.purple : squareColors.blue
          }}
        >
          {mode === IN_PERSON
            ? "S'entrainer en conditions d'examen"
            : mode === TO_EVAL
              ? 'Apprendre à évaluer'
              : "S'entraîner à l'oral"}
        </div>

        {isTeacher && (
          <div className={'mt-2 sess-info small-text ' + (hasSubmittedAll ? 'primary' : 'orange')}>
            {hasSubmittedAll ? (
              <CheckCircleIcon style={{ fontSize: 14 }} />
            ) : (
              <HourglassEmptyIcon style={{ fontSize: 14 }} />
            )}
            <div className="ml-1">
              <strong>
                {String(submissionsCount)}/{isPCL && isDemoSession && !isDemo ? 1 : String(data.participations.length)}
              </strong>{' '}
              rendu(s)
            </div>
          </div>
        )}

        <div className="subtitle small-text" style={{ fontSize: 13, marginTop: -8 }}>
          {mode === IN_PERSON
            ? `Publié le ${new Date(data.timeCreated || Date.now()).toLocaleDateString('fr-FR')}`
            : `À rendre le ${new Date(data.closeDate || Date.now()).toLocaleDateString('fr-FR')}`}
        </div>

        {data.description && (
          <div>
            <div className="subtitle">Consigne</div>
            <p style={{ fontSize: '0.8rem' }}>
              {data.description.split('\n').map((str, idx) => (
                <Fragment key={'r' + idx}>
                  {str}
                  <br />
                </Fragment>
              ))}
            </p>
          </div>
        )}

        {mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL && (isTeacher || (!isTeacher && !data.participated)) && (
          <div>
            <button className="btn btn-outline small" onClick={onEvaluate}>
              Recréer l'évaluation de référence
            </button>
          </div>
        )}
      </div>

      {!isTeacher && !data.session_token && mode === HUB_MODES.TEACHER_FOLDER_TO_DO && (
        <FileDeposit
          folder={data}
          onUpload={() => window.location.reload()}
          url={UPLOAD_FILE + '?token=' + data.token}
        />
      )}

      {isTeacher &&
        (mode !== HUB_MODES.TEACHER_FOLDER_TO_EVAL ? (
          <div className="text-center d-flex ai-c">
            <div className="th-grades">
              <div className="th-grade-progress">
                <CircularProgress
                  maxValue={20}
                  value={data.meanScore === undefined ? ' ' : data.meanScore}
                  withMaxIndicator
                />
              </div>
              <div className="mt-2">Moyenne finale</div>
              <div className="subtitle">
                <small>La moyenne des notes que vous avez validées et attribuées aux prestations de ce groupe.</small>
              </div>
            </div>
          </div>
        ) : (
          <div className="th-grades-section">
            <div className="p-2 th-grades" style={{ marginRight: '3.5em' }}>
              <div className="th-grade-progress">
                <CircularProgress maxValue={20} value={data.reference_score || 0} strokeWidth={25} withMaxIndicator />
              </div>
              <div className="text-strong no-break mt-2">Votre note de référence</div>
              <div className="subtitle">
                <small>
                  La note que vous avez attribuée à la prestation orale lors de votre évaluation de référence.
                </small>
              </div>
            </div>
            {/* <div className="p-2 th-grades">
              <div className="th-grade-progress">
                <CircularProgress
                  maxValue={20}
                  value={data.mean_score === undefined ? ' ' : data.mean_score}
                  strokeWidth={25}
                  withMaxIndicator
                />
              </div>
              <div className="text-strong no-break mt-2">Moyenne estimée</div>
              <div className="subtitle">
                <small>
                  La moyenne du point de vue des élèves : prenant en compte les notes calculées par leur propre
                  évaluation de la prestation orale.
                </small>
              </div>
            </div> */}
            <div className="p-2 th-grades">
              <div className="th-grade-progress">
                <CircularProgress
                  maxValue={20}
                  value={data.mean_grade === undefined ? ' ' : data.mean_grade}
                  withMaxIndicator
                />
              </div>
              <div className="text-strong no-break mt-2">Moyenne finale</div>
              <div className="subtitle">
                <small>
                  La moyenne des notes que vous avez validées et attribuées aux travaux d'évaluation de ce groupe.
                </small>
              </div>
            </div>
          </div>
        ))}
    </div>
  );

  const useVerticalLayout = isTeacher && mode !== HUB_MODES.TEACHER_HUB;

  return isTeacher && mode === HUB_MODES.TEACHER_HUB ? (
    ''
  ) : (
    <div className={!useVerticalLayout ? 'content-sidebar' : ''}>
      <div className={!useVerticalLayout ? 'th-left-bar' : 'th-session-detail'}>
        <div className={useVerticalLayout ? 'body-wrapper' : ''}>
          {mode === HUB_MODES.TEACHER_HUB ? <SessionsCount /> : <SessionDetail />}
        </div>
      </div>
    </div>
  );
}

export default connect(({ auth: { user: { teacher, userKind, urlConnexionGar, token } } }) => ({
  isTeacher: teacher,
  isGAR: userKind === 'woonoz',
  urlConnexionGar,
  token
}))(withRouter(ContentBar));
