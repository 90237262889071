import React from 'react';
import Hexagon from '../../../components/Hexagon';
import '../GridsList.scss';
import HexagonGroupFilter from '../../../components/HexagonGroupFilter';
import useHexagonGroup from '../../../hooks/useHexagonGroup';

const CreationList = ({ grids, onGridClick }) => {
  const [hexagonGroup, setHexagonGroup] = useHexagonGroup();
  const hexagons = grids.filter(
    g => !g.ref || g.ref.group === hexagonGroup || (hexagonGroup === 5 && g.ref.group === undefined)
  );

  return (
    <>
      <div className="subtitle text-center mt-4" style={{ fontSize: 18 }}>
        Quelle grille d'évaluation souhaitez-vous utiliser ?<br />
        Sélectionnez un modèle parmi les catégories proposées ou créez une nouvelle grille
        <br /> en la composant avec les critères de votre choix.
      </div>

      <div className="text-center mt-3">
        <HexagonGroupFilter currentGroup={hexagonGroup} onGroupUpdate={setHexagonGroup} />
      </div>

      <div className="hexagons-content p-2" style={{ overflow: 'auto' }}>
        <div className="hexagons-container" style={{ maxHeight: '100%', overflow: 'unset' }}>
          {hexagons.map((grid, key) => (
            <div
              key={'hg-' + key}
              id={'grid-create-' + (grid.ref && grid.ref.isPublic && grid.ref.group === 6 ? 'pcl' : key)}
              className="hexagons-cell hexagons-cell-hoverable m-3"
              style={{ flex: '1 1 22%', pointerEvents: 'none ' }}
              onClick={onGridClick(grid._id)}
            >
              <div id={key === hexagons.length - 1 ? 'gt-grid-empty' : ''}>
                <Hexagon
                  key={key}
                  phase={1}
                  onCellClick={onGridClick(grid._id)}
                  active={false}
                  evaluationControl={grid}
                  cellNumber={(grid.precision + 1) * 6}
                />
              </div>
              <div>
                <strong>{grid.name}</strong>
                <br />
                <small>{(grid.precision + 1) * 6 + 1} critères</small>
              </div>
            </div>
          ))}
          {new Array((hexagons.length % 3) + 1).fill().map(idx => (
            <div key={'h' + idx} className="hexagons-cell m-3" style={{ visibility: 'hidden', flex: '1 1 22%' }} />
          ))}
        </div>
      </div>
    </>
  );
};

export default CreationList;
