import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTheme } from '@material-ui/core/styles';
Chart.plugins.unregister(ChartDataLabels);
// eslint-disable-next-line import/first
import 'chartjs-plugin-annotation';

export default function BarChart(props) {
  const chartRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    const yAxes = [
      {
        id: 'args',
        type: 'linear',
        position: 'left',
        gridLines: { display: false, drawBorder: true },
        ticks: {
          min: 0,
          fontColor: theme.palette.text.primary,
          display: false
        }
      }
    ];

    chartRef.current = new Chart(chartRef.current, {
      type: 'bar',
      plugins: props.noDataLabels ? [] : [ChartDataLabels],
      options: {
        tooltips: {
          displayColors: false,
          callbacks: {
            title: function(tooltipItem, data) {
              const title = data.labels[tooltipItem[0].index] || '';
              const value = data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index] || '';

              return title + ' - ' + value + ' %';
            },
            label: function(tooltipItem, data) {
              const maxLength = 5;
              var label = data.datasets[tooltipItem.datasetIndex].description[tooltipItem.index] || '';
              const splitted = label.split(' ');
              let res = [];
              for (let i = 0; i < splitted.length; i += maxLength) {
                res.push(splitted.slice(i, i + maxLength).join(' '));
              }

              return res;
            }
          }
        },
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: 'white',
            font: {
              size: 14,
              weight: '900'
            },
            formatter: (value, context) => value + ' %'
          }
        },
        legend: {
          display: false,
          labels: {
            fontColor: 'white'
          }
        },
        scales: {
          yAxes,
          xAxes: [
            {
              gridLines: { display: false },
              ticks: { fontColor: theme.palette.text.primary }
              // maxBarThickness: 100
            }
          ]
        }
      },
      data: {
        labels: props.labels,
        datasets: props.datasets
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data.labels = props.labels;
      chartRef.current.data.datasets = props.datasets;
      chartRef.current.update();
    }
  }, [props.datasets, props.labels]);

  return <canvas ref={chartRef} />;
}

BarChart.defaultProps = {
  showLegend: true
};
