/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginDialog from '../pages/Auth/Login';
import RegisterDialog from '../pages/Auth/Register';

const withAuth = Component => {
  const WrappedComponent = props => {
    const login = 'login';
    const register = 'register';
    const help = 'help';
    const username = 'username';
    const reset = 'reset';

    const [show, setShow] = useState({
      [login]: false,
      [register]: false,
      [help]: false,
      [username]: false,
      [reset]: false
    });

    const onClose = key => () => setShow(show => ({ ...show, [key]: false }));
    const onOpen = key => () => setShow(show => ({ ...show, [key]: true }));

    const onGoRegister = () => setShow(show => ({ ...show, [login]: false, [username]: false, [register]: true }));
    const onResetPassword = () => setShow(show => ({ ...show, [reset]: true, [login]: false }));

    const [, setAccessToken] = useState('');
    const [, setOauthService] = useState('');

    const onSuccessfullyRegistered = () => {
      props.enqueueSnackbar('Inscription réussie ! Merci de vous connecter.', {
        variant: 'success',
        autoHideDuraction: 1500
      });

      setShow({ [register]: false, [login]: true });
    };

    const onOauthLogin = (token, service) => {
      setAccessToken(token);
      setOauthService(service);
      onOpen(username)();
    };

    useEffect(() => {
      if (window.location.pathname.includes("login")) {
        onOpen(login)();
      } else if (window.location.pathname.includes("register")) {
        onOpen(register)();
      }
    }, [window.location.pathname])

    return (
      <>
        {props.isAuthenticated ? (
          <div/>
        ) : (
          <>
            <LoginDialog
              open={show[login]}
              onUsername={onOauthLogin}
              onClose={onClose(login)}
              history={props.history}
              onGoRegister={onGoRegister}
              onResetPassword={onResetPassword}
            />
            <RegisterDialog
              open={show[register]}
              onClose={onClose(register)}
              onUsername={onOauthLogin}
              onSuccessfullyRegistered={onSuccessfullyRegistered}
              history={props.history}
            />
          </>
        )}

        <Component {...props} openLogin={onOpen(login)} openRegister={onOpen(register)} openHelp={onOpen(help)} />
      </>
    );
  };

  return connect(({ auth: { isAuthenticated } }) => ({
    isAuthenticated
  }))(withRouter(withSnackbar(WrappedComponent)));
};

export default withAuth;
