import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, auth, location, admin = false, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated &&
      (!admin || (admin && auth.user.admin)) &&
      (auth.currentGroup ||
        location.pathname.startsWith('/tutorialsAdmin') ||
        location.pathname.startsWith('/grilles') ||
        location.pathname.startsWith('/tutorials')) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(withRouter(PrivateRoute));
