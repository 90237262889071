import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import Routes from './Routes';
import NavBar from './components/layout/Navbar/Navbar';
import { SnackbarProvider } from 'notistack';
import getTheme from './styles/theme';
import { cleanErrors } from './services/error/actions';
import NavbarProvider from './hooks/context/NavbarProvider';
import AlertProvider from './hooks/context/AlertProvider';
import ErrorBoundary from './components/ErrorBoundary';
import { isIE } from 'react-device-detect';
import TutorialProvider from './hooks/context/TutorialProvider';

const useStyles = makeStyles(theme => ({
  white: { color: 'white' }
}));

const App = props => {
  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  const classes = useStyles();
  return (
    <MuiThemeProvider theme={getTheme(props.settings.theme)}>
      {/* <CssBaseline /> */}
      <BrowserRouter>
        <NavbarProvider>
          <AlertProvider>
            <TutorialProvider>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2500}
                classes={{
                  variantError: classes.white,
                  variantSuccess: classes.white,
                  variantWarning: classes.white,
                  variantInfo: classes.white
                }}
                onClose={handleCloseSnackBar}
              >
                <div style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                  <NavBar />

                  {isIE ? (
                    <div
                      style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        flexDirection: 'column',
                        fontSize: 18,
                        flexWrap: 'wrap'
                      }}
                    >
                      <div>
                        <b>
                          Afin de vous garantir un niveau de sécurité optimal et les meilleures conditions
                          d’utilisation, <br />
                          nous ne supportons pas la version actuelle de votre navigateur.
                        </b>
                      </div>
                      <div>
                        <b>Nous vous recommandons l'utilisation des navigateurs Google Chrome ou Mozilla Firefox.</b>
                      </div>
                    </div>
                  ) : (
                    <ErrorBoundary>{props.auth.isAuthenticated && <Routes />}</ErrorBoundary>
                  )}
                </div>
              </SnackbarProvider>
            </TutorialProvider>
          </AlertProvider>
        </NavbarProvider>
        {/* {!isMobileOnly && (
          <footer>
            <small>
              &copy; 2020, SAS DEROPA. <a href="/cgu">Mentions légales</a>.{' '}
              <a href="mailto:contact@retorate.com">Nous contacter</a>.
            </small>
          </footer>
        )} */}
      </BrowserRouter>
      {/* <CookieConsent buttonText={'Je comprends'}>
        Ce site web utilise des cookies pour améliorer l'expérience utilisateur.
      </CookieConsent> */}
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => ({
  settings: state.settings,
  errors: state.errors,
  auth: state.auth
});

export default connect(mapStateToProps, { cleanErrors })(App);
