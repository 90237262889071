import React, { useContext, useEffect } from 'react';
import { onboardingSteps } from '../../../utils/texts';
import HomeHeader from './HomeHeader';
import '../Home.scss';
import Hexagon from '../../../components/Hexagon';
import DummyVideo from '../../../styles/assets/dummy_video.png';
import SquareActionButton from '../../../components/SquareActionButton';
import EditIcon from '@material-ui/icons/Edit';
import { squareColors } from '../../../styles/palette';
import useApiPrepare from '../../../hooks/api/useApiPrepare';
import { SKIP_ONBOARDING } from '../../../services/api_routes';
import { useDispatch } from 'react-redux';
import { refreshToken } from '../../../services/auth/actions';
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import { TutorialContext } from '../../../hooks/context/TutorialProvider';
import { tutorialScreens } from '..//../../utils/texts';
import TutorialCard from '../../../components/TutorialCard';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const Onboarding = ({ dummy_grid, setIsRedirecting, isDemo }) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [, , skipOnboarding] = useApiPrepare(SKIP_ONBOARDING, {}, 'POST');
  const dispatch = useDispatch();
  const goToNextStep = () => setCurrentStep(currentStep + 1);

  const tutorialContext = useContext(TutorialContext);
  const tutorial = tutorialContext.tutorials[tutorialScreens.ONBOARDING];

  useEffect(() => {
    if (window.location.search) {
      const step = parseInt(window.location.search.split('=')[1]);
      if (step >= 0 && step < 3) {
        setCurrentStep(step);
      }
    }
  }, []);

  useEffect(() => {
    window.history.pushState({}, null, window.location.pathname + '?step=' + currentStep);
  }, [currentStep]);

  const onSkipOnboarding = (shouldPersist, redirect) => async () => {
    const res = await skipOnboarding({ shouldPersist });
    if (res.status === 200) {
      const { token } = res.data;
      setIsRedirecting(true);

      refreshToken(token, dispatch);

      window.location.href = redirect;
    }
  };

  const header = { title: "C'est parti !", subtitle: 'Découvrez la solution Mon oral.' };
  const steps = [
    {
      ...onboardingSteps[0],
      content: !dummy_grid ? (
        ''
      ) : (
        <div className="onboarding-hexagon">
          <Hexagon
            phase={1}
            onCellClick={() => {}}
            active={true}
            evaluationControl={dummy_grid}
            cellNumber={dummy_grid.argumentGroups[0].arguments.length * 6}
          />
          <div className="strong">{dummy_grid.name}</div>
          <div className="subtitle">{(dummy_grid.precision + 1) * 6 + 1} critères </div>
        </div>
      )
    },
    {
      ...onboardingSteps[1],
      content:
        tutorial && tutorial.length > 0 ? (
          <div className="onboarding-video">
            <TutorialCard {...tutorial[0]} />
          </div>
        ) : (
          <div className="onboarding-dummy-video">
            <img src={DummyVideo} alt="Dummy Video Onboarding" />
            <div className="uppercase">Très prochainement</div>
          </div>
        )
    },
    {
      ...onboardingSteps[2],
      content: (
        <div className="onboarding-exercises">
          <SquareActionButton
            icon={<EditIcon fontSize="inherit" />}
            title="Créer une activité"
            disabled={isDemo}
            // label={<>&nbsp;</>}
            color={squareColors.green}
            onClick={onSkipOnboarding(false, '/sessionCreation')}
          />
          <SquareActionButton
            title="Accéder au tableau de bord"
            icon={<KeyboardArrowRightIcon fontSize="inherit" />}
            style={{ padding: 5, fontSize: 12, color: squareColors.green }}
            color={'white'}
            iconStyle={{ color: squareColors.green, fontSize: '5em', backgroundColor: 'unset' }}
            onClick={onSkipOnboarding(false, '/home')}
          />
        </div>
      )
    }
  ];

  const OnboardingStep = ({ title, subtitle, content }) => (
    <div className="onboarding-step">
      <div className="hex-phase-bubbles">
        {steps.map((_, idx) => (
          <div className={currentStep === idx ? 'hex-bubble-current' : ''}>{idx + 1}</div>
        ))}
      </div>

      <div>
        <div className="strong uppercase">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>

      <div className="d-flex jc-c">{content}</div>

      {currentStep !== steps.length - 1 && (
        <div>
          <button className="btn btn-primary onboarding-next" onClick={goToNextStep}>
            Suivant
          </button>
        </div>
      )}
    </div>
  );

  const SkipButton = (
    <button onClick={onSkipOnboarding(false, '/home')} className="btn btn-outline onboarding-skip small">
      Ignorer l'introduction
    </button>
  );

  return (
    <div>
      <HomeHeader {...header} content={SkipButton} style={{ position: 'relative' }} />

      <div className="onboarding">
        <OnboardingStep {...steps[currentStep]} />
      </div>
    </div>
  );
};

export default Onboarding;
