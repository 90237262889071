import React from 'react';
import Hexagon from '../../../../components/Hexagon';
import { defaultGlobalAppreciationLabels } from '../../../../utils/texts';

const GridCreationP2 = ({ initialAppreciations, editedAppreciations, setEditedAppreciations }) => {
  const onNameChange = (idx, value) => {
    const globalAppreciationLabels = [...editedAppreciations];
    globalAppreciationLabels[idx] = value;

    setEditedAppreciations([...globalAppreciationLabels]);
  };

  const onReset = () => {
    setEditedAppreciations([...defaultGlobalAppreciationLabels]);
  };

  return (
    <div>
      <div className="gcr-p2-top">
        <div className="gcr-p2-pad"></div>

        <div className="gcr-p2-title">
          <h3 className="gcr-title">Appréciation Générale</h3>
          <div className="subtitle text-center">Cliquez sur un critère pour le modifier</div>
        </div>

        <div className="gcr-p2-btn">
          <button className="btn btn-outline small" onClick={onReset}>
            Réinitialiser
          </button>
        </div>
      </div>

      <Hexagon
        phase={2}
        active={false}
        evaluationControl={{ globalAppreciationLabels: editedAppreciations }}
        phase2Selection={new Array(12).fill(0)}
        onCellClick={() => {}}
        isEditing={true}
        onEdit={onNameChange}
      />
    </div>
  );
};

export default GridCreationP2;
