import { GET_ERRORS, CLEAN_ERRORS } from './actions';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      if (action.payload === 'Forbidden') return { err: "Il faut d'abord vous connecter ou vous inscrire." };
      if (action.payload && Object.keys(action.payload).length > 5)
        return { server: 'Il y a eu une erreur interne au serveur.' };
      return action.payload;
    case CLEAN_ERRORS:
      return {};
    default:
      return state;
  }
}
