export default class Timer {
  constructor(callback, duration = -1, interval = 1) {
    this.time = 0;
    this.interval = interval;
    this.paused = false;
    this.timer = undefined;
    this.callback = callback;
  }

  tick = () => {
    if (!this.paused) {
      this.time += 1;

      if (this.duration >= 0 && this.time === this.duration) {
        this.stop();
      } else {
        this.callback('tick', this.time);
      }
    }
  };

  start() {
    this.timer = setInterval(this.tick, 1000);
  }

  pause() {
    this.paused = true;
  }

  resume() {
    this.paused = false;
  }

  stop() {
    clearInterval(this.timer);
    this.callback('stopped', this.time);
  }
}
