/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import useApiPrepare from '../../hooks/api/useApiPrepare';
import useApi from '../../hooks/api/useApi';
import { CREATE_FOLDER, GET_ALL_HEXAGONS, CREATE_DEBATE, EDIT_FOLDER } from '../../services/api_routes';
import { FormControl, LinearProgress, FormControlLabel, MenuItem, makeStyles, Radio } from '@material-ui/core';
import MuiSelect from '../../components/forms/MuiSelect';
import MuiDatePicker from '../../components/forms/MuiDatePicker';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import TextField from '../../components/TextInput';
import './FolderCreation.scss';
import NavigationBar from '../../components/NavigationBar';
import { connect, useSelector } from 'react-redux';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';
import { canPlayFile, get_thumbnail_img } from '../../utils/video_patterns';
import Dialog from '../../components/dialog/Dialog';
import axios from 'axios';
import { theme } from '../../styles/ui_theme';
import useHexagonGroup from '../../hooks/useHexagonGroup';
import { hexagonGroupPCL, hexagonGroups, tutorialScreens } from '../../utils/texts';
import debate_logo from '../../styles/assets/icons/debate_logo.png';
import withTutorial from '../../hooks/withTutorial';
import { TutorialContext } from '../../hooks/context/TutorialProvider';

const useStyles = makeStyles(_ => ({
  bar: { backgroundColor: theme.palette.primary },
  root: { height: 10, backgroundColor: '#F4F4F4' }
}));

const FolderCreation = ({ mode, group, ...props }) => {
  const classes = useStyles();

  const [hexagonGroup, setHexagonGroup] = useHexagonGroup();
  const closeDate = new Date(Date.now());
  closeDate.setDate(closeDate.getDate() + 14);

  const tutorialContext = useContext(TutorialContext);

  const { register, handleSubmit, watch, setValue, getValues, control, reset } = useForm({
    defaultValues: {
      mode: (props.location.state && props.location.state.debateMode) || 'grand-oral',
      closeDate,
      allowPeerEvaluation: false,
      diffusionLink: ''
    }
  });
  const [, ,] = useApiPrepare(CREATE_FOLDER, [], 'post');
  const [, ,] = useApiPrepare(CREATE_DEBATE, [], 'post');
  const [participants, setParticipants] = React.useState([{ name: '', pic: '' }]);
  const [showCreationModal, setShowCreationModal] = React.useState(false);
  const [createdToken, setCreatedToken] = React.useState('');
  const [prestationMode, setPrestationMode] = React.useState('video');
  const [diffusionFile, setDiffusionFile] = React.useState(null);

  const [uploadState, setUploadState] = React.useState({ uploading: false, progress: 0 });

  const uploadRef = React.useRef();
  const { isPCL: isUserPCL } = useSelector(state => state.auth.user);

  const watchControlName = watch('evaluationControlName');
  const watchURL = watch('diffusionLink');

  const isEditing = props.location.state ? props.location.state.isEditing : false;
  const isCloning = props.location.state ? props.location.state.session && !isEditing : false;

  // Data fetching
  const [folderData, loadingEvaluationControls] = useApi(GET_ALL_HEXAGONS + '?group=' + group + '&mode=' + mode, {});

  const evaluationControls = folderData.evaluationControls || [];
  const filteredControls = evaluationControls.filter(
    hex => hex.group === hexagonGroup && hex.availablePrecisions.length > 0
  );

  const getSelectedEvaluationControl = React.useCallback(() => {
    const controlName = getValues('evaluationControlName');
    const controlIdx = filteredControls.findIndex(ec => ec.name === controlName);

    if (controlIdx > -1) return filteredControls[controlIdx].availablePrecisions;
    return [];
  }, [evaluationControls, getValues]);

  useEffect(() => {
    if (
      !(props.location.state && props.location.state.session) &&
      !loadingEvaluationControls &&
      filteredControls.length > 0
    ) {
      setValue('evaluationControlName', filteredControls[0]._id + '-' + filteredControls[0].availablePrecisions[0]);
    }
  }, [evaluationControls, loadingEvaluationControls, props.location.state, setValue, hexagonGroup]);

  useEffect(() => {
    const precisions = getSelectedEvaluationControl();
    setValue('evaluationPrecision', precisions[Math.max(0, Math.floor(precisions.length / 2 - 1))]);
  }, [getSelectedEvaluationControl, setValue, watchControlName]);

  useEffect(() => {
    if (props.location.state !== undefined && props.location.state.session !== undefined) {
      const { session } = props.location.state;
      const { mode, prestationMode, evaluationControl, ...rest } = session;

      const new_state = rest;

      const closeDateT = new Date(rest.closeDate);
      closeDateT.setHours(0, 0, 0, 0);
      const todayT = new Date(Date.now());
      todayT.setHours(0, 0, 0, 0);

      if (!isEditing && todayT > closeDateT) {
        rest.closeDate = closeDate;
      }

      reset(new_state);

      if (session.mode === 'to_do') {
        setPrestationMode(session.prestationMode);
      } else {
        if (!isEditing && session.participants) {
          setParticipants([...session.participants]);
        }
      }
    }
  }, [isEditing, props.location.state, reset]);

  useEffect(() => {
    tutorialContext.setScreen(
      mode === 'to_do'
        ? tutorialScreens.EXERCISES_CREATION_TO_DO
        : mode === 'to_eval'
        ? tutorialScreens.EXERCISES_CREATION_TO_EVALUATE
        : tutorialScreens.EXERCISES_CREATION_PRESENTIAL
    );
    tutorialContext.setStyle({ maxWidth: '60em', margin: '0 auto' });

    return () => {
      tutorialContext.setScreen('');
      tutorialContext.setStyle({});
    };
  }, [mode]);

  const speakers_to_teams = speakers =>
    speakers.map(speaker => ({ name: '', speakers: [speaker.name], interviewers: [false], pic: speaker.pic }));

  const create_folder = async data => {
    data.group = group;

    data.prestationMode = prestationMode;
    if (data.evaluationControlName) {
      data.evaluationPrecision = data.evaluationControlName.split('-')[1];
      data.evaluationControl = data.evaluationControlName.split('-')[0];
    }
    data.mode = mode;
    delete data.evaluationControlName;

    if (mode === 'to_eval') data.teams = speakers_to_teams(participants);

    if (!data.diffusionLink) data.diffusionLink = '';

    const cancelSource = axios.CancelToken.source();
    try {
      if (!isEditing) {
        // if it is a session to eval, use form data to upload files together with the data
        if (mode === 'to_eval') {
          const form_data = new FormData();
          Object.keys(data).forEach(key => form_data.append(key, JSON.stringify(data[key])));

          participants.forEach((p, idx) => {
            if (p.pic) form_data.append(idx, p.pic);
          });

          if (diffusionFile) form_data.append('diffusionFile', diffusionFile);

          if (isCloning) {
            form_data.append('isCloning', true);
            form_data.append('baseToken', props.location.state.session.shareCode);
          }

          data = form_data;
          setUploadState({ uploading: true, progress: 0, cancelSource });
        }

        const res = await axios.post(mode === 'to_eval' ? CREATE_DEBATE : CREATE_FOLDER, data, {
          validateStatus: s => s < 401,
          cancelToken: cancelSource.token,
          onUploadProgress: progressEvent => {
            setUploadState(s => ({ ...s, progress: (progressEvent.loaded / progressEvent.total) * 100 }));
          }
        });

        if (res.status === 200) {
          if (mode === 'to_eval' && !isCloning) {
            setShowCreationModal(false);
            props.history.push('/join/' + res.data.token);
          } else {
            props.history.replace(
              (mode === 'to_eval' ? '/evals/' : mode === 'in_person' ? '/presentiel/' : '/folders/') + res.data.token
            );
          }
          props.enqueueSnackbar("L'activité a bien été créée.", { variant: 'success', duration: 4000 });
        } else {
          props.enqueueSnackbar(res.data.error, { variant: 'error', duration: 4000 });
          setShowCreationModal(false);
          setUploadState({ uploading: false, progress: 0 });
        }
      } else {
        data.token = props.location.state.session.token;

        const res = await axios.post(
          EDIT_FOLDER,
          { session: data, folder_mode: mode },
          { validateStatus: s => s < 401 }
        );
        if (res.status === 200) {
          props.history.replace(
            (mode === 'to_eval' ? '/evals/' : mode === 'in_person' ? '/presentiel/' : '/folders/') + res.data.token
          );
          props.enqueueSnackbar("L'activité a bien été modifiée.", { variant: 'success', duration: 3000 });
        } else {
          props.enqueueSnackbar(res.data.error, { variant: 'error', duration: 4000 });
        }
      }
    } catch (err) {
      props.enqueueSnackbar("Il y a eu une erreur à la création de l'activité.", { variant: 'error', duration: 4000 });
    }
  };

  const onSubmit = data => {
    if (!isEditing && !isCloning && mode === 'to_eval') {
      if (!data.title || participants.some(p => !p.name) || !(data.diffusionLink || diffusionFile)) {
        props.enqueueSnackbar('Tous les champs obligatoires doivent être renseignés.', {
          variant: 'error',
          duration: 4000
        });
        return;
      } else if (folderData.titles.includes(data.title)) {
        props.enqueueSnackbar('Une activité avec ce titre existe déjà.', { variant: 'error', duration: 4000 });
        return;
      } else if (participants.some(p => p.name.includes('.'))) {
        props.enqueueSnackbar('Les noms des intervenants ne doivent pas contenir de points.', {
          variant: 'error',
          duration: 6000
        });
        return;
      } else {
        setCreatedToken({ ...data });
        setShowCreationModal(true);
      }
    } else {
      if (isCloning && props.location.state.session) {
        // edge case: diffusion link is not copied in reset
        data.diffusionLink = props.location.state.session.diffusionLink;
      }

      create_folder(data);
    }
  };

  const onCancelUpload = () => {
    if (uploadState.cancelSource) uploadState.cancelSource.cancel();
    setUploadState({ uploading: false });
    setShowCreationModal(false);
  };

  const removeParticipant = idx => e => {
    setParticipants([...participants.slice(0, idx).concat(participants.slice(idx + 1, participants.length))]);
  };

  const onUploadImage = idx => e => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].size < 5 * Math.pow(10, 6)) {
        setParticipants([
          ...participants.slice(0, idx),
          { ...participants[idx], pic: e.target.files[0] },
          ...participants.slice(idx + 1, participants.length)
        ]);
      } else {
        alert('Ce fichier est trop lourd. Les images ne doivent pas dépasser 5Mo.');
      }
    }
  };

  const onUploadDiffusionFile = e => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].size <= 50 * Math.pow(10, 6)) {
        setDiffusionFile(e.target.files[0]);
        setValue('diffusionLink', '');
      } else {
        alert('Ce fichier est trop lourd, il ne doit pas dépasser 50Mo.');
      }
    }
  };

  const onRemoveImage = idx => e => {
    e.stopPropagation();
    e.preventDefault();

    setParticipants(participants => {
      participants[idx].pic = '';
      return [...participants];
    });
  };

  const isLinkValid = canPlayFile(createdToken.diffusionLink);
  const isFileValid = diffusionFile !== null && canPlayFile(diffusionFile.name);

  const headerLabels = {
    to_do: "S'entraîner à l'oral",
    to_eval: 'Apprendre à évaluer',
    in_person: "S'entraîner en conditions d'examen"
  };

  return loadingEvaluationControls ? (
    ''
  ) : (
    <div>
      <NavigationBar title={headerLabels[mode]} to={isEditing ? '/list' : '/sessionCreation'} />

      <div className="fcrea-wrapper">
        <Dialog
          open={showCreationModal}
          onClose={() => {
            setShowCreationModal(false);
            onCancelUpload();
          }}
          title={isLinkValid || isFileValid ? "Lancer l'évaluation de référence" : 'Lien invalide'}
          content={
            <div className="text-center">
              <div className="subtitle">
                {diffusionFile !== null && !isFileValid ? (
                  <>Ce type de fichier n'est pas supporté</>
                ) : isFileValid || isLinkValid ? (
                  <>
                    Vous devez évaluer la prestation maintenant pour valider <br />
                    la création de votre prestation. Votre évaluation servira <br />
                    de référence pour établir les notes de vos élèves.
                  </>
                ) : createdToken.diffusionLink ? (
                  <>
                    La source choisie n'est pas valide. Il faut un lien vers un fichier
                    <br />
                    audio ou vidéo valide, ou une vidéo Youtube ou Dailymotion.
                  </>
                ) : (
                  <>Il faut compléter l'ensemble des champs obligatoires.</>
                )}
              </div>

              {!uploadState.uploading ? (
                <button
                  className="btn btn-primary mt-3"
                  onClick={() => {
                    if (isLinkValid || isFileValid) {
                      create_folder(createdToken);
                    } else {
                      setShowCreationModal(false);
                    }
                  }}
                >
                  {isLinkValid ? 'ÉVALUER' : 'OK'}
                </button>
              ) : (
                <div>
                  <div className="mt-4">
                    <LinearProgress classes={classes} value={uploadState.progress} variant="determinate" />
                  </div>
                  <div className="subtitle mb-4">Transfert en cours {Math.round(uploadState.progress)}%</div>
                  <button className="btn btn-outline" onClick={onCancelUpload}>
                    Annuler
                  </button>
                </div>
              )}
            </div>
          }
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            name="title"
            label="Le titre pour cette activité *"
            placeholder="Titre"
            inputRef={register}
            style={{ fontWeight: 700, fontSize: 25 }}
          />
          {mode === 'to_eval' && !isEditing && (
            <>
              <label className="lightblue" style={{ marginTop: '10px' }}>
                La source de la prestation orale à évaluer *
              </label>
              <div>
                <small>Entrez l'adresse d'une source en ligne</small>
              </div>
              <div className="fcrea-dlink">
                <div className="fcrea-dlink-input">
                  <div>
                    <TextField
                      name="diffusionLink"
                      placeholder="Lien URL"
                      className="fullwidth"
                      inputRef={register}
                      disabled={diffusionFile !== null || isCloning}
                      style={{ fontWeight: 700 }}
                    />
                  </div>
                  <div className="mt-2">
                    <div>
                      <small>Ou importez un fichier</small>
                    </div>
                    <div>
                      {diffusionFile === null ? (
                        <>
                          <input
                            ref={uploadRef}
                            type="file"
                            style={{ display: 'none' }}
                            accept={'video/*, audio/*'}
                            onChange={onUploadDiffusionFile}
                          />

                          <button
                            onClick={() => uploadRef.current.click()}
                            type="button"
                            className="btn mt-1 small btn-primary"
                            style={{ width: '12em' }}
                            disabled={isCloning}
                          >
                            Importer
                          </button>
                        </>
                      ) : (
                        <div className="imported-file">
                          <div>{diffusionFile.name}</div>
                          <div>
                            <button className="btn btn-icon btn-text" onClick={() => setDiffusionFile(null)}>
                              <ClearIcon fontSize="small" />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <img
                    alt="thumbnail"
                    src={get_thumbnail_img({ mode: 'to_eval', diffusionLink: watchURL }) || debate_logo}
                  />
                </div>
              </div>
            </>
          )}

          <div className="fcrea-content">
            <div style={{ height: '100%' }}>
              <TextField
                fullHeight
                name="description"
                style={{ height: '100%', fontWeight: 700 }}
                rows={5}
                placeholder="Texte libre"
                label="La consigne ou la description du travail attendu"
                multiline
                inputRef={register}
                className="creation-consigne mr-2"
              />
            </div>

            <div className="fullwidth">
              {!isEditing && (
                <>
                  <div>
                    <label className="lightblue">Catégorie de grilles *</label>
                    <div className="creation-grid fullwidth">
                      <MuiSelect
                        value={hexagonGroup}
                        onChange={e => setHexagonGroup(e.target.value)}
                        name="evaluationControlGroup"
                        className="fullwidth"
                        disabled={isCloning && mode === 'to_eval'}
                      >
                        {isUserPCL ? (
                          <MenuItem key={'grp-' + hexagonGroupPCL} value={6}>
                            {hexagonGroupPCL}
                          </MenuItem>
                        ) : hexagonGroups.map((grp, idx) => (
                          <MenuItem key={'grp-' + grp} value={idx}>
                            {grp}
                          </MenuItem>
                        ))}
                      </MuiSelect>
                    </div>
                  </div>
                  <div>
                    <label className="lightblue mt-4">La grille d'évaluation à associer à l'activité *</label>
                    <div className="creation-grid fullwidth">
                      {filteredControls.length > 0 ? (
                        <MuiSelect
                          control={control}
                          name="evaluationControlName"
                          className="fullwidth"
                          disabled={isCloning && mode === 'to_eval'}
                        >
                          {filteredControls.reduce(
                            (acc, ec) =>
                              acc.concat(
                                ec.availablePrecisions.map(precision => (
                                  <MenuItem key={'evmode' + ec.name + '-' + precision} value={ec._id + '-' + precision}>
                                    <span style={{ fontWeight: 700, marginRight: 5 }}>{ec.name}</span> (
                                    {(precision + 1) * 6 + 1} critères)
                                  </MenuItem>
                                ))
                              ),
                            []
                          )}
                        </MuiSelect>
                      ) : (
                        <div className="mt-1">Il n'y a aucune grille dans cette catégorie.</div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* <div>
                <label className="lightblue">Précision *</label>
                <MuiSelect name="evaluationPrecision" className="creation-evaluation fullwidth" control={control}>
                    {getSelectedEvaluationControl().map(p => (
                      <MenuItem key={'prec' + p} value={p}>
                        {precisionLabels[p]}
                      </MenuItem>
                    ))}
                </MuiSelect>
              </div> */}
            </div>
          </div>

          {mode !== 'in_person' && (
            <div className="fcrea-content">
              <div>
                <label className="lightblue">La date à laquelle les élèves doivent rendre ce travail *</label>
                <div>
                  <MuiDatePicker
                    name="closeDate"
                    control={control}
                    className="fullwidth"
                    style={{ fontWeight: 700 }}
                    minDate={
                      isEditing && props.location.state.session
                        ? new Date(props.location.state.session.closeDate)
                        : Date.now()
                    }
                  />
                </div>
              </div>
              <div></div>
            </div>
          )}

          {!isEditing && mode === 'to_eval' && (
            <div className="fcrea-participants">
              {participants.map((participant, idx) => (
                <div className="fcrea-participant" key={'fcrea-' + idx}>
                  <div className="fcrea-participant-inner">
                    <div style={{ flex: 1 }}>
                      <label htmlFor={'img-input-' + idx}>
                        {participant.pic ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              alt="Logo Participant"
                              className="img-input"
                              style={{ display: 'block' }}
                              src={
                                participant.pic
                                  ? participant.pic.startsWith && participant.pic.startsWith('http')
                                    ? participant.pic
                                    : URL.createObjectURL(participant.pic)
                                  : ''
                              }
                            />
                            <button
                              type="button"
                              className="btn btn-icon btn-text img-button"
                              onClick={onRemoveImage(idx)}
                            >
                              <ClearIcon fontSize="inherit" />
                            </button>
                          </div>
                        ) : (
                          <div className="img-input">
                            <div>PHOTO</div>
                          </div>
                        )}
                      </label>
                      <input id={'img-input-' + idx} type="file" accept="image/*" onChange={onUploadImage(idx)} />
                    </div>

                    {/* <div><button onClick={removeParticipant(idx)} type="button" className="btn-text subtitle" style={{ backgroundColor: 'red'}}>Wesh alors <ClearIcon  /></button></div> */}
                    <div style={{ flex: 4 }}>
                      <div>
                        {participants.length > 1 && !isCloning && (
                          <button
                            onClick={removeParticipant(idx)}
                            type="button"
                            className="btn-text subtitle nopad"
                            style={{ float: 'right' }}
                          >
                            <ClearIcon fontSize="small" />
                          </button>
                        )}
                      </div>
                      <div>
                        <b className="darkblue">INTERVENANT {idx + 1}</b>
                      </div>
                      <div>
                        <TextField
                          label="Le nom de l'orateur *"
                          placeholder="Nom"
                          value={participants[idx].name}
                          onChange={e =>
                            setParticipants([
                              ...participants.slice(0, idx),
                              { ...participants[idx], name: e.target.value },
                              ...participants.slice(idx + 1, participants.length)
                            ])
                          }
                          style={{ fontWeight: 700 }}
                          disabled={isCloning}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <button
                type="button"
                className="btn fcrea-participant text-center"
                onClick={() => setParticipants([...participants, { name: '', pic: '' }])}
                disabled={isCloning}
              >
                <div className="fcrea-participant-inner fcrea-participant-empty">
                  <div>
                    <AddCircleIcon fontSize="inherit" className="fcrea-plus primary" />
                  </div>
                  <div>Ajouter un intervenant</div>
                  <div className="subtitle" style={{ textTransform: 'initial', marginTop: -10 }}>
                    S'il y a un autre orateur dans cette prestation.
                  </div>
                </div>
              </button>
            </div>
          )}

          {mode === 'to_do' && (
            <div>
              <div>
                <label className="lightblue">Type de prestation *</label>
              </div>
              <div>
                <FormControl>
                  <FormControlLabel
                    label="Prestation vidéo"
                    control={
                      <Radio
                        checked={prestationMode === 'video'}
                        color="secondary"
                        onChange={() => setPrestationMode('video')}
                        name="mode_video"
                      />
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    label="Prestation audio uniquement (pas de caméra)"
                    control={
                      <Radio
                        checked={prestationMode === 'audio'}
                        onChange={() => setPrestationMode('audio')}
                        name="mode_audio"
                      />
                    }
                  />
                </FormControl>
              </div>
            </div>
          )}

          <div className="fcrea-footer">
            <div>
              <small className="subtitle">Tous les champs marqués d'un astérisque (*) sont obligatoires.</small>
            </div>
            <button className="btn btn-primary" type="submit">
              Valider
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(({ auth }) => ({ group: auth.currentGroup.token }))(withSnackbar(withRouter(FolderCreation)));
