import {
  CONNECT_TO_DEBATE,
  GET_EVALUATION_CONTROL,
  GET_DEBATE_RESULTS,
  GET_ALL_ARGUMENTS,
  FETCH_COMMENTS,
  POST_COMMENT,
  CLEAR_DEBATE_RESULTS,
  CLEAR_DEBATE,
  GET_DEBATE_METADATA
} from './actions';

const initialState = {
  argumentsList: [],
  title: '',
  timeMode: 'chrono',
  maxTime: 0,
  speakers: [],
  teams: [],
  numMembers: 0,
  isCreator: false,
  active: false,
  phase: 1,
  connected: false,
  evaluationControl: {},
  evaluationControlP2: {},
  evaluationControlInterviewer: {},
  loading: true,
  results: {},
  description: '',
  withTeams: false,
  diffusionLink: '',
  direct: false,
  debateXp: {},
  comments: [],
  evaluationSession: '',
  reevaluationCount: 0,
  mode: 'debate'
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CONNECT_TO_DEBATE:
      return {
        ...state,
        ...action.payload,
        loading: false,
        connected: true
      };
    case GET_EVALUATION_CONTROL:
      return {
        ...state,
        evaluationControl: action.payload.controlP1,
        evaluationControlP2: action.payload.controlP2,
        evaluationControlInterviewer: action.payload.controlInterviewer,
        loading: false
      };
    case GET_DEBATE_RESULTS:
      return {
        ...state,
        results: action.payload
      };
    case CLEAR_DEBATE_RESULTS:
      return {
        ...state,
        results: {}
      };
    case CLEAR_DEBATE:
      return initialState;
    case GET_ALL_ARGUMENTS:
      return {
        ...state,
        argumentsList: action.payload
      };
    case GET_DEBATE_METADATA:
      return {
        ...state,
        ...action.payload
      };
    // case GET_EXPERIENCE:
    //   return {
    //     ...state,
    //     debateXp: action.payload
    //   };
    case FETCH_COMMENTS:
      return {
        ...state,
        comments: action.payload
      };
    case POST_COMMENT:
      return {
        ...state,
        comments: [action.payload, ...state.comments]
      };
    default:
      return state;
  }
}
