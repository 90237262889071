import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import blueGrey from '@material-ui/core/colors/blueGrey';

const getTheme = (variant = 'light') =>
  createMuiTheme({
    palette: {
      background: {
        default: '#ffffff',
        paper: '#EAEAEA'
      },
      primary: {
        light: purple[400],
        main: "#000000",
        dark: purple[900]
      },
      secondary: {
        light: blueGrey[300],
        main: "#A2D146",
        dark: blueGrey[700]
      },
      type: 'light'
    },
    overrides: {
      MuiDialogTitle: {
        root: {
          paddingBottom: 2,
          paddingTop: 6
        }
      }
    }
  });

export default getTheme;
