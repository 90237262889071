import React from 'react';
import { HUB_MODES } from '../../constants';
import SearchIcon from '@material-ui/icons/Search';
import { Select, MenuItem } from '@material-ui/core';

const DEFAULT_SESSION_SORTS = [
  { v: 'date_asc', n: 'Tri par date de création croissant' },
  { v: 'date_desc', n: 'Tri par date de création décroissant' },
  { v: 'name_asc', n: 'Tri par ordre alphabétique croissant' },
  { v: 'name_desc', n: 'Tri par ordre alphabétique décroissant' }
];

const SESSION_SORTS = mode =>
  mode === HUB_MODES.TEACHER_FOLDER_TO_DO || mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL
    ? [
        ...DEFAULT_SESSION_SORTS,
        { v: 'grade_asc', n: 'Tri par note croissant' },
        { v: 'grade_desc', n: 'Tri par note décroissant' }
      ]
    : [
        ...DEFAULT_SESSION_SORTS,
        { v: 'deadline_asc', n: 'Tri par date limite de dépôt croissant' },
        { v: 'deadline_desc', n: 'Tri par date limite de dépôt décroissant' }
      ];

const SessionFilters = ({ onChangeSort, onChangeQuery, currentSort, currentQuery, mode }) => (
  <div className="slist-filter-bar">
    <Select onChange={onChangeSort} value={currentSort} style={{ width: '100%', flex: 1 }}>
      {SESSION_SORTS(mode).map((sort, idx) => (
        <MenuItem value={sort.v} key={sort.v}>
          {sort.n}
        </MenuItem>
      ))}
    </Select>
    <div className="slist-bi">
      <span>
        <SearchIcon fontSize="inherit" />
      </span>
      <input
        type="text"
        placeholder="Rechercher..."
        value={currentQuery}
        onChange={onChangeQuery}
        className="fullwidth"
      />
    </div>
  </div>
);

const SESSION_TYPES = (mode, isTeacher) =>
  isTeacher
    ? mode === HUB_MODES.TEACHER_HUB
      ? [
          { n: 'Tous', v: 'all' },
          { v: 'to_eval', n: 'Apprendre à évaluer' },
          { v: 'to_do', n: "S'entraîner à l'oral" },
          { v: 'in_person', n: "S'entraîner en conditions d'examen" },
          { v: 'finished', n: 'Date limite passée' }
        ]
      : [
          { n: 'Tous', v: 'all' },
          { n: 'À corriger', v: 'to_evaluate' },
          { n: 'Corrigés', v: 'evaluated' },
          { n: 'Non rendus', v: 'not_submitted' }
        ]
    : [{ n: 'Tous', v: 'all' }];

const SessionTypeFilter = ({ onChange, current, mode, isAllGraded, onValidateGrades, isTeacher }) => (
  <div className="slist-session-type">
    {SESSION_TYPES(mode, true).map(st => (
      <button
        key={st.v}
        onClick={onChange(st.v)}
        style={{ textTransform: 'none' }}
        className={`btn small ${current === st.v ? 'btn-primary' : 'btn-outline'} no-focus`}
      >
        {st.n}
      </button>
    ))}
    {mode !== HUB_MODES.TEACHER_HUB && isTeacher && !isAllGraded && (
      <>
        <span style={{ flexGrow: 1 }} />
        <button className="btn small btn-primary" style={{ flexAlign: 'flex-end' }} onClick={onValidateGrades}>
          Valider toutes les notes
        </button>
      </>
    )}
  </div>
);

const SessionsFiltersRow = ({ callbacks, currentState, mode, isAllGraded, onValidateGrades, isTeacher }) => (
  <>
    <SessionFilters
      mode={mode}
      currentQuery={currentState.query}
      currentSort={currentState.sort}
      onChangeQuery={callbacks.onChangeQuery}
      onChangeSort={callbacks.onChangeSort}
    />
    <SessionTypeFilter
      mode={mode}
      isTeacher={isTeacher}
      onChange={callbacks.onChangeSessionType}
      current={currentState.session_type}
      onValidateGrades={onValidateGrades}
      isAllGraded={isAllGraded}
    />
  </>
);

export default SessionsFiltersRow;
