/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HexagonCellWrapper from './components/HexagonCellWrapper';
import ArgumentDescriptionModal from './components/ArgumentDescriptionModal';
import { hexagonColors } from '../../styles/palette';
import wrapText from '../../utils/wrapText';
import WrappedText from './WrappedText';

const pointToString = point => `${point.x},${point.y}`;
const pointsToString = points => points.reduce((str, point) => str + pointToString(point) + ' ', '');

const HexagonBuzzer = ({
  commenting,
  currentComment,
  onOpenComment,
  title,
  argument,
  description,
  onClick,
  onLongPress,
  active,
  group,
  className,
  someoneFlashing,
  isFlashing,
  isEditing,
  isReplay
}) => {
  // const center = { x: 200, y: 173 };

  const hexagonCellStyle = {
    opacity: 1,
    // fill: someoneFlashing && !isFlashing ? hexagonColorsGrayscal[argument.pointsGroup] : hexagonColors[argument.pointsGroup],
    fill: someoneFlashing && isFlashing && argument.pointsGroup === 5 ? '#BBB' : hexagonColors[argument.pointsGroup],
    filter: someoneFlashing && !isFlashing ? 'url(#grayscalee)' : '',
    stroke: 'white',
    strokeWidth: isFlashing ? 5 : someoneFlashing ? 5 : 5
  };

  return (
    <g
      className={active ? (isFlashing ? 'hexagon-cell-no-filter' : 'hexagon-cell') : ''}
      style={{ pointerEvents: 'all' }}
    >
      <polygon
        points="175.5,130.5 224.5,130.5 249,173 224.5,215.5 175,215.5 151,173"
        style={{ ...hexagonCellStyle, opacity: someoneFlashing && !isFlashing ? 0.5 : 1 }}
      />

      <g>
        <HexagonCellWrapper
          isBuzzer
          active={active}
          flipText={false}
          argument={argument}
          onClick={onClick}
          onLongPress={onLongPress}
          isFlashing={isFlashing}
          currentComment={currentComment}
          isReplay={isReplay}
          someoneFlashing={someoneFlashing}
          onOpenComment={onOpenComment}
          tooltipPlacement=""
          commenting={commenting}
        >
          <g>
            <rect className="shape" height="48.8" x="165" y="148.5" width="70" style={{ visibility: 'hidden ' }} />
            <WrappedText x={165} y={150} width={70} height={48.8} text={argument.name} />
          </g>
        </HexagonCellWrapper>
      </g>
    </g>
  );
};

const HexagonCell = React.memo(
  ({
    vertices,
    rotation,
    center,
    stepY,
    flipText,
    argument,
    onClick,
    someoneFlashing,
    active,
    onLongPress,
    onOpenComment,
    isFlashing,
    currentComment,
    isReplay,
    isEditing,
    hexagonSize,
    tooltipPlacement
  }) => {
    const verticesString = pointsToString(vertices);

    const rectWidth = vertices[2].x - vertices[3].x + (hexagonSize === 6 ? 30 : 0);
    const rectHeight = stepY - stepY / 4;
    const rectPadding = (stepY - rectHeight) / 2;

    const rectPos = { x: vertices[3].x - (hexagonSize === 6 ? 15 : 0), y: vertices[0].y + rectPadding };
    const rectCenter = {
      x: rectPos.x + rectWidth / 2,
      y: rectPos.y + rectHeight / 2
    };
    const textRotation = flipText ? 180 : 0;

    const hexagonCellStyle = {
      opacity: 1,
      // fill: someoneFlashing && !isFlashing ? hexagonColorsGrayscal[argument.pointsGroup] : hexagonColors[argument.pointsGroup],
      fill: someoneFlashing && isFlashing && argument.pointsGroup === 5 ? '#BBB' : hexagonColors[argument.pointsGroup],
      filter: someoneFlashing && !isFlashing ? 'url(#grayscalee)' : '',
      stroke: 'white',
      strokeWidth: isFlashing ? 5 : someoneFlashing ? 5 : 5
    };

    return (
      <g
        style={{ pointerEvents: 'all' }}
        transform={`rotate(${rotation}, ${pointToString(center)})`}
        className={active ? (isFlashing ? 'hexagon-cell-no-filter' : 'hexagon-cell') : ''}
      >
        <polygon
          points={verticesString}
          style={{ ...hexagonCellStyle, opacity: someoneFlashing && !isFlashing ? 0.5 : 1 }}
        />
        <g transform={`rotate(${textRotation}, ${pointToString(rectCenter)})`}>
          <HexagonCellWrapper
            tooltipPlacement={tooltipPlacement}
            currentComment={currentComment}
            isReplay={isReplay}
            onOpenComment={onOpenComment}
            someoneFlashing={someoneFlashing}
            onClick={onClick}
            argument={argument}
            onLongPress={onLongPress}
            flipText={flipText}
            isFlashing={isFlashing}
            active={active}
          >
            <g>
              <rect
                x={rectPos.x}
                y={rectPos.y}
                height={rectHeight}
                width={rectWidth}
                style={{ visibility: 'hidden' }}
              />
              <WrappedText
                x={rectPos.x}
                y={rectPos.y}
                width={rectWidth}
                height={rectHeight}
                text={argument.name}
                hexagonSize={hexagonSize}
              />
            </g>
          </HexagonCellWrapper>
        </g>
      </g>
    );
  },
  (prev, next) =>
    prev.someoneFlashing === next.someoneFlashing && prev.onClick === next.onClick && prev.flashing === next.flashing
);

const HexagonRow = props => {
  let p1 = { x: 175, y: 130.5 };
  let p2 = { x: 225, y: 130.5 };
  let p3 = p2;
  let p4 = p1;

  const { rowNb, nbCells, hexagonSize } = props;
  const stepX =
    hexagonSize === 6
      ? 75
      : hexagonSize === 12
      ? 37
      : hexagonSize === 18
      ? 25
      : hexagonSize === 24
      ? 18.75
      : hexagonSize === 30
      ? 14.3
      : 12.05;
  const stepY =
    hexagonSize === 6
      ? 130
      : hexagonSize === 12
      ? 64.5
      : hexagonSize === 18
      ? 43.5
      : hexagonSize === 24
      ? 32.5
      : hexagonSize === 30
      ? 25
      : 21;

  return Array(nbCells)
    .fill()
    .map((_, cellNb) => {
      p1 = p4;
      p2 = p3;
      p3 = { x: p2.x + stepX, y: p2.y - stepY };
      p4 = { x: p1.x - stepX, y: p1.y - stepY };

      const cellIdx = cellNb + rowNb * nbCells;
      const help = () => props.onCellClick(cellIdx);

      const currCell = props.cellsData[cellIdx];

      return (
        <HexagonCell
          onOpenComment={props.onOpenComment}
          hexagonSize={hexagonSize}
          vertices={[p4, p3, p2, p1]}
          stepY={stepY}
          currentComment={props.currentComment}
          argument={props.cellsData[cellNb + rowNb * nbCells]}
          cellIdx={cellNb + rowNb * nbCells}
          onClick={help}
          key={props.keyData + cellNb}
          someoneFlashing={props.flashing.length > 0 && props.flashing[0]._id}
          isEditing={props.isEditing}
          isFlashing={props.flashing.some(p =>
            props.isEditing
              ? p.idx === cellIdx
              : p._id === currCell._id &&
                p.pointsGroup === currCell.pointsGroup &&
                (props.isReplay ? true : p.idx === cellIdx)
          )}
          {...props}
        />
      );
    });
};

const HexagonP1 = ({
  hexagonId,
  hexagonSize,
  onCellClick,
  cellsData,
  buzzerLabel,
  active,
  buzzerArgument,
  buzzerDescription,
  onBuzzerClick,
  buzzerGroup,
  someVoted,
  isReplay,
  isEditing,
  currentComment,
  onOpenComment,
  commenting,
  flashing = []
}) => {
  let svgRef = undefined;
  const [showArgumentDescription, setShowArgumentDescription] = useState(false);
  const [modalArgument, setModalArgument] = useState({
    name: '',
    description: ''
  });

  useEffect(() => {
    if (svgRef !== undefined) {
      wrapText(svgRef);
    }
  });

  const center = { x: 200, y: 173 };

  const onShowArgumentDescription = argument => {
    setModalArgument(argument);
    setShowArgumentDescription(true);
  };

  const tooltipPlacement = idx => {
    if (idx === 0 || idx === 3) return '';
    if (idx === 1 || idx === 2) return '-end';
    return '-start';
  };

  const all = new Array(6)
    .fill()
    .map((_, idx) => (
      <HexagonRow
        hexagonId={hexagonId}
        center={center}
        rowNb={idx}
        nbCells={hexagonSize / 6}
        rotation={60 * idx}
        key={`${hexagonId}-${idx}`}
        keyData={`${hexagonId}-${idx}`}
        hexagonSize={hexagonSize}
        flipText={idx > 1 && idx < 5}
        isReplay={isReplay}
        isEditing={isEditing}
        cellsData={cellsData}
        onLongPress={onShowArgumentDescription}
        onCellClick={onCellClick}
        active={active}
        currentComment={currentComment}
        flashing={flashing}
        onOpenComment={onOpenComment}
        tooltipPlacement={tooltipPlacement(idx)}
      />
    ));

  const buzzer = (
    <HexagonBuzzer
      hexagonId={hexagonId}
      currentComment={currentComment}
      title={buzzerLabel}
      isReplay={isReplay}
      argument={buzzerArgument}
      description={buzzerDescription}
      onClick={onBuzzerClick}
      flashing={flashing}
      group={buzzerGroup}
      isEditing={isEditing}
      isFlashing={flashing.some(p =>
        isEditing
          ? p.idx === -1
          : p._id === buzzerArgument._id &&
            p.pointsGroup === buzzerArgument.pointsGroup &&
            (isReplay ? true : p.idx === -1)
      )}
      someoneFlashing={flashing.length > 0 && flashing[0]._id}
      onLongPress={onShowArgumentDescription}
      active={active}
      onOpenComment={onOpenComment}
      commenting={commenting}
    />
  );

  const isIE = /*@cc_on!@ */ false || !!document.documentMode;

  return (
    <>
      <ArgumentDescriptionModal
        open={showArgumentDescription}
        argument={modalArgument}
        onClose={() => setShowArgumentDescription(false)}
      />
      <div className="hex-title">{buzzerLabel}</div>
      <svg
        preserveAspectRatio="xMidYMin slice"
        style={
          isIE
            ? {
                width: '100%',
                paddingBottom: '98%',
                height: '1px',
                overflow: 'visible'
              }
            : { width: '100%' }
        }
        viewBox="0 0 405 350"
        // ref={el => (svgRef = d3.select(el))}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <filter id="grayscalee">
            <feColorMatrix type="saturate" values="0.35" />
          </filter>
        </defs>

        {buzzer}
        {all}
      </svg>
    </>
  );
};

HexagonP1.propTypes = {
  hexagonId: PropTypes.any,
  onCellClick: PropTypes.func,
  cellsData: PropTypes.array,
  buzzerLabel: PropTypes.string,
  active: PropTypes.bool,
  phase: PropTypes.number,
  buzzerDescription: PropTypes.string,
  onBuzzerClick: PropTypes.func,
  buzzerGroup: PropTypes.number
};

export default React.memo(HexagonP1, (prev, next) => {
  console.log('SHould rerender', next.flashing.length > 0);

  // return true;
  return (
    prev.timer !== next.timer &&
    prev.someVoted === next.someVoted &&
    prev.cellsData.length === next.cellsData.length &&
    prev.phase &&
    next.phase &&
    prev.evaluationControl.renderId === next.evaluationControl.renderId &&
    prev.evaluationControl._id === next.evaluationControl._id &&
    prev.active === next.active &&
    prev.buzzerLabel === next.buzzerLabel &&
    prev.flashing.length >= next.flashing.length &&
    prev.flashing.every(f =>
      next.flashing.some(n => n._id === f._id && n.pointsGroup === f.pointsGroup && n.idx === f.idx)
    )
  );
});
