import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { richTextToHTML } from '../../../utils';

const ArgumentDescriptionModal = props => (
  <Dialog {...props} aria-labelledby="contained-modal-title-vcenter" style={{ WebkitUserSelect: 'none' }}>
    <DialogTitle>
      <div style={{ userSelect: 'none' }}>{props.argument.name}</div>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        <div style={{ userSelect: 'none' }}>{richTextToHTML(props.argument.description)}</div>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} color="primary" variant="contained">
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

export default ArgumentDescriptionModal;
