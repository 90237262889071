/* eslint-disable no-useless-escape */

export const MATCH_URLS = {
  MATCH_URL_YOUTUBE: /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=/,
  MATCH_URL_SOUNDCLOUD: /(?:soundcloud\.com|snd\.sc)\/[^.]+$/,
  MATCH_URL_VIMEO: /vimeo\.com\/.+/,
  MATCH_URL_FACEBOOK: /^https?:\/\/(www\.)?facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/,
  MATCH_URL_STREAMABLE: /streamable\.com\/([a-z0-9]+)$/,
  MATCH_URL_WISTIA: /(?:wistia\.com|wi\.st)\/(?:medias|embed)\/(.*)$/,
  MATCH_URL_TWITCH_VIDEO: /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/,
  MATCH_URL_TWITCH_CHANNEL: /(?:www\.|go\.)?twitch\.tv\/([a-zA-Z0-9_]+)($|\?)/,
  MATCH_URL_DAILYMOTION: /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?$/,
  MATCH_URL_MIXCLOUD: /mixcloud\.com\/([^/]+\/[^/]+)/,
  MATCH_URL_VIDYARD: /vidyard.com\/(?:watch\/)?([a-zA-Z0-9-]+)/,
  AUDIO_EXTENSIONS: /\.(m4a|mp4a|mpga|mp2|mp2a|mp3|m2a|m3a|wav|weba|aac|oga|spx)($|\?)/i,
  VIDEO_EXTENSIONS: /\.(mp4|og[gv]|webm|mov|m4v)($|\?)/i,
  HLS_EXTENSIONS: /\.(m3u8)($|\?)/i,
  DASH_EXTENSIONS: /\.(mpd)($|\?)/i,
  FROM_SITE: new RegExp(window.location.hostname)
};

export const canPlayFile = url => {
  return Object.keys(MATCH_URLS).some(reg => MATCH_URLS[reg].test(url));
};

export const validURL = str => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const get_hostname = url => {
  let matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
  let domain = matches && matches[1]; // domain will be null if no match is found

  return domain;
};

export const is_audio = url => MATCH_URLS['AUDIO_EXTENSIONS'].test(url);

export const can_play = url => Object.keys(MATCH_URLS).some(match => MATCH_URLS[match].test(url));

function get_youtube_video_id(url) {
  var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);
  if (match && match[2].length === 11) {
    return match[2];
  } else {
    return false;
  }
}

export const get_thumbnail = (url, debateType = '') => {
  if (MATCH_URLS.MATCH_URL_YOUTUBE.test(url)) {
    const video_id = get_youtube_video_id(url);
    return video_id ? `https://img.youtube.com/vi/${video_id}/mqdefault.jpg` : undefined;
  } else if (MATCH_URLS.MATCH_URL_DAILYMOTION.test(url)) {
    try {
      const video_id = url.split('video/')[1].split('?')[0];
      return `https://www.dailymotion.com/thumbnail/video/${video_id}`;
    } catch (error) {
      return undefined;
    }
  }

  return undefined;
};

export const get_thumbnail_img = session => {
  if (session.mode === 'to_do') {
    return null;
  }

  const online_thumbnail = get_thumbnail(session.diffusionLink);
  if (online_thumbnail) return online_thumbnail;
  else return null;
};

// console.log('can_play tests');
// console.log(get_hostname('https://www.youtube.com/watch?v=sDKnKzYyx5c'));
// console.log(get_hostname('https://www.dailymotion.com/video/x7tlguf'));
// console.log(get_hostname(''));
// console.log(
//   get_hostname(
//     'https://docs.google.com/document/d/1quAIWsCA5hDhnvlcmZtPLCuNHyZcOMoDQmJMnd7kSwA/edit'
//   )
// );
