import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })

    // console.error(error.toString(), errorInfo.componentStack);
    // You can also log error messages to an error reporting service here
  }
  
  render() {
    if (this.state.errorInfo) {
      // window.location.reload();
      // Error path
      return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <div>Une erreur a eu lieu.<br/></div>
          <div>
            <a href={window.location.href} onClick={() => window.location.reload()} alt="recharger">Recharger la page.</a>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }  
}

export default ErrorBoundary;