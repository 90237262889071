/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { isIE } from 'react-device-detect';
import { connect, useSelector } from 'react-redux';
import NavigationBar from '../../components/NavigationBar';
import useApiPrepare from '../../hooks/api/useApiPrepare';
import { GET_ALL_FOLDERS, GET_FOLDER, GET_FOLDER_EVALUATIONS } from '../../services/api_routes';
import { HUB_MODES } from './constants';
import { useDispatch } from 'react-redux';
import { setExercisesCount } from '../../services/auth/actions';
import ContentBar from './ContentBar';
import SessionsList from './SessionsList';
import './TeacherHub.scss';
import { tutorialScreens } from '../../utils/texts';
import { TutorialContext } from '../../hooks/context/TutorialProvider';
import { useContext } from 'react';

function TeacherHub({ navigation, group, mode, match, history, isTeacher }) {
  const dispatch = useDispatch();
  const tutorialContext = useContext(TutorialContext);

  const url =
    mode === HUB_MODES.TEACHER_HUB
      ? GET_ALL_FOLDERS + '?token=' + match.params.token
      : (mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL ? GET_FOLDER_EVALUATIONS : GET_FOLDER) +
      '?token=' +
      match.params.token;

  const [pageData, isLoading, fetchData] = useApiPrepare(url, { participations: [], title: '', description: '' });
  const { isPCL } = useSelector(state => state.auth.user);

  React.useEffect(() => {
    if (!match.params.token) {
      if (group.token) {
        history.replace('/list/' + group.token);

        if (isIE) {
          fetchData();
        }
      } else {
        history.replace('/');
      }
    } else {
      fetchData();
    }
  }, [match.params.token]);

  React.useEffect(() => {
    if (!isLoading) {
      dispatch(setExercisesCount(pageData.participations.length));
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (!isLoading) {
      tutorialContext.setScreen(
        mode === HUB_MODES.TEACHER_HUB
          ? tutorialScreens.EXERCISES_LIST
          : mode === HUB_MODES.TEACHER_FOLDER_TO_DO
            ? pageData.isDemo && pageData.title.includes("Activité test") && isPCL
              ? tutorialScreens.SUBMITTED_LIST_TO_DO_PCL_TEST :
              pageData.isDemo && pageData.title.includes("Activité modèle") && isPCL ? tutorialScreens.SUBMITTED_LIST_TO_DO_PCL_MODEL : tutorialScreens.SUBMITTED_LIST_TO_DO
            : mode === HUB_MODES.TEACHER_FOLDER_TO_EVAL
              ? tutorialScreens.SUBMITTED_LIST_TO_EVAL
              : tutorialScreens.SUBMITTED_LIST_PRESENTIAL,
        true
      );

      tutorialContext.setStyle({ maxWidth: '50em', margin: '0 auto' });

      if (mode === HUB_MODES.TEACHER_HUB || pageData.isDemo || pageData.isDemoToEvaluate) {
        setTimeout(() => {
          tutorialContext.startGuidedTour();
        }, 500);
      }

    }

    return () => {
      tutorialContext.setScreen('');
      tutorialContext.setStyle({});
    };
  }, [mode, isLoading]);

  return (
    <>
      {isTeacher && mode === HUB_MODES.TEACHER_HUB && <NavigationBar title="Corriger une activité" to="/home" />}
      {mode !== HUB_MODES.TEACHER_HUB && <NavigationBar title={pageData.title} to="/list" />}
      {isTeacher && mode !== HUB_MODES.TEACHER_HUB && (
        <ContentBar mode={mode} data={pageData} isLoading={isLoading} isDemo={group.isDemo} isPCL={isPCL} isDemoSession={pageData.isDemo} />
      )}

      <div className="body-wrapper">
        <div className={isTeacher && mode !== HUB_MODES.TEACHER_HUB ? '' : 'content-wrapper'}>
          {!isTeacher && <ContentBar mode={mode} data={pageData} isLoading={isLoading} isDemo={group.isDemo} />}
          <SessionsList
            token={match.params.token}
            sessions={pageData.participations}
            mode={mode}
            sessionMode={mode}
            reloadData={fetchData}
            prestationMode={pageData.prestationMode}
            closeDate={pageData.closeDate}
            isDemo={pageData.isDemo}
            isDemoToEvaluate={pageData.isDemoToEvaluate}
            isGroupDemo={group.isDemo}
            isLoading={isLoading}
            isPCL={isPCL}
            title={pageData.title}
          />
        </div>
      </div>
    </>
  );
}

export default connect(({ auth }) => ({ group: auth.currentGroup, isTeacher: auth.user.teacher }))(TeacherHub);
