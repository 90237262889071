import React from 'react'
import { Controller } from 'react-hook-form'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import frLocale from "date-fns/locale/fr";

const useStyles = makeStyles({
  root: {
    'label + &': { fontWeight: 700 },
    backgroundColor: '#F4F4F4',
    borderRadius: 6,
    padding: 5,
    marginTop: 2
  },
  input: {
    fontWeight: 700,
    border: 'none'
  },
  label: {
    fontWeight: 700
  }
})

const MuiDatePicker = ({ label, control, name, minDate, ...props }) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils} {...props}>
        <Controller 
          as={
            <DatePicker
              classes={{ root: classes.root }}
              disableToolbar
              minDate={minDate}
              variant="inline"
              autoOk
              InputProps={{ disableUnderline: true }}
              fullWidth
              format="dd/MM/yyyy"
              margin="normal"
              label={label}
              {...props}
            />
          }
          name={name}
          control={control}
          defaultValue={props.defaultValue}
        />
    </MuiPickersUtilsProvider>
  )
}

export default MuiDatePicker;