import { CircularProgress } from '@material-ui/core';
import React from 'react';

function Spinner() {
  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <div><CircularProgress color="secondary" /></div>
      {/* <div className="hexagon-loader">
        <div className="loader-row">
          <div className="arrow up arrow-1" />
          <div className="arrow down arrow-2" />
          <div className="arrow up arrow-3" />
        </div>
        <div className="loader-row">
          <div className="arrow down arrow-6" />
          <div className="arrow up arrow-5" />
          <div className="arrow down arrow-4" />
        </div>
      </div> */}
    </div>
  );
}

export default Spinner;
