import { useDispatch } from 'react-redux';
import { GET_ERRORS } from '../services/error/actions';

const useDispatchError = () => {
  const dispatch = useDispatch();

  return err => {
    if (err.status === 403) { 
      window.location.href = "/list";
    } else {
      dispatch({ type: GET_ERRORS, payload: err.data })
    }
  };
};

export default useDispatchError;
