import React from 'react';
import { useSelector } from 'react-redux';
import { hexagonGroupPCL, hexagonGroups } from '../utils/texts';

const HexagonGroupFilter = ({ currentGroup, onGroupUpdate }) => {
  const { isPCL } = useSelector(state => state.auth.user);
  const { currentGroup: currentUserGroup } = useSelector(state => state.auth);
  console.log('DUH', currentUserGroup);

  const Row = ({ index, group, id }) => (
    <button
      id={id}
      className={'no-focus btn hex-group-btn btn' + (currentGroup === index ? '-primary' : '-outline')}
      onClick={() => onGroupUpdate(index)}
      key={index}
    >
      {group}
    </button>
  );

  return (
    <div style={{ flexDirection: 'row' }}>
      {(!isPCL || (isPCL && currentUserGroup && currentUserGroup.isDemo)) &&
        hexagonGroups.map((group, index) => <Row index={index} group={group} />)}

      {isPCL && <Row index={6} group={hexagonGroupPCL} id="gt-grid-select" />}
    </div>
  );
};

export default HexagonGroupFilter;
