/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { connect } from 'react-redux';
import './EvaluationRoom.scss';
import {
  joinDebate,
  vote,
  clearDebate,
  getEvaluationControl,
  undoVote,
  postUserFinishedDebate,
  clearLatestSession,
  getAvailablePrecisions,
  getDebateMetadata
} from '../../services/debate/actions';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import useApi from '../../hooks/api/useApi';
import useSessionState from '../EvaluationRoom/hooks/useSessionState';
import HexagonsSection from '../EvaluationRoom/components/HexagonsSection2';
import TextInput from '../../components/TextInput';
import StartDialog from './components/StartDialog';
import { REPLAY_DEBATE, UPLOAD_FILE } from '../../services/api_routes';
import { NavbarContext } from '../../hooks/context/NavbarProvider';
import Dialog from '../../components/dialog/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Spinner from '../../components/Spinner';
import { AlertContext } from '../../hooks/context/AlertProvider';
import { TutorialContext } from '../../hooks/context/TutorialProvider';
import EvaluationSidebar from './components/EvaluationSidebar';
import Axios from 'axios';
import { tutorialScreens } from '../../utils/texts';
import { useEffect } from 'react';

function EvaluationRoom({ history, match, mode, ...props }) {
  const { id: token } = match.params;

  const alertContext = React.useContext(AlertContext);
  const tutorialContext = React.useContext(TutorialContext);

  const [debate, isLoading] = useApi(mode === 'replay' ? REPLAY_DEBATE : '/api/debates/join', {}, 'post', { token });
  const [, setPageData] = React.useState({ page: 1, pagesCount: 1 });
  const [, setNotes] = React.useState('');
  const [votes, setVotes] = React.useState([]);
  const [showStartDialog, setShowStartDialog] = React.useState(false);

  const [showCommentModal, setShowCommentModal] = React.useState(false);
  const [showFinishedModal, setShowFinishedModal] = React.useState(false);
  const [commentText, setCommentText] = React.useState('');
  const [uploadState, setUploadState] = React.useState({ uploading: false, progress: 0 });

  const replayData = debate.replay;
  const fake_debate = debate.debate || {};
  fake_debate.notes = debate.notes || '';

  const hexagonSection = React.useRef();
  const videoPlayerRef = React.useRef();
  const historyRef = React.useRef();
  const appreciationRef = React.useRef();

  const {
    isCreator,
    teams,
    evaluationControl,
    evaluationControlInterviewer,
    evaluationControlP2,
    folderMode,
    recordingMode,
    prestationMode,
    isDemo,
    isDemoToEvaluate
  } = mode === 'replay' ? fake_debate : debate;

  useEffect(() => {
    tutorialContext.setScreen(mode === 'replay' ? tutorialScreens.REPLAY : tutorialScreens.EVALUATION, true);
    tutorialContext.setStyle({ marginLeft: 0 });
    return () => {
      tutorialContext.setScreen('');
      tutorialContext.setStyle({});
    };
  }, [mode]);

  const shouldRecord =
    mode !== 'replay' && folderMode === 'in_person' && (recordingMode === 'audio' || recordingMode === 'video');

  /** ------- HEXAGONS PAGING ------- */
  /** Used when there are more than 4 hexagons, they are split in multiple pages */
  const cb = (page, pagesCount) => setPageData({ page, pagesCount });
  const speakersCount = teams ? teams.length : 1;
  const hexPerPage = speakersCount;

  /** ------- REPLAY LOGIC ----------- */
  React.useEffect(() => {
    if (mode === 'replay') {
      fake_debate.description = 'Visualisez en direct les critères sur lesquels votre formateur a cliqué.';
      setNotes(debate.notes);
      if (replayData)
        setVotes(
          replayData
            .sort((a, b) => a.time - b.time)
            .map(d => ({ ...d.argument, time: d.time, pointsGroup: d.pointsGroup, comment: d.comment }))
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fake_debate]);

  /** -------- SESSION STATE  ---------- */
  const goToResults = () => {
    if (props.user.teacher) {
      history.push(
        folderMode !== 'to_eval'
          ? `/debate/${token}/results`
          : props.user.teacher
          ? '/evals/' + token
          : `/debate/${token}/results/${props.user.username}`,
        { from: 'evaluation_room' }
      );
    } else {
      setShowFinishedModal(true);
    }
  };

  const onSessionClosed = () => {
    if (phase >= 2)
      hexagonSection.current.submitPhase2Votes(
        goToResults,
        votes,
        appreciationRef.current ? appreciationRef.current.value : ''
      );
    else goToResults();
  };

  const [sessionState, sessionActions] = useSessionState(
    false,
    token,
    isCreator,
    () => {},
    onSessionClosed,
    () => {},
    mode === 'replay',
    folderMode === 'in_person' && mode !== 'replay' && false
  );

  const { paused, phase } = sessionState;

  React.useEffect(() => {
    return function() {
      props.clearDebate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    return () => {
      if (history.location.pathname === `/debate/${token}/results`) {
        // const notes = hexagonSection.current ? hexagonSection.current.getNotes() : '';
        // props.postUserFinishedDebate(props.user.id, roomId, debate.evaluationSession, notes);
      } else if (
        // Clear the session when we leave the page, not going to debates
        !(history.location.pathname === `/debate/${token}/results` || history.location.pathname === `/join/${token}`) &&
        props.mode !== 'replay'
      ) {
        // clearSession();
      }
    };
  });

  const navbarContext = React.useContext(NavbarContext);

  React.useEffect(() => {
    if (mode === 'replay') {
      navbarContext.popover('Rejouer', fake_debate.title);
    } else {
      navbarContext.popover('évaluation', debate.title);
    }

    if ((mode === 'replay' && isDemo && !isDemoToEvaluate) || (mode !== 'replay' && isDemoToEvaluate)) {
      tutorialContext.startGuidedTour();
    }
  }, [isLoading]);

  const onStart = () => {
    if (mode === 'replay') sessionActions.startPhase1();
    else setShowStartDialog(true);
  };

  const onStartEvaluation = () => {
    sessionActions.startPhase1();
    if (shouldRecord && videoPlayerRef.current) {
      videoPlayerRef.current.startRecording();
    }
  };

  // We use the phase to denote who is the current speaker on phase 2.
  // On phase 2, contrary to phase 1, the speakers are displayed one by one.
  // If phase === 2, display the first speaker, if phase === 3, the second, .... speaker[phase - 2]
  const onP2next = () => {
    if (phase - 2 === speakersCount - 1) {
      sessionActions.close();
    } else {
      if (mode === 'replay' && phase === 1) {
        sessionActions.startPhase2();
      } else {
        window.scrollTo(0, 0);
        sessionActions.nextSpeaker();
      }
    }
  };

  const onCancelEvaluation = () => {
    setShowStartDialog(false);
  };

  const onVote = vote => {
    setVotes(votes => {
      if (historyRef.current) {
        const editedComment = historyRef.current.getEditedComment();
        if (editedComment.idx !== -1) {
          votes[editedComment.idx].comment = editedComment.value;
          historyRef.current.resetEditedComment();
          sessionActions.pause();
        }
      }

      const voteTime = videoPlayerRef.current.getCurrentTime();

      return [{ ...vote, time: voteTime, rank_idx: votes.length, comment: '' }, ...votes]
        .sort((a, b) => (a.rank_idx !== undefined ? a.rank_idx - b.rank_idx : a.time - b.time))
        .reverse();
    });
  };

  const onComment = (voteIdx = -1) => () => {
    if (voteIdx === -1) {
      voteIdx = 0;
    }

    if (votes.length === 0) return;

    setVotes(votes => {
      votes[voteIdx] = { ...votes[voteIdx], comment: commentText };
      return [...votes];
    });

    setShowCommentModal(false);
    setCommentText('');
    sessionActions.pause();
  };

  const onOpenComment = () => {
    setShowCommentModal(true);
    sessionActions.pause();
  };

  const onCloseComment = () => {
    setCommentText('');
    setShowCommentModal(false);
    sessionActions.pause();
  };

  const onValidateP1 = () => {
    alertContext.confirm(
      {
        title: 'Êtes-vous sûr(e) ?',
        content: (
          <>
            Cette action est irréversible.
            <br /> Vous ne pourrez pas revenir en arrière par la suite.
          </>
        ),
        submitText: 'Valider'
      },
      async bool => {
        if (bool) {
          if (shouldRecord && videoPlayerRef.current) {
            videoPlayerRef.current.stopRecording(startUploadRecording);
          }
          sessionActions.startPhase2();
        }
      }
    );
  };

  const onGoBackReplay = () => {
    if (phase === 2) {
      if (videoPlayerRef.current) {
        videoPlayerRef.current.seekTo(0);
      }
      // setPaused(true);
    }

    sessionActions.goBack();
  };

  // Only for in person mode !
  const startUploadRecording = async () => {
    const file = videoPlayerRef.current.getFile();

    if (file.size > 50 * Math.pow(10, 6)) {
      window.alert(
        'Ce fichier dépasse la taille maximale de 50Mo. Nous vous recommandons de le télécharger pour le sauvegarder.'
      );
      return;
    }

    const data = new FormData();
    data.append('file', file);

    setUploadState({ uploading: true, progress: 0 });

    const res = await Axios.post(UPLOAD_FILE + `?token=${token}&mode=in_person`, data, {
      validateStatus: s => s < 405,
      headers: {
        Authorization: Axios.defaults.headers.common['Authorization'],
        'Content-type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        setUploadState(s => ({ ...s, progress: (progressEvent.loaded / progressEvent.total) * 100 }));
      }
    });

    if (res.status !== 200) {
      props.enqueueSnackbar("Il y a eu une erreur à l'envoi du fichier.", { variant: 'error', duration: 4000 });
    }

    setUploadState({ uploading: false, progress: 100 });
  };

  const votesInWindow = replayData
    ? replayData.filter(v => sessionState.timer - v.time >= -1.5 && sessionState.timer - v.time <= 1.5)
    : [];

  return isLoading || Object.keys(debate).length === 0 ? (
    <Spinner />
  ) : (
    <div className="evr-wrapper content-wrapper" style={{ padding: 0 }}>
      <StartDialog
        open={showStartDialog}
        onClose={() => setShowStartDialog(false)}
        onCancel={onCancelEvaluation}
        onStart={onStartEvaluation}
        isTeacher={props.user.teacher}
      />

      <Dialog
        open={showFinishedModal}
        onClose={() => setShowFinishedModal(false)}
        title=" "
        content={
          <div className="text-center">
            <h3>Travail rendu</h3>
            <div className="subtitle">
              Votre travail a bien été transmis à votre enseignant(e).
              <br />
              Une fois qu'il aura été corrigé, votre note sera visible
              <br />
              sur la liste de vos travaux rendus.
            </div>
            <button className="btn btn-primary mt-4" style={{ width: '8em' }} onClick={() => history.push('/list')}>
              Retour à l'accueil
            </button>
          </div>
        }
      />

      <EvaluationSidebar
        sessionActions={sessionActions}
        sessionState={sessionState}
        session={mode === 'replay' ? fake_debate : debate}
        isTeacher={props.user.teacher}
        isUploading={uploadState.uploading}
        prestationMode={prestationMode}
        mode={mode}
        votes={votes}
        setVotes={setVotes}
        replayData={replayData}
        ref={{ videoPlayerRef, historyRef, appreciationRef }}
      />

      <div className="content-data" style={{ minWidth: '300px' }}>
        <Dialog
          content={
            <div className="comment-dialog">
              <div className="d-flex align-items-center">
                <div style={{ flexGrow: 1 }}>
                  <strong>Remarque</strong> {commentText.length}/140
                </div>
                <div>
                  <button className="btn btn-icon btn-text" onClick={onCloseComment}>
                    <CloseIcon />
                  </button>
                </div>
              </div>
              <div className="mt-2">
                <TextInput
                  value={commentText}
                  onChange={e => setCommentText(e.target.value.slice(0, 140))}
                  multiline
                  rows={3}
                  placeholder="Ajouter une remarque pour ce critère."
                />
              </div>
              <div className="mt-2 text-center">
                <button className="btn btn-primary" onClick={onComment()}>
                  Valider
                </button>
              </div>
            </div>
          }
          open={showCommentModal}
          onClose={onCloseComment}
        />

        <HexagonsSection
          ref={hexagonSection}
          debate={mode === 'replay' ? fake_debate : debate}
          canFinish={
            folderMode !== 'in_person' ||
            (folderMode === 'in_person' && (recordingMode === 'none' || recordingMode === 'mobile')) ||
            ((recordingMode === 'audio' || recordingMode === 'video') && !uploadState.uploading)
          }
          phase={phase}
          roomId={token}
          options={sessionState}
          hexagons={{ evaluationControl, evaluationControlP2, evaluationControlInterviewer }}
          maxSize={hexPerPage}
          onPageChange={cb}
          onStart={onStart}
          onGoBack={onGoBackReplay}
          replay={mode === 'replay'}
          onValidateP1={onValidateP1}
          history={history}
          onFinish={onP2next}
          onOpenComment={onOpenComment}
          onComment={onComment}
          commenting={showCommentModal}
          getTime={() => sessionState.timer}
          currentComment={votesInWindow}
          replayP2Selection={mode === 'replay' && debate.replayP2 ? debate.replayP2.selected : []}
          flashing={
            replayData && !paused
              ? votesInWindow.map(({ argument: { _id }, pointsGroup }) => ({ _id, pointsGroup }))
              : []
          }
          onVote={onVote}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  debate: state.debate,
  errors: state.errors,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  joinDebate,
  vote,
  getEvaluationControl,
  undoVote,
  postUserFinishedDebate,
  clearDebate,
  clearLatestSession,
  getAvailablePrecisions,
  getDebateMetadata
})(withRouter(withSnackbar(EvaluationRoom)));
