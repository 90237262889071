import { applyMiddleware, createStore, combineReducers } from 'redux';

import list from './services/list/reducer';
import errors from './services/error/reducer';
import auth from './services/auth/reducer';
import debate from './services/debate/reducer';
import hub from './services/hub/reducer';
import settings from './services/settings/reducer';

import logger from 'redux-logger';
import thunk from 'redux-thunk';

const reducers = combineReducers({ list, errors, auth, debate, hub, settings });

let middleware = [];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, thunk, logger];
} else {
  middleware = [...middleware, thunk];
}

const store = createStore(reducers, applyMiddleware(...middleware));

export default store;
