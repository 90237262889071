import React from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import './TutorialCard.scss';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

const TutorialCard = ({
  title,
  description,
  token,
  thumbnailUrl,
  style,
  titleOnly,
  closable,
  onClose,
  onCloseForever
}) => {
  const onCloseClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  return (
    <div className="tc-wrapper" style={style}>
      {closable && (
        <span className="tc-close" onClick={onCloseClick}>
          <CloseIcon fontSize="inherit" />
        </span>
      )}
      <Link to={'/tutorials/' + token} className="tc-wrapper-a">
        <div className="tc-thumbnail">
          <img src={thumbnailUrl} alt="Miniature" />
          <span className="tc-thumbnail-overlay">
            <PlayArrowIcon fontSize="inherit" />
          </span>
        </div>
        <div className="tc-content">
          <div className="tc-title">{title}</div>
          {!titleOnly && <div className="tc-description">{description}</div>}
        </div>
      </Link>

      {closable && (
        <div className="tc-never-button">
          <button className="btn btn-outline small" onClick={onCloseForever}>
            Ne plus afficher
          </button>
        </div>
      )}
    </div>
  );
};

export default TutorialCard;
